import React from "react";
import styled from "styled-components";
import { HeaderFooterWrapper } from "../theming/common";

const StyledFooter = styled.footer`
  & div {
    margin-right: 25px;
    line-height: 30px;

    & a {
      color: white;
      font-size: 14px;
    }
    & a:hover {
      text-decoration: underline;
    }
  }
`;

const Wrapper = styled(HeaderFooterWrapper)`
  margin-top: auto;
`;

const Footer = () => (
  <Wrapper>
    <StyledFooter>
      <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.natwest.com/global/cookieprivacy.ashx?channel=personal"
        >
          Privacy &amp; Cookies
        </a>
      </div>
      <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.natwest.com/website-terms-and-FSCS.html"
        >
          NatWest T&amp;C's &amp; FSCS
        </a>
      </div>
      <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.lombard.co.uk/toolbar/terms-conditions.html"
        >
          Lombard T&amp;C's
        </a>
      </div>
      <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://personal.natwest.com/personal/fraud-and-security.html"
        >
          Security Centre
        </a>
      </div>
    </StyledFooter>
  </Wrapper>
);

export default Footer;
