import React from "react";
import { Field } from "react-final-form";
import {
  ErrorMessage,
  RadioWrapper,
  FieldInputWrapper,
  H3,
} from "../../../theming/common";
import {
  requiredValidator,
  shortDateFieldValidator,
  dynamicDirectorLastAppointedDate
} from "../../../utils/validators";
import { composeValidators, isFieldValidAndTouched } from "../../../utils/form";
import RadioButton from "../../../components/RadioButton";
import {
  limitedCompanyDirectorsNumber,
  BUSINESS_ENTITY_KEYS,
} from "../../../utils/constants";
import DateInput from "../../../components/DateInput";
import { trackFieldInput, trackRadioButtonSelection, trackValidationError } from "resources/analyticsHelper";

const LimitedCompanyFields = ({ values, form, pageTitle }) => {

  
  const onBlurFieldHandler = (e, fieldLabel, meta) => {
    // console.log(`>>> onBlurFieldHandler`, e.name, e.value, fieldLabel, e, meta);

    trackFieldInput(fieldLabel, e.value);


    if (meta && meta.error) {
      const errMsg = meta.error;
      trackValidationError(errMsg, fieldLabel, pageTitle)
    }    
  };

  const onRadioFieldSelection = (e, fieldLabel, option) => {
    // console.log(`>>> onRadioFieldSelection`, e, fieldLabel);
    
    trackRadioButtonSelection(fieldLabel, option.label);
  };

  return (
    <>
      {values.businessEntity &&
        (values.businessEntity ===
          BUSINESS_ENTITY_KEYS.PRIVATE_LIMITED_COMPANY ||
          values.businessEntity ===
            BUSINESS_ENTITY_KEYS.PUBLIC_LIMITED_COMPANY) && (
          <>
            <H3>Date of incorporation</H3>
            <small>MM/YYYY</small>
            <Field name="startedTradingDate" validate={shortDateFieldValidator}>
              {({ input, meta }) => (
                <>
                  <FieldInputWrapper>
                    <DateInput
                      {...input}
                      onBlur={(e) => { input.onBlur(); onBlurFieldHandler(input, `Date of incorporation`, meta); }}
                      valid={isFieldValidAndTouched(meta)}
                    />
                  </FieldInputWrapper>
                  {meta.error && meta.touched && (
                    <ErrorMessage>{meta.error}</ErrorMessage>
                  )}
                </>
              )}
            </Field>

            <h3>How many Directors are there?</h3>
            <RadioWrapper>
              {limitedCompanyDirectorsNumber.map((x) => {
                return (
                  <Field
                    name="numberOfCurrentDirectors"
                    type="radio"
                    value={x.value}
                    key={`directorsNumber_${x.value}`}
                    validate={requiredValidator}
                  >
                    {({ input }) => {
                      return (
                        <RadioButton
                          key={`radio_directorsNumber_${x.value}`}
                          option={x}
                          bSelected={input.checked}
                          handleChange={(e) => {
                            input.onChange(e);
                            onRadioFieldSelection(input, `How many Directors are there?`, x);
                          }}
                          layout="triple"
                        />
                      );
                    }}
                  </Field>
                );
              })}
            </RadioWrapper>
            {form.getState().touched.businessEntity &&
              !values.numberOfCurrentDirectors && (
                <ErrorMessage>
                  Please select the number of directors
                </ErrorMessage>
              )}

            <H3>When was the last Director appointed?</H3>
            <small>MM/YYYY</small>
            <Field
              name="lastDirectorAppointed"
              validate={composeValidators(shortDateFieldValidator, dynamicDirectorLastAppointedDate)}
            >
              {({ input, meta }) => (
                <>
                  <FieldInputWrapper>
                    <DateInput
                      {...input}
                      onBlur={(e) => { input.onBlur(); onBlurFieldHandler(input, `When was the last Director appointed?`, meta); }}
                      valid={isFieldValidAndTouched(meta)}
                    />
                  </FieldInputWrapper>
                  {meta.error && meta.touched && (
                    <ErrorMessage>{meta.error}</ErrorMessage>
                  )}
                </>
              )}
            </Field>
          </>
        )}
    </>
  );
};

export default LimitedCompanyFields;
