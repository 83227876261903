import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as BackIcon } from "../../resources/images/chevron_purple.svg";
import { colours, Button } from 'resources/global-styles';
import { stageVal } from 'resources/constants';
import { brokerAuth, brokerAccessOnly } from "../../resources/utils";
import { trackButtonClick } from 'resources/analyticsHelper';

const Div = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 60px;
`

const PrevButton = styled.button`
height: 60px;
background-color: transparent;
border: none;
cursor: pointer;
font-size: 18px;
color: ${colours.btn_copy};
display: flex;
align-items: center;
text-decoration: underline;

& svg {
  height: 18px;
  transform: rotate(180deg);
  margin-right: 10px;
  margin-top: 0px;
}
`

const PrevNext = ({ stage, nextLabel, handleChange }) => (
  <Div>
    {stage === stageVal.loan && <Link to={brokerAccessOnly() ? `/${brokerAuth()}` : `/${brokerAuth()}/getstarted`} onClick={() => { trackButtonClick('Button', 'Back'); }}><PrevButton><BackIcon />Back</PrevButton></Link>}
    {stage > stageVal.loan && stage < stageVal.confirmRefer && <PrevButton onClick={() => { trackButtonClick('Button', 'Back'); handleChange(stage-1);}}><BackIcon />Back</PrevButton>}
    {stage < stageVal.summary && <Button onClick={() => { trackButtonClick('Button', 'Next step ' + nextLabel); handleChange(stage+1);}}>Next step <b>{nextLabel}</b></Button>}
    {stage === stageVal.refer && <Button onClick={() => { trackButtonClick('Button', 'Submit referral'); handleChange(stage+1);}}>Submit referral</Button>}
    {(stage === stageVal.confirmRefer || stage === stageVal.confirmUnhappy) && <Link to="/"><Button>Return to homepage</Button></Link>}
    {stage === stageVal.unhappy && (
      <>
        <PrevButton onClick={() => { trackButtonClick('Button', 'Back'); handleChange(stageVal.loan);} }><BackIcon />Back</PrevButton>
        <Button onClick={() => { trackButtonClick('Button', 'Submit'); handleChange(stage+1); } }>Submit</Button>
      </>
    )}
  </Div>
)

export default PrevNext;
