import React from "react";
import styled from "styled-components";
import { HeaderFooterWrapper } from "../theming/common";
import { ReactComponent as LombardLogo } from "../../resources/images/logo-lombard.svg";

const Logo = styled.div`
  text-align: right;
  line-height: 10px;
  padding-right: 10px;
`;
const Title = styled.div`
  margin: 0 10px;
  height: 30px;
  line-height: 30px;
  padding: 2px 20px;
  font-size: 18px;
  border-left: 1px solid white;
`;

const Header = () => (
  <HeaderFooterWrapper>
    <header>
      <a href="/">
        <Logo>
          <LombardLogo />
        </Logo>
      </a>
      <Title>Broker Portal</Title>
    </header>
  </HeaderFooterWrapper>
);

export default Header;
