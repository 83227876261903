import React from "react";
import MaskedInput from "react-text-mask";
import styled from "styled-components";
import { colors } from "../theming/variables";
import { colours } from "resources/global-styles";

const maskShort = [/[0-9]/, /[0-9]/, "/", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
const maskLong = [
  /[0-9]/,
  /[0-9]/,
  "/",
  /[0-9]/,
  /[0-9]/,
  "/",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

const DateMaskedInput = styled(MaskedInput)`
  width: ${(props) => (props.fullwidth ? "90%" : "100%")};
  height: 54px;
  font-size: 18px;
  color: ${colors.darkGrey};
  padding: 15px 45px 15px 15px;
  border: none;
  background-color: ${colours.input_bg};
  border-radius: 8px;
  outline: 0;
  background-position: center right 15px;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  padding-left: 20px;
  background-image: ${(props) =>
    props.valid === "true" ? "url(/images/input-valid-icon.svg)" : "none"};
  border: 1px solid ${colours.field_border};

    &:hover:focus {
      border: none;
      box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.input_border};
    }

    &:hover {
      border: 2px solid ${colours.input_border};
    }
  
    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.input_border};
    }
`;

const DateInput = ({ type, valid, onBlur, ...rest }) => {

  const onBlueHandler = (e) => {
    onBlur(e);
  };

  return (
    <DateMaskedInput
      autoComplete="off"
      mask={type === "date" ? maskLong : maskShort}
      showMask={false}
      guide={false}
      valid={valid}
      tabIndex={1}
      onBlur={onBlueHandler}
      {...rest}
    />
  );
};

export default DateInput;
