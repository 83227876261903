import React, { useContext } from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';

import { media, colours, Input, TextArea } from 'resources/global-styles';
import { formatLabel } from 'resources/utils';
import { ReactComponent as TooltipIcon } from 'resources/images/info_tooltip_purple.svg';

import { QuoteContext } from '_Broker/pages/Quote';
import NotValidated from '_Broker/structure/NotValidated';

import Address from './Address';
import Company from './Company';

const Numeric = styled(NumberFormat)`
  width: ${props => props.fullwidth === '' ? '90%' : '100%'};
  height: 54px;
 	font-size: 18px;
	color: ${colours.darkGrey};
	padding: 15px 45px 15px 15px;
	background-color: ${colours.input_bg};
  border: 1px solid ${colours.field_border};

  outline: 0;
	background-position: center right 15px;
	background-repeat: no-repeat;
  margin: 0px 0px 20px 4px;
  background-image: ${props => props.valid === 'true' ? 'url(/images/input-valid-icon.svg)' : 'none'};
  border-radius: 8px;

  @media ${media.tablet} {
    width: ${props => props.fullwidth === 'true' ? '90%' : '50%'};;
  }

  &:hover:focus {
    border: none;
    box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.input_border};
  }

  &:hover {
    border: 2px solid ${colours.input_border};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.input_border};
  }
`;

const NumericDisabled = styled(NumberFormat)`
  width: ${props => props.fullwidth === '' ? '90%' : '100%'};
  height: 54px;
 	font-size: 18px;
	color: ${colours.darkGrey};
	padding: 15px 45px 15px 15px;
	border: none;
	background-color: ${colours.mediumGrey};
  outline: 0;
	background-position: center right 15px;
	background-repeat: no-repeat;
  margin-bottom: 20px;
  background-image: ${props => props.valid === 'true' ? 'url(/images/input-valid-icon.svg)' : 'none'};
  border-radius: 8px;

  pointer-events: none;
  font-style: italic;

  @media ${media.tablet} {
    width: ${props => props.fullwidth === 'true' ? '90%' : '50%'};;
  }
`;

const Div = styled.div`
  position: relative;
  overflow: hidden;

  & h2 {
    margin-top: 0;
  }
  & label {
    margin: -10px 0 10px 0
  }
  & .tooltip {
    max-width: 50%;
    background-color: #1d7b8a;

    &:after {
      border-top-color: #1d7b8a !important;
    }
  }
`
const Tooltip = styled(TooltipIcon)`
  margin-left: 10px;
  vertical-align: middle;
  height: 24px;
`

const Wrapper = ({ title, label, tooltip, children }) => (
  <Div>
    {title !== '' && <h2>{title}{tooltip !== '' && <Tooltip data-tip={tooltip} />}</h2>}
    {label !== '' && <label>{label}</label>}
    {children}
    {tooltip !== '' && <ReactTooltip place='top' effect='solid' multiline={true} className='tooltip' delayHide={500} />}
  </Div>
)

const FreeText = ({ option, handleChange }) => {
  const context = useContext(QuoteContext);
  const label = option.description ? formatLabel(option.description, context) : '';
  const tooltip = option.tooltip ? formatLabel(option.tooltip, context) : '';

  const inputType = () => {
    switch (option.type) {
      case 'textarea':
        return (
          <TextArea
            value={context.value ? context.value : ''}
            onChange={(evt) => handleChange({choiceId: option.id, choiceVal: evt.target.value})}
            onBlur={() => handleChange(option.id)}
            fullwidth={`${option.layout === 'fullwidth'}`}
            rows='8'
            maxlength='10000' />
        )
      case 'company_lookup':
        return <Company choiceId={option.id} handleChange={handleChange} />
      case 'address_lookup':
        return <Address choiceId={option.id} handleChange={handleChange} />
      case 'currency':
        return (
          <Numeric
            // value={context.value ? context.value : '0'}
            value={context.value == null ? '' : context.value}
            onValueChange={(values) => handleChange({choiceId: option.id, choiceVal: values.value})}
            onBlur={() => handleChange(option.id)}
            valid={`${context.valid}`}
            decimalScale='2'
            fixedDecimalScale={true}
            thousandSeparator={true}
            prefix={'£'}
            fullwidth={`${option.layout === 'fullwidth'}`}
            maxLength='14'
          />
        )
      case 'currency-disabled':
        return (
          <NumericDisabled
            // value={context.value ? context.value : '0'}
            value={context.value == null ? '' : context.value}
            onValueChange={(values) => handleChange({choiceId: option.id, choiceVal: values.value})}
            onBlur={() => handleChange(option.id)}
            valid={`${context.valid}`}
            decimalScale='2'
            fixedDecimalScale={true}
            thousandSeparator={true}
            prefix={'£'}
            fullwidth={`${option.layout === 'fullwidth'}`}
            maxLength='14'
            tabindex="-1"
          />
        )
      case 'time':
        return (
          <Numeric
            value={context.value ? context.value : ''}
            onValueChange={(values) => handleChange({choiceId: option.id, choiceVal: values.value})}
            onBlur={() => handleChange(option.id)}
            valid={`${context.valid}`}
            suffix={` year${context.value>1?'s':''}`}
            fullwidth={`${option.layout === 'fullwidth'}`}
            maxLength='8'
          />
        )
      default:
        return (
          <Input
            valid={context.valid}
            onChange={(evt) => handleChange({choiceId: option.id, choiceVal: option.type === 'postcode' ? (evt.target.value ? evt.target.value.toUpperCase() : '') : evt.target.value})}
            onBlur={() => handleChange(option.id)}
            value={context.value ? context.value : ''}
            fullwidth={option.layout === 'fullwidth'}
            maxLength={option.type === 'time' ? '2' : '50'}
          />
        )
    }
  }

  const validWarning = () => {
    if (typeof(context.valid) != 'undefined' && !context.valid && !!option.required) {
      if (option.layout === 'fullwidth') return <NotValidated width='90%' spacing='20px'>{option.required}</NotValidated>
      return <NotValidated >{option.required}</NotValidated>
    }
    return null;
  }

  return (
    <Wrapper title={option.title} label={label} tooltip={tooltip}>
      {inputType()}
      {validWarning()}
    </Wrapper>
  )
}

export default FreeText;
