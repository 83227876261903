import React from "react";
import styled from "styled-components";
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Form, Field, FormSpy } from "react-final-form";
import {
  ErrorMessage,
  FieldInputWrapper,
  RadioWrapper,
  Button,
} from "../../../theming/common";
import { colors } from "../../../theming/variables";
import { ReactComponent as EditIcon } from "resources/images/edit_purple.svg";
import { useLombardContext } from "../../../context/lombardContext";
import {
  isCarsAssetSelected,
  isFieldValidAndTouched,
  composeValidators,
} from "../../../utils/form";
import {
  requiredValidator,
  shortDateFieldValidator,
  numberValidator,
  vehicleRegistrationNumberValidator
} from "../../../utils/validators";
import DateInput from "../../../components/DateInput";
import RadioButton from "../../../components/RadioButton";
import Numeric from "../../../components/NumericInput";
import FieldInput from "../../../components/FieldInput";
import { trackFieldInput, trackRadioButtonSelection, trackValidationError } from "resources/analyticsHelper";
import { colours } from 'resources/global-styles';

const EditIconContainer = styled.div`
  color: ${colours.warning_link};
  flex-grow: 1;
  text-align: end;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;

  svg {
    width: 32px;
    height: 32px;
    margin-right: 0.25rem;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: right;
  margin: 1.5rem 0;
`;

const SuccessImage = styled.img`
  margin-left: 1.5rem;
`;

const vehiclenumberknownOptions = {
  Yes: {
    value: "yes",
    label: "Yes",
  },
  No: {
    value: "no",
    label: "No",
  },
};

const AdditionalAssetDetails = ({
  assetIndex,
  onSubmit,
  isExpanded,
  updateAccordionItemExpandedState,
  handleFormSpyOnChange,
  formsValidationStatus,
}) => {
  const { state } = useLombardContext();
  const { assetTypes } = state;
  const lumpsumSet = state.quote.assetDetails.lumpsumset === "yes";
  const initialValues = state.supportingDetails[`assetdetails_${assetIndex}`];

  const onBlurFieldHandler = (e, fieldLabel, postfix, meta) => {
    // console.log(`>>> onBlurFieldHandler`, e.name, e.value, fieldLabel, e);

    trackFieldInput(fieldLabel, e.value + (postfix || ''));

    if (meta && meta.error) {
      const errMsg = meta.error;
      trackValidationError(errMsg, fieldLabel, 'Quote summary')
    }    
  };

  
  const onRadioFieldSelection = (e, fieldLabel, option) => {
    // console.log(`>>> onRadioFieldSelection`, e, fieldLabel, option);
    
    trackRadioButtonSelection(fieldLabel, option.label);
  };

  return (
    <AccordionItem key={assetIndex} dangerouslySetExpanded={isExpanded}>
      <AccordionItemHeading onClick={updateAccordionItemExpandedState}>
        <AccordionItemButton>
          <h3>Additional details about asset #{assetIndex}</h3>
          {formsValidationStatus &&
            formsValidationStatus[`assetdetails_${assetIndex}`] && (
              <>
                <SuccessImage src="/images/input-valid-icon.svg" />
                {!isExpanded && (
                <EditIconContainer>
                  <div className="editicon"><EditIcon /></div>
                  <div>Edit</div>
                </EditIconContainer>
                )}
              </>
            )}
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <Form
          onSubmit={onSubmit}
          initialValues={{ formKey: "assetdetails_" + assetIndex, ...initialValues  }}
          render={({ handleSubmit, form }) => (
            <form autoComplete="off" onSubmit={handleSubmit}>
              <input
                autoComplete="off"
                name="hidden"
                type="text"
                style={{ display: "none" }}
              />
              <FormSpy
                subscription={{ values: true, valid: true, pristine: true }}
                onChange={handleFormSpyOnChange}
              />
              {!isCarsAssetSelected(
                assetTypes,
                state.quote.assetDetails.assetTypeId
              ) && (
                <>
                  <Field name="description" validate={requiredValidator}>
                    {({ input, meta }) => (
                      <div>
                        <label>Asset description</label>
                        <FieldInput
                          {...input}
                          valid={isFieldValidAndTouched(meta)}
                          onBlur={() => { input.onBlur(); onBlurFieldHandler(input, 'Asset description', null, meta); }}
                          type="text"
                          class={meta.error && meta.touched ? 'error-border' : ''}
                        />
                        {meta.error && meta.touched && (
                          <ErrorMessage>{meta.error}</ErrorMessage>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field
                    name="manufactureDate"
                    validate={shortDateFieldValidator}
                  >
                    {({ input, meta }) => (
                      <>
                        <label>Date of manufacture (MM/YYYY)</label>
                        <FieldInputWrapper>
                          <DateInput
                            {...input}
                            onBlur={() => { input.onBlur(); onBlurFieldHandler(input, 'Date of manufacture (MM/YYYY)', null, meta); }}
                            valid={isFieldValidAndTouched(meta)}
                          />
                        </FieldInputWrapper>
                        {meta.error && meta.touched && (
                          <ErrorMessage>{meta.error}</ErrorMessage>
                        )}
                      </>
                    )}
                  </Field>
                </>
              )}
              {isCarsAssetSelected(
                assetTypes,
                state.quote.assetDetails.assetTypeId
              ) && (
                <>
                  <Field name="vehicleRegNumberIsKnown">
                    {({ input, meta }) => (
                      <div>
                        <label>Do you know the vehicle reg number?</label>
                        <RadioWrapper>
                          <Field
                            name="vehiclenumberknown"
                            type="radio"
                            value={vehiclenumberknownOptions.Yes.value}
                            key={`vehiclenumberknown_${vehiclenumberknownOptions.Yes.value}`}
                            validate={requiredValidator}
                          >
                            {({ input }) => {
                              return (
                                <RadioButton
                                  key={`radio_vehiclenumberknown_${vehiclenumberknownOptions.Yes.value}`}
                                  option={vehiclenumberknownOptions.Yes}
                                  bSelected={input.checked}
                                  // handleChange={input.onChange}
                                  handleChange={(...args) => {
                                    input.onChange(...args);
                                    onRadioFieldSelection(input, 'Do you know the vehicle reg number?', vehiclenumberknownOptions.Yes);
                                  }}
                                  layout="fullwidth"
                                >
                                  <Field
                                    name="vehicleRegNumber"
                                    validate={composeValidators(requiredValidator, vehicleRegistrationNumberValidator)}
                                  >
                                    {({ input, meta }) => (
                                      <div>
                                        <label>Vehicle reg number</label>
                                        <FieldInput
                                          {...input}
                                          valid={isFieldValidAndTouched(meta)}
                                          onBlur={() => { onBlurFieldHandler(input, 'Vehicle reg number', null, meta); }}
                                          type="text"
                                        />
                                        {meta.error && meta.touched && (
                                          <ErrorMessage>
                                            {meta.error}
                                          </ErrorMessage>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </RadioButton>
                              );
                            }}
                          </Field>
                          <Field
                            name="vehiclenumberknown"
                            type="radio"
                            value={vehiclenumberknownOptions.No.value}
                            key={`vehiclenumberknown_${vehiclenumberknownOptions.No.value}`}
                            validate={requiredValidator}
                          >
                            {({ input }) => {
                              return (
                                <RadioButton
                                  key={`radio_vehiclenumberknown_${vehiclenumberknownOptions.No.value}`}
                                  option={vehiclenumberknownOptions.No}
                                  bSelected={input.checked}
                                  // handleChange={input.onChange}
                                  handleChange={(...args) => {
                                    input.onChange(...args);
                                    onRadioFieldSelection(input, 'Do you know the vehicle reg number?', vehiclenumberknownOptions.No);
                                  }}
                                  layout="fullwidth"
                                >
                                  <Field
                                    name="vehicleMake"
                                    validate={requiredValidator}
                                  >
                                    {({ input, meta }) => (
                                      <div>
                                        <label>Vehicle make</label>
                                        <FieldInput
                                          {...input}
                                          valid={isFieldValidAndTouched(meta)}
                                          onBlur={() => { onBlurFieldHandler(input, 'Vehicle make', null, meta); }}
                                          type="text"
                                        />
                                        {meta.error && meta.touched && (
                                          <ErrorMessage>
                                            {meta.error}
                                          </ErrorMessage>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                  <Field
                                    name="vehicleModel"
                                    validate={requiredValidator}
                                  >
                                    {({ input, meta }) => (
                                      <div>
                                        <label>Vehicle model</label>
                                        <FieldInput
                                          {...input}
                                          valid={isFieldValidAndTouched(meta)}
                                          onBlur={() => { onBlurFieldHandler(input, 'Vehicle model', null, meta); }}
                                          type="text"
                                        />
                                        {meta.error && meta.touched && (
                                          <ErrorMessage>
                                            {meta.error}
                                          </ErrorMessage>
                                        )}
                                      </div>
                                    )}
                                  </Field>

                                  <Field
                                    name="vehicleRegistrationYear"
                                    validate={composeValidators(
                                      numberValidator(
                                        1990,
                                        new Date().getFullYear() + 1
                                      )
                                    )}
                                  >
                                    {({ input, meta }) => (
                                      <>
                                        <label>
                                          Vehicle's registration year
                                        </label>
                                        <FieldInputWrapper>
                                          <Numeric
                                            {...input}
                                            valid={isFieldValidAndTouched(meta)}
                                            decimalScale={2}
                                            onBlur={() => { onBlurFieldHandler(input, `Vehicle's registration year`, null, meta); }}
                                            ignoreThousandsSeparator={true}
                                          />
                                        </FieldInputWrapper>
                                        {meta.error && meta.touched && (
                                          <ErrorMessage>
                                            {meta.error}
                                          </ErrorMessage>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </RadioButton>
                              );
                            }}
                          </Field>
                        </RadioWrapper>
                        {form.getState().touched.vehiclenumberknown &&
                          !form.getState().values.vehiclenumberknown && (
                            <ErrorMessage>Please select an option</ErrorMessage>
                          )}
                      </div>
                    )}
                  </Field>
                  <Field name="currentMileage" validate={requiredValidator}>
                    {({ input, meta }) => (
                      <>
                        <label>Vehicle's current mileage</label>
                        <FieldInputWrapper>
                          <Numeric
                            {...input}
                            valid={isFieldValidAndTouched(meta)}
                            onBlur={() => { onBlurFieldHandler(input, `Vehicle's current mileage`, ' miles', meta); }}
                            suffix={input.value > 1 ? " miles" : " mile"}
                          />
                        </FieldInputWrapper>
                        {meta.error && meta.touched && (
                          <ErrorMessage>{meta.error}</ErrorMessage>
                        )}
                      </>
                    )}
                  </Field>
                  {lumpsumSet && (
                    <Field
                      name="expectedAnnualMileage"
                      validate={requiredValidator}
                    >
                      {({ input, meta }) => (
                        <>
                          <label>
                            Expected annual mileage of vehicle (miles)
                          </label>
                          <FieldInputWrapper>
                            <Numeric
                              {...input}
                              valid={isFieldValidAndTouched(meta)}
                              onBlur={() => { onBlurFieldHandler(input, `Expected annual mileage of vehicle (miles)`, ' miles', meta); }}
                              suffix={input.value > 1 ? " miles" : " mile"}
                            />
                          </FieldInputWrapper>
                          {meta.error && meta.touched && (
                            <ErrorMessage>{meta.error}</ErrorMessage>
                          )}
                        </>
                      )}
                    </Field>
                  )}
                </>
              )}
              <ButtonContainer>
                <Button type="submit">Next</Button>
              </ButtonContainer>
            </form>
          )}
        />
      </AccordionItemPanel>
    </AccordionItem>
  );
};

export default AdditionalAssetDetails;
