import { constsNatWest, formatter, stageVal } from "resources/constants";

// Warning: the order of the choice ids
// (i.e. loan.purpose=1, loan.secured=2, loan.secured.property_owned=3 etc)
// has to match the Custom Dimensions defined in Google Analytics

export const quoteStages = [
  {
    stage: stageVal.loan,
    progress: "Loan and security",
    title: "Loan details and security",
    choices: [
      { id: ["loan.purpose"] },
      {
        id: [
          "loan.secured",
          "loan.secured.property_owned",
          "loan.secured.property_value",
          "loan.secured.property_purchase",
          "loan.secured.other_asset",
        ],
      },
      { id: ["loan.amount"] },
      { id: ["loan.term"] },
    ],
  },
  {
    stage: stageVal.business,
    progress: "Business details",
    title: "Business details",
    choices: [
      { id: ["business.sector", "business.sector.leisure"] },
      { id: ["business.trading"] },
      {
        id: [
          "business.entity",
          "business.financial",
          "business.financial.useCalculator",

          "business.financial.operatingProfit",
          "business.financial.netProfit", // non-ltd
          "business.financial.interest", // non-ltd
          "business.financial.depreciation",
          "business.financial.amortisation",
          "business.financial.lossOnDisposalFixedAssets",
          "business.financial.profitOnDisposalFixedAssets",
          "business.financial.exceptionalAddBacks",
          "business.financial.exceptionalItem",
          "business.financial.ebitdaDraft",
          "business.financial.dividends",
          "business.financial.dividends2", // non-ltd

          "business.financial.ebitda",
          "business.financial.director_remuneration",
          "business.financial.existing_debt",
          "business.financial.gross_rent"
        ],
      },
    ],
  },
  {
    stage: stageVal.summary,
    progress: "Quote summary",
    title: "Your quote summary",
  },
  {
    stage: stageVal.refer,
    progress: "Refer quote",
    title: "Refer your quote",
    description:
      "To refer your quote to us, just supply a few contact details for you and your client.",
    warning:
      'Our <a target="_blank" rel="noopener noreferrer" href="https://personal.natwest.com/personal/privacy-policy.html"><u>Privacy Policy</u></a> explains how we\'ll use the information you provide from yourself and your client.<br/>Please send a copy of our <a target="_blank" rel="noopener noreferrer" href="https://personal.natwest.com/content/dam/natwest/personal/Privacy/234018146-nwpl-november-2019-web.pdf"><u>privacy notice</u></a> to your client.<br/>It is your responsibility to only refer clients in line with the FCA permissions that you currently hold.',
    sections: [
      {
        id: "refer.broker",
        title: "Your details",
        choices: [
          { id: ["refer.broker.forename"] },
          { id: ["refer.broker.surname"] },
          { id: ["refer.broker.company"] },
          { id: ["refer.broker.email"] },
          { id: ["refer.broker.phone"] },
          { id: ["refer.broker.contact"] },
        ],
      },
      {
        id: "refer.client",
        title: "Client details",
        choices: [
          { id: ["refer.client.forename"] },
          { id: ["refer.client.surname"] },
          { id: ["refer.client.company"] },
          {
            id: [
              "refer.client.postcodeLookup",
              "refer.client.address1",
              "refer.client.address2",
              "refer.client.town",
              "refer.client.postcode",
            ],
          },
          { id: ["refer.client.email", "refer.broker.contact"] },
          { id: ["refer.client.phone", "refer.broker.contact"] },
        ],
      },
      {
        id: "refer.additional",
        title: "Additional details (optional)",
        choices: [
          //          { id: ["refer.additional.documents"] },
          { id: ["refer.additional.information"] },
        ],
      },
      {
        id: "refer.confirm",
        title: "Confirm and submit",
        choices: [{ id: ["refer.confirm.confirmed"] }],
      },
    ],
  },
  {
    stage: stageVal.confirmRefer,
    title: "Thanks, we've received your referral",
  },
  {
    stage: stageVal.unhappy,
    title: "We'll need to talk before continuing your indicative quote",
    description:
      "Unfortunately as we're in beta, we're unable to provide an online quote based on your requirements. Enter your details below and a broker business development manager will get in touch to discuss your client's borrowing needs.",
    choices: [
      { id: ["unhappy.forename"] },
      { id: ["unhappy.surname"] },
      { id: ["unhappy.email"] },
      { id: ["unhappy.phone"] },
    ],
  },
  {
    stage: stageVal.confirmUnhappy,
    title: "We'll need to talk before continuing your indicative quote",
    description:
      "Thanks, we’ve got your details and a broker business development manager will be in touch within 24 hours.",
  },
];

export const allFinancials = [
  {
    id: "business.financial",
    title: "Do you have your client’s financial information?",
    description:
      "We use EBITDA as our preferred method of determining affordability and it can be calculated using your client’s most recent set of accounts. We can assist you with calculating EBITDA below and will validate this as part of the application process.",
    required: "Tell us if you know your client's financial information",
    options: [
      {
        label: "Yes, I have the client's financial information",
        value: "yes",
        layout: "fullwidth",
        radioFreetext: [
          {
            id: "business.financial.useCalculator",
            title: "Would you like to use our EBITDA Calculator?",
            required: "Tell us if you want to use the calculator",
            layout: "fullwidth",
            options: [


              
              {
                label: "Yes",
                value: "yes",
                layout: "fullwidth",
                radioFreetext: [

                  // for ltd
                  {
                    id: "business.financial.operatingProfit",
                    type: "currency",
                    title: "Operating Profit",
                    tooltip: 'Also called \'Profit Before Interest & Tax (PBIT)\'',
                    required: "Enter operating profit",
                    layout: "fullwidth",
                    condition: {
                      key: 'business.entity',
                      value: 'ltd' 
                    }
                  },

                  // for non-ltd
                  {
                    id: "business.financial.netProfit",
                    type: "currency",
                    title: "Net profit",
                    required: "Enter net profit",
                    layout: "fullwidth",
                    condition: {
                      key: 'business.entity',
                      value: 'ltd',
                      operator: '!='
                    }
                  },
                  {
                    id: "business.financial.interest",
                    type: "currency",
                    title: "Interest",
                    required: "Enter Interest",
                    layout: "fullwidth",
                    condition: {
                      key: 'business.entity',
                      value: 'ltd',
                      operator: '!='
                    }
                  },


                  // common for all entity types
                  {
                    id: "business.financial.depreciation",
                    type: "currency",
                    title: "Depreciation",
                    required: "Enter Depreciation",
                    layout: "fullwidth"
                  },
                  {
                    id: "business.financial.amortisation",
                    type: "currency",
                    title: "Amortisation",
                    required: "Enter Amortisation",
                    layout: "fullwidth"
                  },
                  {
                    id: "business.financial.lossOnDisposalFixedAssets",
                    type: "currency",
                    title: "Loss on disposal of fixed assets",
                    required: "Enter Loss on disposal of fixed assets",
                    layout: "fullwidth"
                  },
                  {
                    id: "business.financial.profitOnDisposalFixedAssets",
                    type: "currency",
                    title: "Profit on disposal of fixed assets",
                    required: "Enter Profit on disposal of fixed assets",
                    layout: "fullwidth"
                  },
                  {
                    id: "business.financial.exceptionalAddBacks",
                    type: "currency",
                    title: "Exceptional add backs",
                    tooltip: 'Items such as non-repeating rent, bad debts, exceptional repairs and renewals etc',
                    required: "Enter Exceptional add backs",
                    layout: "fullwidth"
                  },
                  {
                    id: "business.financial.exceptionalItem",
                    type: "currency",
                    title: "Exceptional items to be deducted",
                    tooltip: 'COVID Grants, JRS/Furlough, insurance payments etc',
                    required: "Enter Exceptional item to be deducted",
                    layout: "fullwidth"
                  },
        
        
                  {
                    id: "business.financial.ebitdaDraft",
                    type: "currency-disabled",
                    title: "Equals EBITDA",
                    required: "Calculated draft annual EBITDA",
                    layout: "fullwidth"
                  },
        
                  
                  // for ltd
                  {
                    id: "business.financial.dividends",
                    type: "currency",
                    title: "Dividends/Drawings need",
                    tooltip: 'Please enter dividends taken or the amount the owners of the business need to draw to cover personal living expenses in excess of director\'s remuneration stated in the P&L. If the latter this must be confirmed by an income and expenditure.',
                    required: "Enter Dividends/Drawing need",
                    layout: "fullwidth",
                    condition: {
                      key: 'business.entity',
                      value: 'ltd' 
                    }
                  },
                  // for non-LTD
                  {
                    id: "business.financial.dividends2",
                    type: "currency",
                    title: "Total drawings taken/Drawings need",
                    tooltip: 'Please enter the amount the owner(s) need to draw to cover personal living expenses. This should be confirmed by an  income and expenditure',
                    required: "Enter Total drawings taken/Drawings need",
                    layout: "fullwidth",
                    condition: {
                      key: 'business.entity',
                      value: 'ltd',
                      operator: '!='
                    }
                  },
        
        
        
        
        
        
        
        
        
        
        
                  {
                    id: "business.financial.ebitda",
                    type: "currency-disabled",
                    title: "Equals Adjusted EBITDA",
                    required: "Enter Equals Adjusted EBITDA",
                    layout: "fullwidth",
                  },
                  // {
                  //   id: "business.financial.director_remuneration",
                  //   type: "currency",
                  //   title: "Enter annual total director remuneration",
                  //   tooltip: "Enter annual dividends required",
                  //   required: "Enter annual total director remuneration",
                  //   layout: "fullwidth",
                  //   condition: {
                  //     key: 'business.entity',
                  //     value: 'ltd'
                  //   }
                  // },
                  {
                    id: "business.financial.existing_debt",
                    type: "currency",
                    title: "Enter annual debt servicing cost",
                    tooltip:
                      "Includes interest, fees and costs on: overdrafts, capital and interest payments on existing NatWest borrowing and any other form of borrowing (e.g. other bank debt, asset finance, trade instruments, third parties, repayment of direct loans).  Do not include the cost of the loan you are applying for in this figure.",
                    required: "Enter annual debt servicing cost",
                    layout: "fullwidth",
                  },
                ]
              }, 
              {
                label: "No, I will calculate EBITDA myself",
                value: "no",
                layout: "fullwidth",
                radioFreetext: [
                  {
                    id: "business.financial.ebitda",
                    type: "currency",
                    title: "Enter annual EBITDA",
                    required: "Enter annual EBITDA",
                    layout: "fullwidth",
                  },
                  {
                    id: "business.financial.director_remuneration",
                    type: "currency",
                    title: "Enter annual total director remuneration",
                    tooltip: "Enter annual dividends required",
                    required: "Enter annual total director remuneration",
                    layout: "fullwidth",
                    condition: {
                      key: 'business.entity',
                      value: 'ltd'
                    }
                  },
                  {
                    id: "business.financial.existing_debt",
                    type: "currency",
                    title: "Enter annual debt servicing cost",
                    tooltip:
                      "Includes interest, fees and costs on: overdrafts, capital and interest payments on existing NatWest borrowing and any other form of borrowing (e.g. other bank debt, asset finance, trade instruments, third parties, repayment of direct loans).  Do not include the cost of the loan you are applying for in this figure.",
                    required: "Enter annual debt servicing cost",
                    layout: "fullwidth",
                  },
                ]
              }
            ]
          },
        ]
      }, 
      {
        label: "No, I don't currently have the client's financial information",
        value: "no",
        layout: "fullwidth",
        warning: {
          label: "You can proceed without adding this information, but your quote will be less accurate.",
        },
      }
    ]
  }
];

export const nonLtdFinancials = [
  {
    id: "business.financial",
    title: "Do you know your client's financial information?",
    description:
      "EBITDA and annual debt servicing cost. This can be an estimate and should be for the latest financial year. We'll validate this part as part of the application progress.",
    required: "Tell us if you know your client's financial information",
    options: [
      {
        label: "Yes, I have the client's financial information",
        value: "yes",
        layout: "fullwidth",
        radioFreetext: [
          {
            id: "business.financial.ebitda",
            type: "currency",
            title: "Enter annual EBITDA",
            required: "Enter annual EBITDA",
            layout: "fullwidth",
          },
          {
            id: "business.financial.existing_debt",
            type: "currency",
            title: "Enter annual debt servicing cost",
            tooltip:
              "Includes interest, fees and costs on: overdrafts, capital and interest payments on existing NatWest borrowing and any other form of borrowing (e.g. other bank debt, asset finance, trade instruments, third parties, repayment of direct loans). Do not include the cost of the loan you are applying for in this figure.",
            required: "Enter annual debt servicing cost",
            layout: "fullwidth",
          },
        ],
      },
      {
        label: "No, I don't currently have the client's financial information",
        value: "no",
        layout: "fullwidth",
        warning: {
          label:
            "You can proceed without adding this information, but your quote will be less accurate.",
        },
      },
    ],
  },
];

export const realEstateFinancials = [
  {
    id: "business.financial",
    title: "Do you know your client's financial information?",
    description:
      "You will need to know the gross rent of the portfolio or property we are lending against. Where lending against commercial property, we have a very strong preference for leases in excess of 5 years without any break clauses in this period.",
    required: "Tell us if you know your client's financial information",
    options: [
      {
        label: "Yes, I have the client's financial information",
        value: "yes",
        layout: "fullwidth",
        radioFreetext: [
          {
            id: "business.financial.gross_rent",
            type: "currency",
            title: "Enter the total gross rent of your client’s portfolio",
            required: "Enter the total gross rent",
            layout: "fullwidth",
          },
        ],
      },
      {
        label: "No, I don't currently have the client's financial information",
        value: "no",
        layout: "fullwidth",
        warning: {
          label:
            "You can proceed without adding this information, but your quote will be less accurate.",
        },
      },
    ],
  },
];

export const loanChoices = [
  {
    id: "loan.purpose",
    title: "What is the purpose of the loan?",
    description:
      "Ensure the option you select is in line with the FCA permissions that you currently hold.",
    required: "Select a loan purpose",
    options: [
      {
        label: "Property purchase",
        value: "property",
      },
      {
        label: "Asset purchase",
        value: "asset",
      },
      {
        label: "Vehicle purchase",
        value: "vehicle",
      },
      {
        label: "Refinance of existing loan",
        value: "refinance",
      },
      {
        label: "Business growth",
        value: "growth",
      },
      {
        label: "Debt consolidation",
        value: "consolidation",
      },
      {
        label: "Cash flow",
        value: "cashflow",
      },
      {
        label: "Other purpose",
        value: "other",
      },
    ],
  },
  {
    id: "loan.secured",
    title: "What is this loan secured against?",
    required: "Tell us if the loan is to be secured",
    options: [
      {
        label: "Property",
        value: "property",
        layout: "triple",
        options: [
          {
            id: "loan.secured.property_owned",
            title:
              "Does the business currently own the property to be offered as security?",
            tooltip:
              "For customers within sub-sectors: Hotels / Short Stay Leisure Accommodation, Pubs & Bars, Restaurants (excluding Take-Aways) and Sporting Activities; we can consider a Going Concern Value",
            description:
              "For customers within sub-sectors: Hotels / Short Stay Leisure Accommodation, Pubs & Bars, Restaurants (excluding Take-Aways) and Sporting Activities; we can consider a Going Concern Value.",
            required: "Tell us if the business already owns this property",
            options: [
              {
                label: "Yes",
                value: "yes",
                layout: "fullwidth",
                radioFreetext: [
                  {
                    id: "loan.secured.property_value",
                    type: "currency",
                    layout: "fullwidth",
                    title:
                      "What is the value of the property to be offered as security?",
                    required: "Enter the value of the property",
                  },
                ],
              },
              {
                label: "No",
                value: "no",
                layout: "fullwidth",
                radioFreetext: [
                  {
                    id: "loan.secured.property_purchase",
                    type: "currency",
                    layout: "fullwidth",
                    title:
                      "What is the purchase price of the property to be offered as security?",
                    required: "Enter the purchase price of the property",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: "Other asset",
        value: "other",
        layout: "triple",
        freetext: [
          {
            id: "loan.secured.other_asset",
            title: "What asset will be offered as security?",
            required: "Enter the name of the asset offered as security",
          },
        ],
      },
      {
        label: "No security",
        value: "none",
        layout: "triple",
      },
    ],
  },
  {
    id: "loan.amount",
    required: `Enter a value between ${formatter.format(
      constsNatWest.minAmount
    )} and ${formatter.format(constsNatWest.maxAmount)}`,
    freetext: [
      {
        id: "loan.amount",
        type: "currency",
        title: "What is the loan amount needed?",
        tooltip:
          "For lending above £750,000 contact your Broker Business Development Manager.",
        description: `Enter an amount between ${formatter.format(
          constsNatWest.minAmount
        )} and ${formatter.format(constsNatWest.maxAmount)}`,
      },
    ],
  },
  {
    id: "loan.term",
    required:
      "Enter a number of years between 1 and %max_term% for the term length",
    freetext: [
      {
        id: "loan.term",
        type: "time",
        title: "What is the term length needed?",
        description: "Enter a term between 1 year and %max_term% years (note the maximum term for Real Estate Investment is 15 years)",
      },
    ],
  },
  {
    id: "business.sector",
    title: "What is the primary business sector of your client?",
    required: "Select a primary business sector",
    data: "sectors",
  },
  {
    id: "business.trading",
    title: "How long have they been trading?",
    required: "Tell us how long your client has been trading",
    options: [
      {
        label: "Less than 2 years",
        value: "under_2_years",
        layout: "triple",
      },
      {
        label: "2 to 3 years",
        value: "2/3_years",
        layout: "triple",
      },
      {
        label: "3+ years",
        value: "over_3_years",
        layout: "triple",
      },
    ],
  },


  
  {
    id: "business.entity",
    title: "What is the client's legal business entity?",
    required: "Select the client's legal business entity",
    optionCondition: {
      condition: {
        key: 'business.sector',
        value: '10' 
      },
      name: 'optionsRealEstate'
    },
    options: [
      {
        label: "Limited company",
        value: "ltd",
        layout: "triple",
        options: allFinancials,
      },
      {
        label: "Partnership",
        value: "partnership",
        layout: "triple",
        // options: nonLtdFinancials,
        options: allFinancials,
      },
      {
        label: "Sole trader",
        value: "sole",
        layout: "triple",
        // options: nonLtdFinancials,
        options: allFinancials,
      },
    ],
    optionsRealEstate: [
      {
        label: "Limited company",
        value: "ltd",
        layout: "triple",
        options: realEstateFinancials,
      },
      {
        label: "Partnership",
        value: "partnership",
        layout: "triple",
        options: realEstateFinancials,
      },
      {
        label: "Sole trader",
        value: "sole",
        layout: "triple",
        options: realEstateFinancials,
      },
    ]
  },
  {
    id: "refer.broker.forename",
    title: "Your first name",
    required: "Enter your first name",
    freetext: [
      {
        id: "refer.broker.forename",
      },
    ],
  },
  {
    id: "refer.broker.surname",
    title: "Your last name",
    required: "Enter your last name",
    freetext: [
      {
        id: "refer.broker.surname",
      },
    ],
  },
  {
    id: "refer.broker.email",
    subsection: "Your contact details",
    title: "Your email address",
    required: "Enter a valid email address",
    freetext: [
      {
        id: "refer.broker.email",
        type: "email",
      },
    ],
  },
  {
    id: "refer.broker.phone",
    title: "Your phone number",
    required: "Enter a valid UK phone number",
    freetext: [
      {
        id: "refer.broker.phone",
        type: "phone",
      },
    ],
  },
  {
    id: "refer.broker.company",
    title: "Your company name",
    required: "Enter your company name",
    freetext: [
      {
        id: "refer.broker.company",
      },
    ],
  },
  {
    id: "refer.broker.contact",
    title: "Who is the main point of contact for the application?",
    description:
      "Select who you'd like us to contact about this application. If you choose client, we'll still update you on the progress of the application.",
    required: "Select the main point of contact for the application",
    options: [
      {
        label: "Broker",
        value: "broker",
      },
      {
        label: "Client",
        value: "client",
      },
    ],
  },
  {
    id: "refer.client.forename",
    title: "Client's first name",
    required: "Enter the first name of your client",
    freetext: [
      {
        id: "refer.client.forename",
      },
    ],
  },
  {
    id: "refer.client.surname",
    title: "Client's last name",
    required: "Enter the last name of your client",
    freetext: [
      {
        id: "refer.client.surname",
      },
    ],
  },
  {
    id: "refer.client.company",
    title: "What is the registered business name?",
    required: "Enter your client's company name",
    freetext: [
      {
        id: "refer.client.company",
        type: "company_lookup",
        description:
          "Or enter the registered company number to look up the name",
      },
    ],
  },
  {
    id: "refer.client.address1",
    required: "Enter first line of address",
    freetext: [
      {
        id: "refer.client.address1",
        description: "Address line 1",
      },
    ],
  },
  {
    id: "refer.client.address2",
    freetext: [
      {
        id: "refer.client.address2",
        description: "Address line 2",
      },
    ],
  },
  {
    id: "refer.client.town",
    required: "Enter postal town of address",
    freetext: [
      {
        id: "refer.client.town",
        description: "Town or city",
      },
    ],
  },
  {
    id: "refer.client.postcode",
    required: "Enter a valid UK postcode",
    freetext: [
      {
        id: "refer.client.postcode",
        type: "postcode",
        description: "Postcode",
      },
    ],
  },
  {
    id: "refer.client.postcodeLookup",
    title: "Client's business address",
    freetext: [
      {
        id: "refer.client.postcodeLookup",
        type: "address_lookup",
      },
    ],
  },
  {
    id: "refer.client.email",
    subsection: "Client contact details",
    title: "Client's email address",
    required: "Enter your client's email address",
    displayIf: { id: "refer.broker.contact", value: "client" },
    freetext: [
      {
        id: "refer.client.email",
        type: "email",
      },
    ],
  },
  {
    id: "refer.client.phone",
    title: "Client's phone number",
    required: "Enter a valid UK phone number",
    displayIf: { id: "refer.broker.contact", value: "client" },
    freetext: [
      {
        id: "refer.client.phone",
        type: "phone",
      },
    ],
  },
  {
    id: "refer.additional.documents",
    title: "Supporting documents (optional)",
    description:
      "Upload any documents to support this application. This could include financial accounts, business plans, property details or valuations.",
  },
  {
    id: "refer.additional.information",
    title:
      "Include any further details that you think could be relevant to this referral",
    freetext: [
      {
        id: "refer.additional.information",
        type: "textarea",
        layout: "fullwidth",
      },
    ],
  },
  {
    id: "refer.confirm.confirmed",
    required: "You must confirm in order to continue",
    checkbox: [
      {
        id: "refer.confirm.confirmed",
        label:
          "I confirm that I've provided my client with a copy of your privacy notice and have consent to share their information.",
        value: "confirmed",
      },
    ],
  },
  {
    id: "unhappy.forename",
    required: "Enter your first name",
    freetext: [
      {
        id: "unhappy.forename",
        title: "Your first name",
      },
    ],
  },
  {
    id: "unhappy.surname",
    required: "Enter your last name",
    freetext: [
      {
        id: "unhappy.surname",
        title: "Your last name",
      },
    ],
  },
  {
    id: "unhappy.email",
    required: "Enter a valid email address",
    freetext: [
      {
        id: "unhappy.email",
        title: "Your email address",
        type: "email",
      },
    ],
  },
  {
    id: "unhappy.phone",
    required: "Enter a valid UK phone number",
    freetext: [
      {
        id: "unhappy.phone",
        title: "Your phone number",
        type: "phone",
      },
    ],
  },
];

// ONLY used for tracking in analyticsHelper.js
export const optionsForBusinessSector = {
  options: [
    {
      label: "Manufacturing",
      value: "1",
    },{
      label: "Leisure",
      value: "2",
    },{
      label: "Retail and Wholesale",
      value: "3",
    },{
      label: "Professional services",
      value: "4",
    },{
      label: "Technology, Media and Telecommunications",
      value: "5",
    },{
      label: "Building and construction",
      value: "6",
    },{
      label: "Other",
      value: "7",
    },{
      label: "Agriculture",
      value: "8",
    },{
      label: "Healthcare",
      value: "9",
    },{
      label: "Real estate",
      value: "10",
    },{
      label: "Hotels or short stay accommodation",
      value: "11",
    },{
      label: "Pubs and bars",
      value: "12",
    },{
      label: "Restaurants (excluding takeaways)",
      value: "13",
    },{
      label: "Sporting activities",
      value: "14",
    },{
      label: "Doctor and Dentist",
      value: "15",
    },{
      label: "Opticians",
      value: "16",
    },{
      label: "Vets",
      value: "17",
    },{
      label: "Other Healthcare",
      value: "18",
    }
  ]
};