import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';

import { ReactComponent as EditIcon } from 'resources/images/edit_purple.svg';
import { brokerAuth, subContext, debugConsole } from 'resources/utils';
import { trackApplicationStepName } from 'resources/analyticsHelper';
import { colours, Button, TextLink, media } from 'resources/global-styles';
import { QuoteContext } from '_Broker/pages/Quote';
import Choice from './Choice';

export const SectionTitle = styled.label`
  position: relative;
  color: ${colours.natWestPurple};
  font-size: 19px;
  font-weight: bold;
  padding: 30px 0;
  width: 100%;
  border-top: 1px solid ${colours.mediumGrey};
  display: block;
  cursor: pointer;

  & img {
    margin-left: 15px;
  }
  & div {
    float: right;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  &:hover {
    text-decoration: underline;
  }

  @media ${media.tablet} {
    font-size: 26px;
  }
`
const Div = styled.div`
  & h2 {
    margin: 10px 0;
  }
`

const Section = ({ section, context, handleChange, handleOpen, isOpen, index, isRetrieved }) => {
  const isInvalid = section.choices.reduce((invalid, obj) => invalid || obj.id.reduce((valid,id) => valid || (context[id].valid === false), false), false);
  const isValid = section.choices.reduce((valid, obj) => valid && obj.id.reduce((valid,id) => valid && (context[id].valid === true), true), true);
  const show = isOpen || isInvalid;

  const myRef = useRef(null)

  useEffect(() => { // disable page scrolling if modal open
    if (isOpen) {
      const broker = brokerAuth();
      const sectionId = section.id.match(/^refer.(\w*)$/);

      debugConsole('Track quote stage', `/${broker}/${isRetrieved ? 'retrieve-' : ''}quote/4-refer/${index+1}-${sectionId[1]}`);
    }
  }, [isOpen])


  return (
    <>
      <SectionTitle ref={myRef} onClick={() => handleOpen(index, myRef)}>
        {section.title}
        {isValid && <img src='/images/input-valid-icon.svg' alt='validated' />}
        {!show && (isValid || isInvalid) && <TextLink><EditIcon />Edit</TextLink>}
      </SectionTitle>
      <Div>
        {show && section.choices && section.choices.map((obj, i) => (
          <QuoteContext.Provider key={`choice_${i}`} value={subContext(obj.id[0], context)}>
            <Choice choice={obj} handleChange={handleChange} />
          </QuoteContext.Provider>
        ))}
        {show && index < 3 && <div style={{textAlign: 'right'}}><Button onClick={() => handleOpen(index+1, myRef)}>Next</Button></div>}
      </Div>
    </>
  );
}

const Sections = ({ sections, handleChange, buildTree, isRetrieved }) => {
  const [open, setOpen] = useState(0);


  const handleOpen = (newOpen, ref) => {
    window.scrollTo(0, ref.current.offsetTop);

    // Trigger validation if another section is already open
    if(open > -1) {
      sections[open].choices.forEach(obj => obj.id.forEach(choice => handleChange(choice)));
    }

    setOpen(newOpen === open ? -1 : newOpen);
  }

  return sections.map(( obj, index ) => <Section key={`section_${index}`} section={obj} context={buildTree(obj)} handleChange={handleChange} handleOpen={handleOpen} isOpen={open === index} index={index} isRetrieved={isRetrieved} />);
}

export default Sections;
