import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import { companiesHouse, regExp } from 'resources/constants';
import { fetchPlus, validFormat } from 'resources/utils';

import { colours, Input } from 'resources/global-styles';
import { QuoteContext } from '_Broker/pages/Quote';

const PopupList = styled.ul`
  padding: 0;
  background-color: ${colours.lightGrey};
  width: 100%;
  max-height: 310px;
  margin-top: -20px;
  overflow-y: scroll;

  & li {
    width: 100%;
    padding: 20px 20px 0 20px;
    color: ${colours.natWestPurple};  
    cursor: pointer;
    list-style: none;
  }
`

const Company = ({ choiceId, handleChange }) => {
  const context = useContext(QuoteContext);
  const [list, setList] = useState([]);

  const handleTextChange = async (newVal, bLookup) => {
    setList([]);
    handleChange({choiceId: choiceId, choiceVal: newVal});

    if (bLookup && validFormat(newVal, regExp.companyNumber)) { // only lookup if it's a valid company number
      const url = `${companiesHouse.url}?q=${newVal}&items_per_page=10`;
      const response = await fetchPlus(url, false, null, { headers: {
        Authorization: `Basic ${companiesHouse.authorization}`,
      }});

      if (response.json && response.json.items) {
        setList(response.json.items);
      }
    }
  }

  return (
    <>
      <Input
        value={context.value ? context.value : ''}
        onChange={(evt) => handleTextChange(evt.target.value, true)}
        onBlur={() => handleChange(choiceId)}
        valid={context.valid}
        maxLength='255'
      />
      <PopupList>
        {!!list.length && list.map((obj, i) => {
          const label = `${obj.title}, ${obj.address_snippet}`;
          return <li key={`address_${i}`} onClick={() => handleTextChange(label, false)}>{label}</li>
        })}
      </PopupList>
    </>
  )
}


export default Company;
