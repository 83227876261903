import React from 'react';
import { PDFDownloadLink, Document, Page, View, Text as Txt, Image, Font } from '@react-pdf/renderer'
import styled from 'styled-components';
import pdfStyled from '@react-pdf/styled-components';
import ReactGA from 'react-ga';
import { ReactComponent as BackIcon } from "resources/images/download_white.svg";

import { colours, Button } from 'resources/global-styles';
import { tableData } from 'resources/utils';

const PDFButton = styled(Button)`

  border: none;
  margin-top: 10px;
  margin-bottom: 44px;
  line-height: 30px;
  padding: 0px 32px;

  .flex-container {
    display: flex;
  }

  .icon {
    content: url('/images/download_white.svg');
    display: inline-block;
    margin-right: 5px; 
    width: 24px;
    margin-right: 20px;
  }
`
const Text = pdfStyled(Txt)`
  font-family: 'RNHouseSansRegular';
  font-size: 14px;
  margin-bottom: 20px;
`
const SmallText = pdfStyled(Text)`
  text-transform: none;
  font-family: 'RNHouseSansRegular';
  font-size: 14px;
  margin-bottom: 10px;
`
const BoldText = pdfStyled(Txt)`
  font-family: 'RNHouseSansBold';
  font-size: 14px;
  border-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  margin: 20px 0 40px 0;
  margin: 10px 0 20px 0;
`
const Header = pdfStyled(View)`
  text-align: center;
`
const Logo = pdfStyled(Image)`
  width: 64px;
  margin: 30px auto 0 auto;
`
const Main = pdfStyled(View)`
  margin: 30px;
`
const Title = pdfStyled(Text)`
  font-family: 'RNHouseSansBold';
  font-size: 24px;
  text-align: center;
  margin-bottom: 30px;
`
const Table = pdfStyled(View)`
  display: table;
  width: auto;
  border-style: solid;
  border-width: 1;
  border-right-width: 0;
  border-bottom-width: 0;
  margin: 10px 0 20px 0;
`
const Row = pdfStyled(View)`
  margin: auto;
  flex-direction: row;
`
const Col = pdfStyled(View)`
  width: 50%;
  border-style: solid;
  border-width: 1;
  border-left-width: 0;
  border-top-width: 0;
`
const Cell = pdfStyled(Text)`
  margin: 10px 0 10px 10px;
`
const Footer = pdfStyled(Text)`
  text-transform: uppercase;
  font-size: 14px;
`

Font.register({ family: 'RNHouseSansBold', src: '/fonts/RNHouseSansW01Bold.ttf' });
Font.register({ family: 'RNHouseSansRegular', src: '/fonts/RNHouseSansW01Regular.ttf' });

const SummaryPDF = ({ tableData }) => (
  <Document>
    <Page size="A4">
      <Header>
        <Logo src="/images/pdf-logo-purple.png" />
      </Header>
      <Main>
        <Title>Broker indicative quote</Title>
        <BoldText style={{ marginBottom: '5px' }}>This quote is for the use of credit brokers and other intermediaries only.</BoldText>
        <BoldText>It should not be directly distributed to the customer.</BoldText>
        <Text>This quote is for indicative purposes ONLY and does not constitute an offer. If you choose to apply, a full credit and affordability check will be carried out and your actual quote may vary.</Text>
        <Text>Security fees may apply and repayments may vary depending on loan start date or repayment profile.</Text>
        <Table>
          {tableData.map((obj, i) => (
            <Row key={`row_${i}`}>
              <Col><Cell>{obj.label}</Cell></Col>
              <Col><Cell>{obj.value}</Cell></Col>
            </Row>
          ))}
        </Table>
        <Footer>ANY PROPERTY USED AS SECURITY, WHICH MAY INCLUDE YOUR HOME, MAY BE REPOSSESSED IF YOU DO NOT KEEP UP REPAYMENTS ON A MORTGAGE OR OTHER DEBT SECURED ON IT.
          {"\n"}
          {"\n"}
          <SmallText>Please note that, for the purposes of interest rate calculation, where NatWest Bank base rate is below zero, it will be deemed to be zero.</SmallText>
        </Footer>
        </Main>
    </Page>
  </Document>
)

const PDF = ({ product, chosen, start }) => {
  if (!start) return <PDFButton>[generating]</PDFButton>

  const trackClick = () => {
    
  }

  return (
    <PDFDownloadLink document={<SummaryPDF tableData={tableData(product, chosen)} />} fileName="quote_summary.pdf">
      {({ blob, url, loading, error }) => loading ? <PDFButton>[generating]</PDFButton> :
        <PDFButton onClick={() => trackClick()}>
          <div className="flex-container">
            <span className="icon"><BackIcon/></span>
            <span>Download loan details</span>
          </div>
        </PDFButton>}
    </PDFDownloadLink>
  );
}

export default PDF;
