import React, { useEffect, useState } from "react";
import Header from "components/Header";
import Footer from "_Broker/structure/Footer";
import Page from "_Broker/structure/Page";
import styled from "styled-components";
import { colours, Button } from "resources/global-styles";
import RadioWrapper from "../components/RadioWrapper";
import { ReactComponent as BackIcon } from "../resources/images/chevron_purple.svg";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { brokerAuth } from "../resources/utils";
import { useAppContext } from "../context/appContext";
import { products, financeType } from "../resources/constants";
import { useLombardContext } from "../_Lombard/context/lombardContext";
import LombardRoutes from "../_Lombard/utils/routes";
import {
  trackLombardApplicationStart,
  trackPageTransitionGetStarted,
  trackRadioButtonSelection,
  trackButtonClick,
  trackValidationError
} from "../resources/analyticsHelper";

const Main = styled.div`
  flex: 1 0 auto;
`;

const PageModified = styled(Page)`
  min-height: calc(100vh - 61px);

  & h1 {
    margin-top: 38px;
    font-size: 36px;
    color: ${colours.title_text};
  }
`;


const PrevNextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 60px;
`;

const PrevButton = styled.button`
  height: 60px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: ${colours.btn_copy};
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }

  & svg {
    height: 18px;
    transform: rotate(180deg);
    margin-right: 10px;
    margin-top: 0px;
  }
`;

const GetStartedPage = () => {
  const { data, actions } = useAppContext();
  const lombardContext = useLombardContext();
  const [selectedOption, setSelectedOption] = useState(data.selectedOption);
  const [selectedProduct, setSelectedProduct] = useState(data.selectedProduct);
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [productValidationTriggered, setProductValidationTriggered] =
    useState(false);
  const history = useHistory();
  useEffect(() => {
    trackPageTransitionGetStarted("Both");
  }, []);

  const handleOptionChange = (value) => {
    let val;
    if (value === 'loan') {
      val = financeType.options[0].label
    } else if (value === 'asset') {
      val = financeType.options[1].label
    }
    trackRadioButtonSelection('Do you want a quote for a loan or asset finance?', val);

    setSelectedOption(value);
  };
  const handleProductChange = (value) => {
    trackRadioButtonSelection('What product are you looking for?', value);
    setSelectedProduct(value);
  };
  const match = useRouteMatch();
  const homepageLink = `/${match.params.brokerId}`;
  const handleNextClick = () => {
    setValidationTriggered(true);
    if (selectedOption) {
      actions.addOrUpdateChoice("selectedOption", selectedOption);
      actions.addOrUpdateChoice("selectedProduct", selectedProduct);

      if (selectedOption === "loan") {
        history.push(`/${brokerAuth()}/quote`);
        return;
      }

      if (selectedProduct) {
        lombardContext.actions.fetchAssetTypes();
        if (selectedProduct === "Fixed rate hire purchase") {
          if(lombardContext.state.initialQuoteCreationStarted === false){
            lombardContext.actions.markQuoteCreationStarted();
            trackLombardApplicationStart();
          }
          history.push(LombardRoutes.lombardQuoteRoute());
        } else {
          lombardContext.actions.clearContextState();
          history.push(LombardRoutes.unableToProceedRoute());
        }
      } else {
        setProductValidationTriggered(true);
        trackValidationError(products.validationMessage, `What product are you looking for?`, `Let's get started`);
      }
    } else {
      trackValidationError(products.validationMessage, `Do you want a quote for a loan or asset finance?`, `Let's get started`);
    }
  };

  return (
    <Main>
      <PageModified>
        <Header showNatwestLogo={true} />
        <h1>Let's get started</h1>
        <h3>Do you want a quote for a loan or asset finance?</h3>
        <RadioWrapper
          options={financeType.options}
          radioLayout="fullwidth"
          selectedOption={selectedOption}
          handleOptionChange={handleOptionChange}
          validationTriggered={validationTriggered}
          validationMessage={financeType.validationMessage}
        />
        {selectedOption === "asset" && (
          <>
            <h3>What product are you looking for?</h3>
            <RadioWrapper
              options={products.options}
              selectedOption={selectedProduct}
              handleOptionChange={handleProductChange}
              validationTriggered={productValidationTriggered}
              validationMessage={products.validationMessage}
            />
          </>
        )}
        <PrevNextContainer>
          <Link to={homepageLink}>
            <PrevButton onClick={ (e) => { trackButtonClick('Button', 'Back'); }}>
              <BackIcon />
              Back
            </PrevButton>
          </Link>
          <Button
            onClick={ (e) => {
              trackButtonClick('Button', 'Next'); 
              handleNextClick(e);
            }}
          >Next</Button>
        </PrevNextContainer>
      </PageModified>
      <Footer />
    </Main>
  );
};

export default GetStartedPage;
