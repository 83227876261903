import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

import { debugConsole } from 'resources/utils';

import ErrorPage from '_Broker/pages/ErrorPage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, eventId: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    debugConsole('boundary error', error);
    debugConsole('boundary error info', info);
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      const eventId = Sentry.captureException(error);
      this.setState({eventId});
    });
  }

  render() {
    if (this.state.hasError) return <ErrorPage text='internal' />
    return this.props.children; 
  }
}

export default ErrorBoundary;