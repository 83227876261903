import { brokerAuth } from "../../resources/utils";

const unableToProceedRoute = () => `/${brokerAuth()}/lombard/contact`;
const lombardQuoteRoute = () => `/${brokerAuth()}/lombard/quote`;
const quoteExpiredRoute = () => `/${brokerAuth()}/lombard/quote/expired`;
const quoteReplacedRoute = () => `/${brokerAuth()}/lombard/quote/replaced`;
const quoteAlreadyAppliedRoute = () => `/${brokerAuth()}/lombard/quote/applied`;
const getStarted = () => `/${brokerAuth()}/getStarted`;

export default {
  unableToProceedRoute,
  lombardQuoteRoute,
  quoteExpiredRoute,
  quoteReplacedRoute,
  quoteAlreadyAppliedRoute,
  getStarted,
};
