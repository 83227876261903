import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Page from "../components/Page";
import ProgressBar from "../components/Progress";
import {
  AssetDetails,
  SupportingDetails,
  BusinessDetails,
  QuoteSummary,
} from "./quote/index";
import { stageVal } from "../utils/constants";
import { useLombardContext } from "../context/lombardContext";
import Loader from "../components/Loader";
import ApiFetchService from "../utils/dataFetching";
import LombardRoutes from "../utils/routes";
import QuoteReceived from "./QuoteReceived";
import {
  trackLombardApplicationStepName,
  trackLombardApplicationComplete,
  trackLombardEmailQuoteSummaryRetrieval
} from "../../resources/analyticsHelper";


const renderSelectedStageFunction = (
  stageId,
  assetTypes,
  isLoading,
  referenceNumber,
  isRetrievedForm
) => {
  // console.log('>>> renderSelectedStageFunction', stageId);

  if (!assetTypes || !assetTypes.length || isLoading) {
    return <Loader />;
  }

  switch (stageId) {
    case stageVal.asset:
      return <AssetDetails />;
    case stageVal.business:
      trackLombardApplicationStepName("Business Details");
      return <BusinessDetails />;
    case stageVal.additional:
      trackLombardApplicationStepName("Supporting Details");
      return <SupportingDetails />;
    case stageVal.summary:
      if (!isRetrievedForm) {
        trackLombardApplicationStepName("Quote Summary");
      }
      return <QuoteSummary />;
    default:
      if (referenceNumber) {
        trackLombardApplicationComplete(referenceNumber);
      }
      return <QuoteReceived />;
  }
};

const Quote = () => {
  const { state, actions } = useLombardContext();
  const stageId = state.quote.activeStageId;
  const referenceNumber = state.referenceNumber;
  const { retrievalId } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation();
  const quoteId = Number(search.toLowerCase().split('?quoteid=')[1]);

  const renderSelectedStage = useMemo(
    () =>
      renderSelectedStageFunction(
        stageId,
        state.assetTypes,
        isLoading,
        referenceNumber,
        !!retrievalId
      ),
    [stageId, state.assetTypes, isLoading, referenceNumber, retrievalId]
  );

  useEffect(() => {
    const handleBrowserBack = (historyState) => {
      const name = historyState?.state?.name;
      const stageId =
        name && name.split("___").length > 1 ? name.split("___")[0] : null;
      if (state.quote.activeStageId > 0 && stageId == null) {
        handleStageChange(state.quote.activeStageId - 1);
      } else {
        handleStageChange(stageId * 1);
      }
    };

    const currentHistoryState = window.history.state;
    if (
      state.quote.activeStageId > 0 &&
      (!currentHistoryState ||
        currentHistoryState.name !==
          `${state.quote.activeStageId}___browserBack`)
    ) {
      window.history.pushState(
        { name: `${state.quote.activeStageId}___browserBack` },
        "on browser back click",
        window.location.href
      );
    }
    window.addEventListener("popstate", handleBrowserBack);
    return () => {
      window.removeEventListener("popstate", handleBrowserBack);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.quote.activeStageId]);

  useEffect(() => {
    if (!state.assetTypes || !state.assetTypes.length) {
      actions.fetchAssetTypes();
    }

    if (retrievalId) {
      if (retrievalId.indexOf("LMB") === 0) {
        actions.setIsFromReferenceNumber(true);
        actions.setPrevReferenceNumber(retrievalId);
        actions.setPrevQuoteId(quoteId);
        
        setIsLoading(true);

        ApiFetchService.RetrieveQuoteByReferenceNumber(retrievalId, quoteId)
          .then((data) => {
            actions.fillQuoteFromRetrievedInformation(data);
            trackLombardEmailQuoteSummaryRetrieval("Quote Retrieved");
          })
          .catch((error) => {
            if (error.message === 'Quote is edited and replaced') {
              history.push(LombardRoutes.quoteReplacedRoute());
            } else {
              history.push(LombardRoutes.quoteExpiredRoute());
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(true);

        ApiFetchService.RetrieveQuote(retrievalId)
          .then((data) => {
            actions.fillQuoteFromRetrievedInformation(data);
            trackLombardEmailQuoteSummaryRetrieval("Quote Retrieved");
          })
          .catch((error) => {
            if (error.message === "Quote already applied") {
              history.push(LombardRoutes.quoteAlreadyAppliedRoute());
            } else {
              console.log(error);
              history.push(LombardRoutes.quoteExpiredRoute());
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStageChange = useCallback(
    (id) => {
      actions.setStage(id);
    },
    [actions]
  );

  return (
    <Page
      afterHeaderComponent={
        <>
          {isLoading && <></>}
          {!isLoading && !state.isFromReferenceNumber && (
            <ProgressBar stageId={stageId} handleChange={handleStageChange} />
          )}
        </>
      }
    >
      {renderSelectedStage}
    </Page>
  );
};

export default Quote;
