import React from "react";
import styled from "styled-components";
import { media, colours } from "resources/global-styles";
import { ReactComponent as LogoIcon } from "resources/images/natwest_purple_logo.svg";

const Header = styled.header`
  margin-bottom: 5rem;

  @media ${media.tablet} {
    width: 55%;
  }

  & h1 {
    margin-top: 38px;
    font-size: 36px;
    color: ${colours.title_text};
  }
`;

const LogoWrapper = styled.div`
  padding-top: 50px;
  color: ${colours.natWestPurple};
  display: flex;

  .separation{
    border-left: 1px solid ${colours.natWestPurple};
    height: 33px;
    margin-right: 20px;
    margin-top: -4px;
  }

  @media ${media.mobile}{
    display: block;
    text-align: center;
    width: 100%;
    margin: 0;
    border: none;
    margin-bottom: 20px;
  }
`;

const Logo = styled(LogoIcon)`
  width: 153px;
  height: 33px;
  margin-top: -12px;
  vertical-align: middle;
  height: 50px;
  margin-left: -26px;

  @media (min-width: 768px) and (max-width: 1024px){
    width: 120px;
    margin-right: 5px;
  }

  @media ${media.mobile}{
    width: 100%;
    margin: 0;
    border: none;
    margin-bottom: 20px;
  }
`;

const LogoLombard = styled.img`
  width: 153px;
  height: 33px;
  padding-right: 20px;
  border-right: 1px solid ${colours.natWestPurple};
  margin-right: 20px;
  margin-top: -5px;
  vertical-align: middle;

  @media (min-width: 768px) and (max-width: 1024px){
    width: 120px;
    margin-right: 10px;
    padding-left: 5px;
  }

  @media ${media.mobile}{
    width: 153px;
    height: 33px;
    margin: 0;
    border: none;
    margin-bottom: 20px;
  }
`;

const HeaderComponent = (props) => {
  return (
    <Header>
      <LogoWrapper>
        {props.showNatwestLogo && <Logo alt="NatWest" />}
        {props.showNatwestLogo && <div className="separation"></div>}
        <LogoLombard src="/images/lombard-logo-secondary.png" alt="Lombard" />
        <div>Broker Portal</div>
      </LogoWrapper>
      {props.children}
    </Header>
  );
};

export default HeaderComponent;
