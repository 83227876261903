import React, { useCallback } from "react";
import { brokerAuth } from "../../../resources/utils";
import { useLombardContext } from "../../context/lombardContext";
import AssetDetailsForm from "../../components/AssetDetailsForm";
import PrevNextButtons from "../../components/PrevNextButtons";
import { quoteStages, VAT } from "../../utils/constants";
import { isCarsAssetSelected } from "_Lombard/utils/form";

const PAGE_KEY = "assetDetails";

const getNextStageName = (activeStageId) => {
  const nextStageId = activeStageId + 1;
  const nextStage = quoteStages.filter((x) => x.id === nextStageId)[0];
  return nextStage.label;
};

const handleNextClickTracking = (e) => {
  // console.log('>>> handleNextClickTracking', e);
};

const formFooter = (activeStageId, getStartedLink) => (
  <PrevNextButtons
    backLink={getStartedLink}
    handleNextClickTracking={handleNextClickTracking}
    nextBtnText={`Next step: ${getNextStageName(activeStageId)}`}
  />
);

const AssetDetails = () => {
  const { state, actions } = useLombardContext();
  const { assetTypes } = state;
  const getStartedLink = `/${brokerAuth()}/getstarted`;

  const onSubmit = useCallback(
    (values) => {
      onValuesChange(values);
      actions.setStage(state.quote.activeStageId + 1);
    },
    // eslint-disable-next-line no-use-before-define
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.quote.activeStageId, assetTypes, onValuesChange]
  );

  const onValuesChange = useCallback((values) => {
    if (isCarsAssetSelected(assetTypes, values.assetTypeId)) {
      values.amountFinanced = values.purchasePrice - values.deposit;
    }

    if (!isCarsAssetSelected(assetTypes, values.assetTypeId) && values.vatDefer === 'yes') {
      values.amountFinanced = values.purchasePrice - values.deposit;
    }
    
    if (!isCarsAssetSelected(assetTypes, values.assetTypeId) && values.vatDefer === 'no') {
      values.amountFinanced = values.purchasePrice + (values.purchasePrice * VAT) - values.deposit;
    }

    // values.amountFinanced = isCarsAssetSelected(assetTypes, values.assetTypeId) 
    //   // car 
    //   ? values.purchasePrice - values.deposit 
    //   // non-car
    //   : values.purchasePrice + (values.purchasePrice * VAT) - values.deposit;
    //   // non-car + yes
    //   : values.purchasePrice - values.deposit;
    actions.setQuoteInformation(PAGE_KEY, values);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetTypes]);

  return (
    <>
      <h1>Asset details</h1>
      <h3>What type of asset is needed?</h3>
      {assetTypes.length && (
        <AssetDetailsForm
          onSubmit={onSubmit}
          onValuesChange={onValuesChange}
          initialValues={state.quote[PAGE_KEY]}
          assetTypes={assetTypes}
          activeStageId={state.quote.activeStageId}
          backLink={getStartedLink}
          footer={formFooter(state.quote.activeStageId, getStartedLink)}
        />
      )}
    </>
  );
};

export default AssetDetails;
