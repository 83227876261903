import React, { useContext } from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring'

import { loanChoices } from 'resources/page-structure';
import { media, colours } from 'resources/global-styles';
import { subContext } from 'resources/utils';
import { SectionTitle } from '_Broker/quote/Section.jsx';
import { QuoteContext } from '_Broker/pages/Quote';
import NotValidated from '_Broker/structure/NotValidated';

import Radio from './Radio';
import FreeText from './FreeText';
import DropDown from './DropDown';
import Checkbox from './Checkbox';



const Section = styled(animated.section)`
  margin-bottom: 40px;

  h2 {
    font-size: 22px;
    color: ${colours.text_copy};
  }

  & label {
    margin: -10px 0 10px 0;
    font-size: 14px;
    color: ${colours.sub_text_copy};
  }
`
const RadioWrapper = styled.div`
  @media ${media.tablet} {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
`
const SubSectionTitle = styled.div`
position: relative;
color: ${colours.natWestPurple};
font-size: 26px;
font-weight: bold;
padding: 30px 0;
width: 100%;
border-top: 1px solid ${colours.mediumGrey};
display: block;
cursor: pointer;
`

const Choice = ({ choice, handleChange, show, choiceData = [] }) => {
  const springProps = null; // useSpring({opacity: 1, from: {opacity: 0}, config: { duration: 2000 }})
  const context = useContext(QuoteContext);
  
  return (
    <Section style={springProps} key={`choice_${choice.id[0]}`} >
      {choice.subsection && <SubSectionTitle>{choice.subsection}</SubSectionTitle>}
      {choice.title && <h2>{choice.title}</h2>}
      {show && choice.description && <label>{choice.description}</label>}

      {
        show && 
        choice.options && 
        <RadioWrapper>
          {choice.options.map(( obj, i ) => (
            <Radio key={`radio_${i}`} choiceId={choice.id} option={obj} handleChange={handleChange} />
          ))}
        </RadioWrapper>
      }

      {show && choice.freetext && choice.freetext.map(( obj, i ) => (
        <FreeText key={`freetext_${i}`} option={obj} handleChange={handleChange} />
      ))}

      {show && choice.checkbox && choice.checkbox.map(( obj, i ) => (
        <Checkbox key={`checkbox_${i}`} option={obj} handleChange={handleChange} />
      ))}

      {
        show && 
        choice.data && 
        choiceData && 
        <RadioWrapper>
          {choiceData.map(( obj, i ) => (
            <Radio key={`radio_${i}`} choiceId={choice.id} option={obj} handleChange={handleChange} />
          ))}
        </RadioWrapper>
      }
      {typeof(context.valid) != 'undefined' && !context.valid && !!choice.required && <NotValidated>{choice.required}</NotValidated>}
    </Section>
  );
}

const ChoiceWrapper = ({ choice, handleChange }) => {
  const context = useContext(QuoteContext);
  const currChoice = loanChoices.filter( obj => obj.id === choice.id[0] );

  if (!currChoice.length) return null;

  let options = null;
  if (currChoice[0].optionCondition) {
    if (context['chosen'][currChoice[0].optionCondition.condition.key].value === currChoice[0].optionCondition.condition.value) {
      options = currChoice[0][currChoice[0].optionCondition.name];
    }
  }
  
  if (!options) {
    options = currChoice[0].options ? currChoice[0].options : currChoice[0].data ? context[currChoice[0].data] : null;
  }

  if (currChoice[0].displayIf && context[currChoice[0].displayIf.id].value !== currChoice[0].displayIf.value) return null;
  return (
    <>
      <Choice choice={currChoice[0]} handleChange={handleChange} show={!choice.after} choiceData={context[currChoice[0].data]} />
      {options && options.map(( obj, i ) => {
        if (obj.options && obj.value === context.value) {
          return obj.options.map(( obj2, i ) => (
            <QuoteContext.Provider key={`subchoice_${i}`} value={{...subContext(obj2.id, context)}}>
              <Choice choice={obj2} handleChange={handleChange} show={true} />
            </QuoteContext.Provider>
          ));
        }
        if (obj.freetext && obj.value === context.value) {
          return obj.freetext.map(( obj2, i ) => (
            <QuoteContext.Provider key={`freetext_${i}`} value={{...subContext(obj2.id, context)}}>
              <Section><FreeText option={obj2} handleChange={handleChange} /></Section>
            </QuoteContext.Provider>
          ));
        }
        if (obj.dropdown && obj.value === context.value) {
          return (
            <QuoteContext.Provider key='dropdown' value={{...subContext(obj.dropdown.id, context)}}>
              <p>{obj.label}</p>
              <DropDown choice={obj.dropdown} handleChange={handleChange} />
            </QuoteContext.Provider>
          )
        }
        return null;
      })}
    </>
  );
}

export default ChoiceWrapper;
