import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useKeyPress, useOutline } from "resources/hooks";
import { Tickbox } from "../theming/common";
import { colours } from 'resources/global-styles';

const CheckboxStyled = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  border: 2px solid ${colours.table_bottom_border};
  cursor: pointer;
  border-radius: 8px;

  &:focus {
    outline-width: ${(props) => (props.outline ? "5px" : "0")};
  }
`;

const Checkbox = ({ option, value, handleChange, layout }) => {
  const bSelected = option.value === value;
  const [hasFocus, setHasFocus] = useState(false);
  const spacePress = useKeyPress(" ");
  const bOutline = useOutline();

  useEffect(() => {
    if (spacePress && !bSelected && hasFocus) handleChange(option.value);
  }, [bSelected, hasFocus, spacePress, handleChange, option.value]);

  return (
    <CheckboxStyled
      checked={bSelected}
      outline={bOutline}
      layout={layout}
      onClick={() => handleChange(option.value)}
      tabIndex={1}
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
    >
      <Tickbox checked={bSelected} />
      <div>{option.label}</div>
    </CheckboxStyled>
  );
};

export default Checkbox;
