import { mockFetch } from 'resources/mock';
import { constsNatWest, regExp } from 'resources/constants';
import { formatter } from './constants';

export const debugConsole = (label, object) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`DEBUG: ${label}: ${JSON.stringify(object)}`);
  }
}

export const formatLabel = (text, chosen) => {
  return text ? text.replace(/%(.*)%/g, (match, p1) => chosen[p1] ) : '';
}

export const validAmount = (value) => {
  return Number(value) >= constsNatWest.minAmount && Number(value) <= constsNatWest.maxAmount;
}

export const validTerm = (value, max) => {
  return Number(value) >= 1 && Number(value) <= max;
}

export const subContext = (id, context) => {
  return Object.assign({}, context, { value: context[id]?.value, valid: context[id]?.valid })
};

export const fetchPlus = async (url, bPost, params, options) => {
  if (process.env.NODE_ENV !== 'production') {
    if (params) debugConsole('fetch params', params);
    return { json: await mockFetch(url), error: null };
  } else { 
    let json = null;
    let error = null;

    const fullOptions = bPost ? Object.assign({}, options, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params) // body data type must match "Content-Type" header
    }) : options;
    
    try {
      const response = await fetch(url, fullOptions);
      debugConsole('fetch status', response.statusText);
      debugConsole('fetch status', response.status);
      if (response.status === 401) {
        error = 'unauthorised';
      }
      else if (response.status !== 200) {
        error = `${response.statusText} (${response.status})`;
      } else {
        json = await response.json();
      }
    } catch (err) {
      debugConsole('fetch error', err);
      throw new Error(err);
    }

    return { json, error };
  }
};

export const transformSectors = (apiResponse, removeHidden = false) => {
  if (removeHidden) {
    apiResponse = apiResponse.filter(obj => !obj.hidden);
  }
  let sectorData = apiResponse.map((obj) => {
    let sectorChoice = {
      id: obj.id,
      label: obj.name,
      value: `${obj.id}`,
      ltv: obj.loanToValueRatio,
      subSectors: obj.subSectors
    };

    // for main sectors
    if (obj.subSectors && obj.subSectors.length) {
      if (obj.id === 2) {
        sectorChoice.options = [{
          id: "business.sector.leisure",
          title: "What sector of Leisure is the client in?",
          required: "Select a Leisure sector",
          options: transformSectors(obj.subSectors, removeHidden),
        }]
      } else if (obj.id === 9) {
        sectorChoice.options = [{
          // id: "business.sector.healthcare",
          id: "business.sector.leisure",
          title: "What sector of Health is the client in?",
          required: "Select a Health sector",
          options: transformSectors(obj.subSectors, removeHidden),
        }]
      } else if (obj.id === 3) {
        sectorChoice.options = [{
          id: "business.sector.leisure",
          title: "What sector of Retail and Wholesale is the client in?",
          required: "Select a Retail and Wholesale sector",
          options: transformSectors(obj.subSectors, removeHidden),
        }]
      } else if (obj.id === 4) {
        sectorChoice.options = [{
          id: "business.sector.leisure",
          title: "What sector of Professional Services is the client in?",
          required: "Select a Professional Services sector",
          options: transformSectors(obj.subSectors, removeHidden),
        }]
      } else if (obj.id === 6) {
        sectorChoice.options = [{
          id: "business.sector.leisure",
          title: "What sector of Construction & Building Materials is the client in?",
          required: "Select a Construction & Building Materials sector",
          options: transformSectors(obj.subSectors, removeHidden),
        }]
      } else if (obj.id === 7) {
        sectorChoice.options = [{
          id: "business.sector.leisure",
          title: "What sector of Other Services is the client in?",
          required: "Select an Other Services sector",
          options: transformSectors(obj.subSectors, removeHidden),
        }]
      } else if (obj.id === 8) {
        sectorChoice.options = [{
          id: "business.sector.leisure",
          title: "What sector of Agriculture is the client in?",
          required: "Select an Agriculture sector",
          options: transformSectors(obj.subSectors, removeHidden),
        }]
      } else if (obj.id === 10) {
        sectorChoice.options = [{
          id: "business.sector.leisure",
          title: "What sector of Real Estate is the client in?",
          required: "Select a Real Estate sector",
          options: transformSectors(obj.subSectors, removeHidden),
        }]
      } else if (obj.id === 1100) {
        sectorChoice.options = [{
          id: "business.sector.leisure",
          title: "What sector of Automotive is the client in?",
          required: "Select an Automotive sector",
          options: transformSectors(obj.subSectors, removeHidden),
        }]
      } else if (obj.id === 1200) {
        sectorChoice.options = [{
          id: "business.sector.leisure",
          title: "What sector of Manufacturing & Industrials is the client in?",
          required: "Select a Manufacturing & Industrials sector",
          options: transformSectors(obj.subSectors, removeHidden),
        }]
      }
    } else {
      // NOTE: calculate value for dcrThreshold and its variants if necessary
      // console.log(obj);
      // console.log(obj.name, obj.dcrThreshold, obj.dcrThresholdHigher, obj.dcrThresholdLower);
    }

    return sectorChoice
  });

  return sectorData;
}

export const findSector = (sectors, id) => {
  let sector = sectors.find((sector) => sector.value === id);
  if (sector) return sector;
  sector = sectors.reduce((flatArr, obj) => obj.options ? flatArr.concat(obj.options[0].options) : flatArr, []).find((sector) => sector.value === id );
  if (sector) return sector;
  return {ltv:0};
}

export const adminAuth = () => localStorage.getItem('admin');

export const brokerAuth = () => localStorage.getItem('broker');

export const brokerAccessOnly = () => {
  return localStorage.getItem("accessLevel") === "NatWest";
}

export const getAccessLevel = () => {
  return localStorage.getItem("accessLevel");
}

// This cookie gets set by the NatWest public site and will only be readable if we are on the same domain
export const optOutCookies = () => false;//document.cookie.includes('CCF=N;');

// TODO this isn't guaranteed to be unique - should pull SessionId from webserver
export const sessionId = () => {
  let id = sessionStorage.getItem('sessionId')
  if (id) return id;

  id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line
    return v.toString(16);
  });
  debugConsole('New SessionId created', id);
  sessionStorage.setItem('sessionId', id);
  return id;
}

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
}

/**
 * Table data for viewing product/quote details
 */
export const tableData = ( product, context ) => {
  let data = [
    { label: 'Loan amount', value: formatter.format(context['loan.amount'].value) },
    { label: 'Loan term', value: `${context['loan.term'].value} years` },
  ];

  if (!!product.fixedRateTermInYears) data.push({ label: 'Initial fixed term', value: `${product.fixedRateTermInYears} years` });

  data.push(
    { label: 'Interest rate', value: `${product.rate}%` },
    { label: 'Monthly repayments', value: formatter.format(product.monthlyCost) },
    { label: 'Arrangement fee', value: formatter.format(product.arrangementFee * context['loan.amount'].value / 100) },
    { label: 'Early repayment fee', value: formatter.format(product.earlyRepaymentFee) }
  );

  if (!product.fixedRateTermInYears) data.push({ label: 'Base rate now', value: `${product.baseRate}%` });

  data.push(
    { label: 'Affected by base rate change', value: product.fixedRateTermInYears ? 'Not during fixed term' : 'Yes' }
  );
  
  // if (!!product.fixedRateTermInYears) data.push({ label: 'Follow on rate', value: `${product.followOnRate}%` });
  if (!!product.fixedRateTermInYears) {
    const followOnRateDiff = Math.round((product.followOnRate - product.baseRate) * 100) / 100;
    data.push({ label: 'Follow on rate', value: `${followOnRateDiff}% + base rate` });
  } 

  return data;
}

export const validFormat = (stringTest, testType) => {
  let valid = false;

  switch (testType) {
    case regExp.email:
      valid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(stringTest);
      break;
    case regExp.phone:
      valid = /^(?:0|\+?44)(?:\d\s?){9,10}$/.test(stringTest);
      break;
    case regExp.postcode:
      valid = /^(GIR 0AA)|((([A-Z][0-9][0-9]?)|(([A-Z][A-HJ-Y][0-9][0-9]?)|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z]))))\s*[0-9][A-Z]{2})$/.test(stringTest);
      break;
    case regExp.name:
      valid = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(stringTest);
      break;
    case regExp.companyNumber:
      valid = /^[0-9]{7,8}$/.test(stringTest);
      break;
    default:
  }

  return valid;
}



export const isAssetCars = (assetTypes, assetId) => {
  if (assetTypes && assetId) {
    const asset = assetTypes.filter((x) => x.id === assetId)[0];
    return asset.name === 'Cars';
  }
  return false;
};

export const isPurchasePriceIncludingVat = (assetTypes, assetId, deferVat) => {
  const isCars = isAssetCars(assetTypes, assetId);

  // cars
  if (isCars) {
    return true;
  }

  // non-cars && deferVat = yes|no
  return false;
}

export const isDepositIncludingVat = (assetTypes, assetId, deferVat) => {
  const isCars = isAssetCars(assetTypes, assetId);

  // non-cars && deferVat = no
  if (!isCars && deferVat === 'no') {
    return true;
  }

  // car || (non-cars && deferVat = yes)
  return false;
}