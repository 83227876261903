import React, { Fragment } from 'react';
import styled from 'styled-components';

import { quoteStages } from 'resources/page-structure';
import { zIndex } from 'resources/constants';
import { media, colours } from 'resources/global-styles';

const Outer = styled.div`
  width: 100%;
  padding: 0 10%;
  background-color: ${colours.progress_bar};
  border-bottom: 1px solid ${colours.mediumGrey};
  font-size: 14px;
`
const Inner = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: ${media.maxWidth};
  padding: 15px 0;
	display: flex;
  justify-content: space-between;
`
const PositionBar = styled.div`
  height: 4px;
  width: 30%;
  margin: 15px -60px 0 -70px;
  border-top: 2px solid ${props => props.done ? colours.natWestPurple : '#dedede'};
  background-color: ${props => props.done ? colours.natWestPurple : '#efefef'};
  z-index: ${zIndex.normal};
`
const Div = styled.div`
  z-index: ${zIndex.progress};
`
const Circle = styled.div`
  font-family: ${props => props.done ? 'nw-icons' : ''};
  border-radius: 100%;
  background-color: ${(props) =>
    props.done ? colours.progress_bg_dark : props.active ? colours.progress_bg : "#f9f9f9"};
  color: ${(props) =>
    props.done ? colours.progress_bar : props.active ? colours.header : colours.progress_border};
  border: 2px solid
    ${(props) => (props.active || props.done ? colours.progress_border : "#D1D1D1")};
  cursor: ${props => props.done ? 'pointer' : 'inherit'};
  width: 34px;
  height: 34px;
  padding-top: 3px;
  margin: 0 auto;
  text-align: center;
`
const Title = styled.span`
  display: none;

  @media ${media.tablet} {
    display: inherit;    
  }
`

const ProgressBar = ({ stage, handleChange }) => (
  <Outer>
    <Inner>
      {quoteStages.map((obj, i) => (<Fragment key={`progress_${i}`}>
        {i > 0 && i < 4 && <PositionBar done={i<=stage} />}
        {obj.progress && <Div>
          <Circle active={i===stage} done={i<stage} onClick={() => { if (i<stage) handleChange(i) }} >{i < stage ? '\ue900' : i + 1}</Circle>
          <Title>{obj.progress}</Title>
        </Div>}
      </Fragment>))}
    </Inner>
  </Outer>
)

export default ProgressBar;
