import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import { colours, RoundedDiv, Tickbox } from 'resources/global-styles';
import { useKeyPress, useOutline } from 'resources/hooks';

import { QuoteContext } from '_Broker/pages/Quote';

const CheckboxStyled = styled(RoundedDiv)`
  display: flex;
  margin-bottom: 20px;
  padding: 20px;
  border-width: 2px;
  width: 100%;
  border-color: ${props => props.checked ? colours.natWestPurple : colours.mediumGreyBorder};
  cursor: pointer;

  &:focus {
    outline-width: ${props => props.outline ? '5px' : '0'};
  }
`

const Checkbox = ({ option, handleChange }) => {
  const context = useContext(QuoteContext);
  const bSelected = option.value === context.value;

  const [hasFocus, setHasFocus] = useState(false);
  const spacePress = useKeyPress(' ');
  const bOutline = useOutline();
  if (spacePress && !bSelected && hasFocus) handleChange({choiceId: option.id, choiceVal: bSelected ? null : option.value, isValid: bSelected });

  return (
    <CheckboxStyled checked={bSelected} outline={bOutline} layout={option.layout} onClick={() => handleChange({choiceId: option.id, choiceVal: bSelected ? null : option.value})} tabIndex={1} onFocus={() => setHasFocus(true)} onBlur={() => setHasFocus(false)} >
      <Tickbox checked={bSelected}/>
      <div>{option.label}</div>
    </CheckboxStyled>
  )
}

export default Checkbox;
