import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Page from "../components/Page";
import { Button } from "../theming/common";
import LombardRoutes from "../utils/routes";
import { trackButtonClick, trackLombardEmailQuoteSummaryRetrieval } from "../../resources/analyticsHelper";

const ButtonWithTopMargin = styled(Button)`
  margin-top: 1.5rem;
`;

const QuoteReplaced = () => {
  useEffect(() => {
    trackLombardEmailQuoteSummaryRetrieval("Quote is edited and replaced");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <Page>
      <h1>This quote was edited and replaced</h1>
      <label>Check the latest confirmation email to edit. Or start a new quote.</label>
      <Link onClick={() => trackButtonClick('Button', 'Start a new quote')} to={LombardRoutes.getStarted()}>
        <ButtonWithTopMargin>Start a new quote</ButtonWithTopMargin>
      </Link>
    </Page>
  );
};

export default QuoteReplaced;
