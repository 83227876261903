import React, { useState } from 'react';
import styled from 'styled-components';

import { useFetch } from 'resources/hooks';
import { starMessages } from 'resources/constants';
import { RoundedDiv, Button, TextArea, Tickbox, colours } from 'resources/global-styles';
import { sessionId, brokerAuth } from 'resources/utils';
import { trackApplicationRating, trackButtonClick, trackCheckboxSelection } from 'resources/analyticsHelper';

import { ReactComponent as StarEmptyIcon } from 'resources/images/star-empty.svg';
import { ReactComponent as StarFilledIcon } from 'resources/images/star-filled.svg';

const RoundedDivCentred = styled(RoundedDiv) `
  max-width: 768px;
  text-align: center;
  margin: 0 auto;
  padding: 30px;

  & h2 {
    color: ${colours.darkGrey};
    font-weight: bold;
    margin: 0 0 20px 0;
  }
`
const StarWrapper = styled.div`
  width: 150px;
  margin: 0 auto 30px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`
const StarEmpty = styled(StarEmptyIcon) `
  cursor: pointer;

  &:hover > g > g > g > path {
    fill: ${colours.mediumGrey};
  }
`
const StarFilled = styled(StarFilledIcon) `
  cursor: pointer;
`

const SaveRating = ({ rating, comment, contact, broker, referral }) => {
  useFetch('Ratings/referral', true, {
    webSessionId: sessionId(),
    brokerFirmIdentifier: brokerAuth(),
    score: rating,
    comments: comment,
    hasConsentBeenGivenToBeContacted: contact,
    firstName: broker.forename,
    lastName: broker.surname,
    companyName: broker.company,
    phoneNumber: broker.phone,
    emailAddress: broker.email,
    referralId: Number(referral),
  });

  return <h2>Thank you for sending your feedback</h2>
}

const Star = ({ selected, onClick }) => {
  if (selected) return <StarFilled onClick={onClick} />
  return <StarEmpty onClick={onClick} />
}

const StarRating = ({ referral, broker }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState();
  const [contact, setContact] = useState(false);
  const [save, setSave] = useState(false);

  if (save && rating > 0) {
    trackApplicationRating(rating);

    if (contact) {
      trackCheckboxSelection('Tick the box if we can use your details to contact you about your experience', 'contact', true);
    }
    trackButtonClick('Button', 'Submit Feedback');
    return (
      <RoundedDivCentred>
        <SaveRating rating={rating} comment={comment} contact={contact} broker={broker} referral={referral} />
      </RoundedDivCentred>
    )
  }

  if (save && rating === 0) setSave(false);

  return (
    <RoundedDivCentred>
      <h2>How would you rate your overall application experience?</h2>
      <StarWrapper>
        {[...Array(5)].map((n, i) => (
          <Star key={`star_${i}`} selected={i < rating} onClick={() => setRating(i+1)} />
        ))}
      </StarWrapper>
      {!!rating && <p>{starMessages[rating]}<br/></p>}
      <TextArea onChange={(evt) => setComment(evt.target.value)} value={comment} fullwidth='true' rows='6' maxlength='10000' />
      <div onClick={() => setContact(!contact)}><Tickbox checked={!!contact} />Tick the box if we can use your details to contact you about your experience</div>
      <Button onClick={() => setSave(true)} style={{margin: '30px 0 0 0'}}>Submit feedback</Button>
    </RoundedDivCentred>
  )
}

export default StarRating;
