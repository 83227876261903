import React, { useRef, useState, useCallback } from "react";
import { useTransition, useChain, animated, config } from "react-spring";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "resources/images/close.svg";
import { zIndex } from "resources/constants";
import { Modal } from "resources/global-styles";
import Warning from "./Warning";
import { Form, Field } from "react-final-form";
import { emailValidator, emailRequiredValidator } from "../utils/validators";
import { Input, ErrorMessage, Button } from "../theming/common";
import { media, colors } from "../theming/variables";
import { isFieldValidAndTouched, composeValidators } from "../utils/form";
import ApiFetchService from "../utils/dataFetching";
import { trackButtonClick, trackValidationError } from "resources/analyticsHelper";
import { colours } from "resources/global-styles";

const AnimatedDiv = styled(animated.div)`
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  height: 100vh;
  width: 30%;
  background-color: ${colours.page_bg};
  z-index: ${zIndex.sidebar};
  box-shadow: -1px 0 10px rgba(0, 0, 0, 0.25);
  padding: 30px;

  & h1 {
    color: ${colors.darkGrey};
    margin: 0;
  }

  @media ${media.tablet} {
    width: 600px;
  }

  @media ${media.mobile} {
    width: 100%;
  }
`;
const Close = styled(CloseIcon)`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`;
const CancelButton = styled(Button)`
  width: 45%;
  min-width: 0;
  background-color: ${colours.btn_prim_bg};
  color: ${colours.btn_prim_text};
  border: none;
  margin-bottom: 0;
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 1rem 0;
`;

const SaveQuote = ({ quoteId, handleClose }) => {
  const [saved, setSaved] = useState(false);
  const [brokerEmail, setBrokerEmail] = useState("");
  const sidebarRef = useRef();
  const transition = useTransition(true, null, {
    from: {
      transform: "translateX(100%)",
    },
    enter: {
      transform: "translateX(0)",
    },
    leave: {
      transform: "translateY(-100%)",
    },
    unique: true,
    config: config.slow,
    ref: sidebarRef,
  });

  useChain([sidebarRef], [0]);

  const onSubmit = useCallback(
    (values) => {
      ApiFetchService.SaveQuote(quoteId, values.email).then(() => {
        setBrokerEmail(values.email);
        setSaved(true);
      });
    },
    [quoteId]
  );

  
  
  const onBlurFieldHandler = (meta) => {
    // console.log(`>>> onBlurFieldHandler`, e.name, e.value, fieldLabel, e, meta);
    
    // trackFieldInput(fieldLabel, e.value);

    if (meta && meta.error) {
      const errMsg = meta.error;
      trackValidationError(errMsg, 'Your email address', 'Save quote')
    }    
  };

  return transition.map(({ item, key, props }) =>
    item ? (
      <Modal key={key}>
        <AnimatedDiv style={props} onClick={(evt) => evt.stopPropagation()}>
          <Close onClick={handleClose} />
          <h1>Save quote</h1>
          {!saved && (
            <>
              <p>
                Enter your email address and we'll send you a link so you can
                return to this quote.
              </p>
              <p>This quote expires in 28 days.</p>
              <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, form, values }) => (
                  <form autoComplete="off" onSubmit={handleSubmit}>
                    <input
                      autoComplete="off"
                      name="hidden"
                      type="text"
                      style={{ display: "none" }}
                    />
                    <Field
                      name="email"
                      type="email"
                      validate={composeValidators(
                        emailRequiredValidator,
                        emailValidator
                      )}
                    >
                      {({ input, meta }) => (
                        <div>
                          <label>Your email address</label>
                          <Input
                            {...input}
                            valid={isFieldValidAndTouched(meta)}
                            type="email"
                            onBlur={() => { input.onBlur(); onBlurFieldHandler(meta); }}
                          />
                          {meta.error && meta.touched && (
                            <ErrorMessage>{meta.error}</ErrorMessage>
                          )}
                        </div>
                      )}
                    </Field>
                    <ButtonContainer>
                      <Button type="submit" onClick={ (e) => { trackButtonClick('Button', 'Submit'); }}>Submit</Button>
                      <CancelButton onClick={handleClose}>Cancel</CancelButton>
                    </ButtonContainer>
                  </form>
                )}
              />
              <Warning>
                Broker Portal is for credit brokers and other intermediaries
                only. These quotes should not be directly distributed to the
                customer.
              </Warning>
            </>
          )}
          {saved && (
            <>
              <p>
                Thanks, we've emailed your link to{" "}
                <strong>{brokerEmail}</strong>
              </p>
              <p>Use the link to return to your indicative quote</p>
              <Button onClick={handleClose}>Close</Button>
            </>
          )}
        </AnimatedDiv>
      </Modal>
    ) : null
  );
};

export default SaveQuote;
