const isFieldValidAndTouched = (meta) => {
  return meta.touched ? meta.valid.toString() : undefined;
};

const isFieldValidAndTouchedAndNotEmpty = (meta, value) => {
  return meta.touched && value ? meta.valid.toString() : undefined;
};

// TODO: change function name to 'includingVAT()'
const isCarsAssetSelected = (assetTypes, selectedAssetId) => {
  // console.log(assetTypes, selectedAssetId, vatDefer);
  if (assetTypes && selectedAssetId) {
    var asset = assetTypes.filter((x) => x.id === selectedAssetId)[0];
    // return asset.name === "Cars";
    if (asset.name === 'Cars') {
      return true;
    }
  }
  return false;


  // if (assetTypes && selectedAssetId) {
  //   var asset = assetTypes.filter((x) => x.id === selectedAssetId)[0];
  //   // return asset.name === "Cars";
  //   if (asset.name === 'Cars') {
  //     return true;
  //   } else {
  //     return vatDefer === 'yes';
  //   }
  // }
  // return false;
};


const isCars = (assetTypes, selectedAssetId) => {
  if (assetTypes && selectedAssetId) {
    var asset = assetTypes.filter((x) => x.id === selectedAssetId)[0];
    return asset.name === 'Cars';
  }
  return false;
};

const getDepositSuffix = (total, value) => {
  if (total && value) {
    return ` (${((value / total) * 100).toFixed(2)}% total net cost)`;
  }
  return "";
};

const composeValidators =
  (...validators) =>
  (value, evt, input) =>
    validators.reduce(
      (error, validator) => error || validator(value, evt, input),
      undefined
    );

export { isFieldValidAndTouched, isFieldValidAndTouchedAndNotEmpty, composeValidators, isCarsAssetSelected, isCars, getDepositSuffix };
