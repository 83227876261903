import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { trackButtonClick } from 'resources/analyticsHelper';

import { RoundedDiv, TextLink, media, colours } from 'resources/global-styles';

import Product from './Product';
import Sidebar from './Sidebar';
import SaveQuote from './SaveQuote';

const Wrapper = styled.div`
  @media ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`
const Title = styled.h1`
  font-size: 22px;
  color: ${colours.main_text_copy};
  font-family: "RNHouseSansBold",sans-serif;
`
const SaveLink = styled(TextLink)`
  @media ${media.tablet} {
    margin-top: 55px;
  }
`


const AllProducts = ({ handleStateChange, handleStageChange, products, quote }) => {
  const [open, setOpen] = useState(-1);
  const [save, setSave] = useState(false);

  useEffect(() => { // disable page scrolling if modal open
    document.body.style.overflow = open >= 0 || save ? 'hidden' : 'unset';
  }, [open, save])

  const handleOpen = (newOpen) => {
    setOpen(newOpen === open ? -1 : newOpen);
  }
  const handleSave = () => {
    setSave(false);
  }

  if (!products.length) return <RoundedDiv>No products found</RoundedDiv>
  
  return (
    <>
      {save && <SaveQuote quote={quote} handleSave={handleSave} />}
      {open >= 0 && <Sidebar product={products.find((product) => product.id === open)} handleOpen={handleOpen} index={open} />}
      <Wrapper>
        <Title>{products.length} loan{products.length > 1 ? 's' : ''} available</Title>
        <SaveLink onClick={() => {setSave(true); trackButtonClick('Button', 'Save application');}}>Save application</SaveLink>
      </Wrapper>
      {products.map((product, index) => <Product key={`product_${index}`} product={product} handleOpen={handleOpen} handleStateChange={handleStateChange} handleStageChange={handleStageChange} quote={quote} />)}
      <div style={{height: '30px'}} />
    </>
  );
}

export default AllProducts;
