import React from "react";
import { optOutCookies } from "resources/utils";
import Header from "./Header";


const HeaderComponent = ({showNatwestLogo}) => {
  return (
    <Header showNatwestLogo={showNatwestLogo}>  
      <h1>Broker lending check</h1>
      {optOutCookies() === false && (
        <>
          <p>
            Welcome to our Broker Portal application. Use this tool to get a
            fast, indicative quote for loans or asset finance. Check our
            appetite for lending and get a quote within 5 minutes.
          </p>
        </>
      )}

      {optOutCookies() === true && (
        <>
          <h2>Cookies not accepted</h2>
          <p>
            Unfortunately, as this is a beta prototype you are unable to use the
            tool with cookies turned off. This is for us to give you the best
            experience and make continual improvements to the prototype.
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.natwest.com/global/cookieprivacy.ashx?channel=personal"
            >
              To continue using the tool please set you preferences here
            </a>
          </p>
        </>
      )}
    </Header>
  );
};

export default HeaderComponent;
