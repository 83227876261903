import React, { useContext } from 'react';

import { QuoteContext } from '_Broker/pages/Quote';
import { brokerAuth, sessionId } from 'resources/utils';
import { useFetch } from 'resources/hooks';

const ConfirmUnhappy = () => {
  const context = useContext(QuoteContext);

  const getValue = (field) => {
    if (context[field]) {
      if (field === 'unhappy.phone') return String(context[field].value);
      if (context[field].value === 'yes') return true;
      if (context[field].value === 'no') return false;
      if (!isNaN(context[field].value)) return Number(context[field].value);
      return context[field].value;
    }
    return null;
  }

  useFetch('ContactUs/speaktotheteam', true, {
    firstName: getValue('unhappy.forename'),
    lastName: getValue('unhappy.surname'),
    email: getValue('unhappy.email'),
    phoneNumber: getValue('unhappy.phone'),
    reasonForSpeakingToTheTeam: getValue('unhappy.reason'),
    webSessionId: sessionId(),
    brokerFirmIdentifier: brokerAuth(),
    loanPurpose: getValue('loan.purpose'),
    loanToBeSecuredVia: getValue('loan.secured'),
    isPropertyOwnedByTheBusiness: getValue('loan.secured.property_owned'),
    valueOfPropertyToBeOfferedAsSecurity: getValue('loan.secured.property_value'),
    purchasePriceOfPropertyToBeOfferedAsSecurity: getValue('loan.secured.property_purchase'),
    assetToBeOfferedAsSecurity: getValue('loan.secured.other_asset'),
    amount: getValue('loan.amount'),
    term: getValue('loan.term'),
    sectorId: getValue('business.sector.consolidated'),
    securityCoverage: context['loan.secured'].value === 'none' ? 0 : 100,
    lengthOfTrading:getValue('business.trading') ? getValue('business.trading') : '',
    clientLegalBusinessEntity: getValue('business.entity') === 0 ? null : getValue('business.entity'),
    isClientFinancialInformationKnown: getValue('business.financial') === 'yes',
    ebitda: getValue('business.financial.ebitda'),
    totalDirectorsRemuneration: getValue('business.financial.director_remuneration'),
    existingDebt: getValue('business.financial.existing_debt'),
    grossRent: getValue('business.financial.gross_rent') ? parseFloat(getValue('business.financial.gross_rent')) : null,

  });

  return <br/>
}

export default ConfirmUnhappy;
