import { Component } from "react";
import { debugConsole } from "resources/utils";
import {
  trackLandingPage,
  trackApplicationStart,
  trackApplicationStepName,
  trackPageTransitionLombardUnableToProceed
} from "resources/analyticsHelper";

class Tracking extends Component {

  componentDidMount() {
    const { location } = this.props;
    this.logPageChange(location.pathname, location.search);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (
      location.pathname !== prevProps.location.pathname ||
      location.search !== prevProps.location.search
    ) {
      this.logPageChange(location.pathname, location.search);
      window.scrollTo(0, 0);
    }
  }

  logPageChange = (pathname, search = "") => {
    const reHome = new RegExp("^/$");
    const reBrokerHome = new RegExp("^/[a-zA-Z0-9]{5}/?$");
    const reBrokerQuote = new RegExp("^/[a-zA-Z0-9]{5}/quote$");
    const reBrokerRetrieve = new RegExp(
      "^/[a-zA-Z0-9]{5}/retrieve-quote/({){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1}$"
    );

    const reLombardContactUs = new RegExp("^/[a-zA-Z0-9]{5}/lombard/contact$");

    switch (true) {
      case reHome.test(pathname):
        debugConsole("Track home page", "/home");
        trackLandingPage();
        break;

      case reBrokerHome.test(pathname):
        debugConsole("Track broker home page", `${pathname}/home`);
        trackLandingPage();
        break;

      case reBrokerQuote.test(pathname):
        debugConsole("Track quote start", `${pathname}/quote`);
        trackApplicationStart();
        break;

      case reBrokerRetrieve.test(pathname):
        debugConsole("Track retrieve-quote", pathname);
        trackApplicationStepName(`${pathname}`);
        break;

      case reLombardContactUs.test(pathname):
        debugConsole("Track unable to provide online quote", pathname);
        trackPageTransitionLombardUnableToProceed();
        break;

      default:
        if(pathname.indexOf("/lombard") === -1 && pathname.indexOf("getstarted") === -1){
          debugConsole("Track other url", `${pathname}${search}`);
          trackApplicationStepName(`${pathname}${search}`);
        }
    }
  };

  render() {
    return null;
  }
}

export default Tracking;
