import React from "react";
import { Field } from "react-final-form";
import {
  ErrorMessage,
  RadioWrapper,
} from "../../../theming/common";
import {
  requiredValidator,
} from "../../../utils/validators";
import RadioButton from "../../../components/RadioButton";
import {
  partnersNumber,
  BUSINESS_ENTITY_KEYS,
} from "../../../utils/constants";

const PartnershipEntityFields = ({ values, form }) => {
  return (
    <>
      {values.businessEntity &&
        values.businessEntity === BUSINESS_ENTITY_KEYS.PARTNERSHIP && (
          <>
            <h3>How many Partners are there?</h3>
            <RadioWrapper>
              {partnersNumber.map((x) => {
                return (
                  <Field
                    name="numberOfPartners"
                    type="radio"
                    value={x.value}
                    key={`partnersNumber_${x.value}`}
                    validate={requiredValidator}
                  >
                    {({ input }) => {
                      return (
                        <RadioButton
                          key={`radio_partnersNumber_${x.value}`}
                          option={x}
                          bSelected={input.checked}
                          handleChange={input.onChange}
                          layout="triple"
                        />
                      );
                    }}
                  </Field>
                );
              })}
            </RadioWrapper>
            {form.getState().touched.businessEntity &&
              !values.numberOfPartners && (
                <ErrorMessage>
                  Please select the number of partners
                </ErrorMessage>
              )}
          </>
        )}
    </>
  );
};

export default PartnershipEntityFields;
