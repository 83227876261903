import React, { createContext, useContext } from "react";
import ApiFetchService from "_Lombard/utils/dataFetching";
import { netAssetsNumber } from "../utils/constants";
import deepmerge from "deepmerge";

const cleanState = {
  supportingDetails: {},
  quoteSummary: {},
  quote: {
    activeStageId: 0,
  },
  initialQuoteCreationStarted: true,
  isFromReferenceNumber: false,
  prevReferenceNumber: null,
  prevQuoteId: null,
};

const initialContextValue = {
  assetTypes: [],
  supportingDetails: {},
  quoteSummary: {},
  quote: {
    activeStageId: 0,
  },
  initialQuoteCreationStarted: false,
  isFromReferenceNumber: false,
  prevReferenceNumber: null,
  prevQuoteId: null,
};

const LombardContext = createContext();

const getFormattedDate = (stringDate) => {
  const date = new Date(stringDate);
  const month =
    date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  return `${month}/${date.getFullYear()}`;
};

function lombardReducer(state, action) {
  let newState = Object.assign({}, state);

  const { payload } = action;

  switch (action.type) {
    case "addOrUpdatePageData": {
      if (typeof newState[payload.key] === "object") {
        newState[payload.key] = deepmerge({}, payload.value);
      } else {
        newState[payload.key] = payload.value;
      }
      return newState;
    }
    case "assetTypesLoaded": {
      newState.assetTypes = payload;
      return newState;
    }
    case "setStage": {
      newState.quote.activeStageId = payload;
      return newState;
    }
    case "setQuoteInformation": {
      newState.quote[payload.key] = payload.value;
      return newState;
    }
    case "fillQuoteFromRetrievedInformation": {
      newState.quote = {
        activeStageId: 2,
        assetDetails: {
          assetTypeId: payload.assetTypeId,
          deposit: payload.deposit,
          numberOfAssets: payload.numberOfAssets,
          commission: payload.commission,
          finalLumpSum: payload.finalLumpSum,
          lumpsumset: payload.finalLumpSum > 0 ? "yes" : "no",
          purchasePrice: payload.purchasePrice,
          termInMonths: payload.termInMonths,
          amountFinanced: payload.purchasePrice - payload.deposit,
          vatDefer: payload.vatDefer,
          vatDeferDetails: payload.vatDeferDetails,
        },
        businessDetails: {
          annualIncome: payload.annualIncome,
          businessEntity: payload.businessEntity,
          lastDirectorAppointed: payload.lastDirectorAppointed
            ? getFormattedDate(payload.lastDirectorAppointed)
            : null,
          lastPartnerAppointed: payload.lastPartnerAppointed
            ? getFormattedDate(payload.lastPartnerAppointed)
            : null,
          startedTradingDate: payload.startedTradingDate
            ? getFormattedDate(payload.startedTradingDate)
            : null,
          numberOfPartners: payload?.numberOfPartners,
          numberOfCurrentDirectors: payload?.numberOfCurrentDirectors,
          netAssets: payload?.netAssetsMoreThan3Millions,
        },
      };
      newState.quoteSummary = {
        retrieved: true,
        quoteId: payload.quoteId,
        monthlyPayment: payload.monthlyPayment,
        monthlyPaymentNet: payload.monthlyPaymentNet,
        fixedInterestRatePerAnnum: payload.fixedInterestRatePerAnnum,
        fixedInterestRatePerAnnumNet: payload.fixedInterestRatePerAnnumNet,
        totalAmountPayable: payload.totalAmountPayable,
        termInMonths: payload.termInMonths,
        arrangementFee: payload.arrangementFee,
        optionToPurchaseFee: payload.optionToPurchaseFee,
        cashPrice: payload.cashPrice,
        deposit: payload.deposit,
        finalLumpSum: payload.finalLumpSum,
        totalBorrowing: payload.totalBorrowing,
        fixedInterestAmount: payload.fixedInterestAmount,
        commissionInPercents: payload.commissionInPercents,
        commission: payload.commission,
        isCompleted: payload.isCompleted,
        vatTotalCost: payload.vatTotalCost,
        vatTotalCostNet: payload.vatTotalCostNet,
      };

      return newState;
    }
    case "setSupportingDetails": {
      newState.supportingDetails[payload.key] = payload.value
      
      return newState;
    }
    case "clearContextState": {
      newState = deepmerge(
        { assetTypes: [...newState.assetTypes] },
        cleanState
      );
      return newState;
    }
    case "markQuoteCreationStarted": {
      newState.initialQuoteCreationStarted = true;
      return newState;
    }
    case "setIsFromReferenceNumber": {
      newState.isFromReferenceNumber = payload;
      return newState;
    }
    case "setPrevReferenceNumber": {
      newState.prevReferenceNumber = payload;
      return newState;
    }
    case "setPrevQuoteId": {
      newState.prevQuoteId = payload;
      return newState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LombardContextProvider({ children }) {
  const [state, dispatch] = React.useReducer(
    lombardReducer,
    initialContextValue
  );
  const actions = {
    addOrUpdatePageData: (key, value) => {
      if (key && value) {
        dispatch({
          type: "addOrUpdatePageData",
          payload: {
            key,
            value,
          },
        });
      }
    },
    fetchAssetTypes: () => {
      ApiFetchService.FetchAssetTypes().then((data) => {
        dispatch({
          type: "assetTypesLoaded",
          payload: data,
        });
      });
    },
    setStage: (stageId) => {
      dispatch({
        type: "setStage",
        payload: stageId,
      });
    },
    setQuoteInformation: (key, value) => {
      if (key && value) {
        dispatch({
          type: "setQuoteInformation",
          payload: {
            key,
            value,
          },
        });
      }
    },
    fillQuoteFromRetrievedInformation: (retrievedData) => {
      dispatch({
        type: "fillQuoteFromRetrievedInformation",
        payload: retrievedData,
      });
    },
    setSupportingDetails: (key, value) => {
      dispatch({
        type: "setSupportingDetails",
        payload: {
          key,
          value,
        },
      });
    },
    clearContextState: () => {
      dispatch({
        type: "clearContextState",
      });
    },
    markQuoteCreationStarted: () => {
      dispatch({
        type: "markQuoteCreationStarted",
      });
    },
    setIsFromReferenceNumber: (data) => {
      dispatch({
        type: "setIsFromReferenceNumber",
        payload: data,
      });
    },
    setPrevReferenceNumber: (data) => {
      dispatch({
        type: "setPrevReferenceNumber",
        payload: data,
      });
    },
    setPrevQuoteId: (data) => {
      dispatch({
        type: "setPrevQuoteId",
        payload: data,
      });
    },
  };
  const value = { state, actions };
  return (
    <LombardContext.Provider value={value}>{children}</LombardContext.Provider>
  );
}

function useLombardContext() {
  const context = useContext(LombardContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a LombardContext");
  }
  return context;
}

export { LombardContextProvider, useLombardContext };
