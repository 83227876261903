import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { useFetch } from "resources/hooks";
import GlobalStyles from "resources/global-styles";
import { optOutCookies, debugConsole } from "resources/utils";

import Tracking from "_Broker/structure/Tracking";
import ErrorBoundary from "_Broker/structure/ErrorBoundary";
import Home from "./pages/Welcome";
import BrokerHome from "./_Broker/pages/Home";
import LombardHome from "./pages/LombardOnly";
import Admin from "_Broker/pages/Admin";
import ChangePassword from "_Broker/pages/ChangePassword";
import Quote from "_Broker/pages/Quote";
import Retrieve from "_Broker/pages/Retrieve";
import ErrorPage from "_Broker/pages/ErrorPage";
import GetStartedPage from "./pages/GetStarted";
import GetStartedLombardOnlyPage from "./pages/GetStartedLombardOnly";
import UnableToProcessPage from "./_Lombard/pages/UnableToProceed";
import ThankYou from "./_Lombard/pages/ThankYou";
import LombardQuote from "./_Lombard/pages/Quote";
import LombardQuoteExpired from "./_Lombard/pages/QuoteExpired";
import LombardQuoteReplaced from "./_Lombard/pages/QuoteReplaced";
import { AppContextProvider } from "./context/appContext";
import { LombardContextProvider } from "./_Lombard/context/lombardContext";

const RetrieveRoute = ({ match }) => {
  const { loading, error, data } = useFetch(
    `Quotes/${match.params.retrieveId}`,
    true,
    {
      brokerFirmIdentifier: match.params.brokerId,
    },
    null,
    [match.params.brokerId, match.params.retrieveId]
  );

  if (error) {
    debugConsole("Retrieval code not recognised", match.params.retrieveId);
    localStorage.removeItem("retrieve");
  } else if (data) {
    debugConsole("Retrieval code recognised", match.params.retrieveId);
    localStorage.setItem("retrieve", JSON.stringify(data));
    sessionStorage.setItem("sessionId", data.webSessionId);
  }

  if (loading) return null;

  if (data)
    return (
      <Switch>
        <Route
          exact
          path="/:brokerId/retrieve-quote/:retrieveId"
          component={AuthRoute}
        />
        <Route render={(props) => <ErrorPage {...props} text="404" />} />
      </Switch>
    );
  return <Route component={Home} />;
};

const getNatWestOnlyRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={BrokerHome} />
      <Route exact path="/:brokerId/quote" component={Quote} />
      <Route exact path="/:brokerId" component={BrokerHome} />
      <Route
        exact
        path="/:brokerId/retrieve-quote/:retrieveId"
        component={Retrieve}
      />
      <Route render={(props) => <ErrorPage {...props} text="404" />} />
    </Switch>
  );
};

const getLombardOnlyRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={LombardHome} />
      <Route exact path="/:brokerId" component={LombardHome} />
      <Route
        exact
        path="/:brokerId/getstarted"
        component={GetStartedLombardOnlyPage}
      />
      <Route
        exact
        path="/:brokerId/lombard/contact"
        component={UnableToProcessPage}
      />
      <Route exact path="/:brokerId/lombard/thankyou" component={ThankYou} />
      <Route
        exact
        path="/:brokerId/lombard/quote/expired"
        render={() => <LombardQuoteExpired quoteExpired={true} />}
      />
      <Route
        exact
        path="/:brokerId/lombard/quote/replaced"
        render={() => <LombardQuoteReplaced/>}
      />
      <Route
        exact
        path="/:brokerId/lombard/quote/applied"
        render={() => <LombardQuoteExpired quoteExpired={false} />}
      />
      <Route
        path="/:brokerId/lombard/quote/:retrievalId?"
        component={LombardQuote}
      />
      <Route render={(props) => <ErrorPage {...props} text="404" />} />
    </Switch>
  );
};

const getAllRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route
        exact
        path="/:brokerId/retrieve-quote/:retrieveId"
        component={Retrieve}
      />
      <Route exact path="/:brokerId/quote" component={Quote} />
      <Route exact path="/:brokerId" component={Home} />
      <Route exact path="/:brokerId/getstarted" component={GetStartedPage} />
      <Route
        exact
        path="/:brokerId/lombard/contact"
        component={UnableToProcessPage}
      />
      <Route exact path="/:brokerId/lombard/thankyou" component={ThankYou} />
      <Route
        exact
        path="/:brokerId/lombard/quote/expired"
        render={() => <LombardQuoteExpired quoteExpired={true} />}
      />
      <Route
        exact
        path="/:brokerId/lombard/quote/replaced"
        render={() => <LombardQuoteReplaced />}
      />
      <Route
        exact
        path="/:brokerId/lombard/quote/applied"
        render={() => <LombardQuoteExpired quoteExpired={false} />}
      />
      <Route
        path="/:brokerId/lombard/quote/:retrievalId?"
        component={LombardQuote}
      />
      <Route render={(props) => <ErrorPage {...props} text="404" />} />
    </Switch>
  );
};

const AuthRoute = ({ match }) => {
  let brokerId = match.params.brokerId
    ? match.params.brokerId
    : localStorage.getItem("broker");

  const authFetch = useFetch(
    `Authentication/validate/${brokerId}`,
    true,
    null,
    null,
    [brokerId]
  );
  if (authFetch.error || optOutCookies()) {
    debugConsole(
      "Broker code not authenticated (or broker has opted out of cookies)",
      brokerId
    );

    localStorage.removeItem("broker");
    return <Route component={Home} />;
  }

  if (authFetch.data) {
    debugConsole("Broker code authenticated", brokerId);
    localStorage.setItem("broker", brokerId);
    localStorage.setItem("accessLevel", authFetch.data);
    let routes;
    // debugger;
    switch (authFetch.data) {
      case "All":
        routes = getAllRoutes();
        break;
      case "NatWest":
        routes = getNatWestOnlyRoutes();
        break;
      case "Lombard":
        routes = getLombardOnlyRoutes();
        break;
      default:
        return null;
    }

    return (
      <>
        {!optOutCookies() && <Route component={Tracking} />}
        {routes}
      </>
    );
  }

  return null;
};

const App = () => (
  <AppContextProvider>
    <LombardContextProvider>
      <Router>
        <GlobalStyles />

        <ErrorBoundary>
          <Switch>
            <Route exact path="/" component={AuthRoute} />
            <Route exact path="/admin" component={Admin} />
            <Route path="/reset-password/:token+" component={ChangePassword} />
            <Route
              exact
              path="/:brokerId/retrieve-quote/:retrieveId"
              component={RetrieveRoute}
            />
            <Route exact path="/:brokerId/quote" component={AuthRoute} />
            <Route exact path="/:brokerId" component={AuthRoute} />
            <Route exact path="/:brokerId/getstarted" component={AuthRoute} />
            <Route
              exact
              path="/:brokerId/lombard/contact"
              component={AuthRoute}
            />
            <Route
              exact
              path="/:brokerId/lombard/thankyou"
              component={AuthRoute}
            />
            <Route
              exact
              path="/:brokerId/lombard/quote/expired"
              component={AuthRoute}
            />
            <Route
              exact
              path="/:brokerId/lombard/quote/replaced"
              component={AuthRoute}
            />
            <Route
              exact
              path="/:brokerId/lombard/quote/applied"
              component={AuthRoute}
            />
            <Route
              path="/:brokerId/lombard/quote/:retrievalId?"
              component={AuthRoute}
            />
            <Route render={(props) => <ErrorPage {...props} text="404" />} />
          </Switch>
        </ErrorBoundary>
      </Router>
    </LombardContextProvider>
  </AppContextProvider>
);

export default App;
