import { useState, useEffect } from "react";

import { mockFetch } from './mock';
import { debugConsole } from "./utils";

export const useFetch = (url, bPost, params, headers, arrDepends = []) => {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: null,
  });
  const fullURL = `${process.env.REACT_APP_API_URL}/api/${url}`;
  useEffect(() => {
    const fetchData = async () => {
      if (process.env.NODE_ENV !== 'production') {
        if (params) debugConsole('fetch params', params);
        const json = await mockFetch(fullURL);
        setState({ loading: false, error: false, data: json });
      } else { 
        const fullOptions = bPost ? {
          method: 'POST',
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache',
          credentials: 'same-origin', // include, *same-origin, omit
          redirect: 'follow', // manual, *follow, error
          referrer: 'no-referrer', // no-referrer, *client
          headers: Object.assign({}, headers, { 'Content-Type': 'application/json' }),
          body: JSON.stringify(params) // body data type must match "Content-Type" header
        } : { headers: headers };

        try {
          const response = await fetch(fullURL, fullOptions);
          
          if (response.status === 401) {
            if (!response.url.includes("token")) {
              setState({ loading: false, error: "Unauthorised", data: null });
            } else {
              const json = await response.json();
              setState({ loading: false, error: json.title, data: null });
            }
          } else if (response.status === 400) {
            const json = await response.json();
            // setState(() => { throw new Error(json.title) })
            setState({ loading: false, error: json, data: null });
          } else if (response.status === 500) {
            setState(() => {
              throw new Error(`Server responded with 500: ${fullURL}`);
            });
          } else if (response.status === 502 || response.status === 503) {
            setState(() => {
              throw new Error("Service Unavailable");
            });
          } else if (response.status !== 200) {
            const json = await response.json();
            setState({ loading: false, error: json.title, data: null });
          } else {
            const json = await response.json();
            setState({ loading: false, error: false, data: json });
          }
        } catch (err) {
          setState(() => { throw new Error(err) })
        }
      }
    }
    
    fetchData();
  }, arrDepends);

  return state;
}

let cachedScripts = [];

export const useScript = (src) => {
  const [state, setState] = useState({
    loaded: false,
    error: false
  });

  useEffect(() => {
    if (cachedScripts.includes(src)) {
      setState({
        loaded: true,
        error: false
      });
    } else {
      cachedScripts.push(src);

      let script = document.createElement('script');
      script.src = src;
      script.async = true;

      const onScriptLoad = () => {
        setState({
          loaded: true,
          error: false
        });
      };

      const onScriptError = () => {
        const index = cachedScripts.indexOf(src);
        if (index >= 0) cachedScripts.splice(index, 1);
        script.remove();

        setState({
          loaded: true,
          error: true
        });
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      document.body.appendChild(script);

      return () => {
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
      };
    }
  }, [src] );

  return [state.loaded, state.error];
}

export const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler(evt) {
    if (evt.key === targetKey) {
      if (evt.target.tagName === 'DIV') evt.preventDefault();
      setKeyPressed(true);
    }
  }
  function upHandler(evt) {
    if (evt.key === targetKey) {
      if (evt.target.tagName === 'DIV') evt.preventDefault();
      setKeyPressed(false);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  });

  return keyPressed;
}

export const useOutline = () => {
  const [outline, setOutline] = useState(false);

  const handleKeydown = (e) => {
    if (e.keyCode === 9) setOutline(true); // tab event
  }

  const handleClick = () => setOutline(false);
  
  useEffect(() => {
    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('click', handleClick);
    }
  });

  return outline;
};