import React from 'react';
import styled from 'styled-components';

import { errorMessages } from 'resources/constants';
import Page from '_Broker/structure/Page';
import Header from '_Broker/structure/Header';

const ErrorPage = styled(Page)`
  background-image: url('/images/error-graphic.svg');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 50%;
  min-height: 500px;
  border: 1px solid transparent;

  & h1 {
    width: 50%;
    margin-top: 60px;
  }
  & p {
    width: 50%;
  }
  & a:hover {
    text-decoration: underline;
  }
`

const Error = ({text}) => (
  <>
    <Header />
    <ErrorPage>
      <h1>{errorMessages[text].title}</h1>
      <p>{errorMessages[text].description}</p>
      <p><a rel="noopener noreferrer" href="/">Broker portal homepage</a></p>
      <p><a target="_blank" rel="noopener noreferrer" href="https://www.natwest.com/">NatWest homepage</a></p>
    </ErrorPage>
  </>
);

export default Error;
