import { useFetch } from 'resources/hooks';
import { brokerAuth } from 'resources/utils';

const SaveAndEmail = ({ quote, brokerEmail }) => {
  const { loading, error, data } = useFetch(`Quotes/${quote}/saveforlater`, true, {
    brokerFirmIdentifier: brokerAuth(),
    brokerEmail: brokerEmail,
  });

  return null;
};

export default SaveAndEmail;