import React from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

import { formatter, numberTwoDecimalsFormatter } from "resources/constants";
import { ReactComponent as TooltipIcon } from "resources/images/info_tooltip_purple.svg";
import { isCarsAssetSelected } from "_Lombard/utils/form";
import { media, colors } from "../../../theming/variables";
import { TextLink } from "../../../theming/common";
import Loader from "../../../components/Loader";
import { useLombardContext } from "../../../context/lombardContext";
import { isPurchasePriceIncludingVat, isAssetCars, isDepositIncludingVat } from "resources/utils";
import { VAT  } from "_Lombard/utils/constants";
import { colours } from "resources/global-styles.js";


const Tooltip = styled(TooltipIcon)`
  margin-left: 10px;
  vertical-align: middle;
  height: 24px;
`;

const Frame = styled.div`
  border: 2px solid ${colors.mediumGreyBorder};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const FrameHeader = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 2px solid ${colors.mediumGreyBorder};
  padding: 1.5rem;
  font-weight: bold;

  div:last-child {
    @media ${media.mobile} {
      margin-left: 0;
    }
  }

  @media ${media.mobile} {
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    margin-left: 0rem;
  }
`;

const FrameHeaderText = styled.div`
  flex-grow: 1;
`;

const PaymentsRow = styled.div`
  width: 100%;
  display: flex;
  background-color: ${colors.lightGrey};
  border-bottom: 2px solid ${colors.mediumGreyBorder};

  @media ${media.mobile} {
    flex-wrap: wrap;
  }
`;

const PaymentsRowItem = styled.div`
  padding: 1.5rem;

  &:first-child {
    color: white;
    background-color: ${colours.btn_dark};
  }

  @media ${media.mobile} {
    width: 100%;
  }
`;

const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 1.5rem;
`;

const DetailsItem = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const DetailsHeader = styled.div`
  font-weight: bold;
  width: 100%;
`;

const DetailsSubHeader = styled.div`
  font-weight: bold;
  width: 100%;
  margin-top: 1rem;
`;
const DetailsText = styled.div`
  width: 50%;
`;

const DetailsInfo = styled.div`
  font-weight: bold;
  width: 50%;
`;

const DetailsColumn = styled.div`
  width: 50%;
  padding-top: 1.5rem;

  @media ${media.mobile} {
    width: 100%;
  }
`;

const DownloadIcon = styled.div`
  background-image: url("/images/download_icon.png");
  width: 22px;
  height: 22px;
  background-size: contain;
  float: left;
  margin-right: 0.5rem;
`;

const TextLinkWithMobileStyles = styled(TextLink)`
  @media ${media.mobile} {
    width: 100%;
    padding: 1rem 0;
  }
`;

const QuoteInformationTable = ({
  data,
  showFinalLumpSum,
  isLoading,
  handleSaveQuoteClick,
  hideSaveQuote
}) => {
  const { state } = useLombardContext();
  const { assetTypes } = state;

  return (
    <Frame>
      {data && !isLoading && (
        <>
          <FrameHeader>
            <FrameHeaderText>Fixed rate hire purchase</FrameHeaderText>
            {!hideSaveQuote && <TextLinkWithMobileStyles onClick={handleSaveQuoteClick}>
              Save quote
            </TextLinkWithMobileStyles>}
          </FrameHeader>
          <PaymentsRow>
            <PaymentsRowItem>
              <div>Monthly payment</div>
              <div>
                <strong>
                  {state.quote.assetDetails.vatDefer === 'yes' ? <>
                    2 x {formatter.format(data.monthlyPayment)}<br />
                    1 x {formatter.format(data.monthlyPayment + data.vatTotalCost)}<br />
                    {data.termInMonths - 3} x {formatter.format(data.monthlyPayment)}
                  </> : <>
                    {formatter.format(data.monthlyPayment)}
                  </>}
                  {/* {formatter.format(data.monthlyPayment)} */}
                  {/* 2 x {formatter.format(data.monthlyPayment)}<br />
                  1 x {formatter.format(data.monthlyPayment + data.cashPrice * 0.2)}<br />
                  {data.termInMonths - 3} x {formatter.format(data.monthlyPayment)} */}
                </strong>
              </div>
            </PaymentsRowItem>
            <PaymentsRowItem>
              <div>Fixed interest rate</div>
              <div>
                <strong>{data.fixedInterestRatePerAnnum}%</strong>
              </div>
            </PaymentsRowItem>
            <PaymentsRowItem>
              <div>Total amount payable</div>
              <div>
                <strong>{formatter.format(data.totalAmountPayable)}</strong>
              </div>
            </PaymentsRowItem>
          </PaymentsRow>
          <Details>
            <DetailsHeader>Full quote details</DetailsHeader>
            <DetailsColumn>
              <DetailsSubHeader>With commission (Gross Yield):</DetailsSubHeader>
              <DetailsItem>
                <DetailsText>Monthly payment</DetailsText>
                <DetailsInfo>
                  {/* {JSON.stringify(data)} */}
                  {state.quote.assetDetails.vatDefer === 'yes' ? <>
                    2 x {formatter.format(data.monthlyPayment)}<br />
                    1 x {formatter.format(data.monthlyPayment + data.vatTotalCost)}<br /> 
                    <small>(incl. deferred VAT)</small><br />
                    {data.termInMonths - 3} x {formatter.format(data.monthlyPayment)}
                  </> : <>
                    {formatter.format(data.monthlyPayment)}
                  </>}
                  {/* 2 x {formatter.format(data.monthlyPayment)}<br />
                  1 x {formatter.format(data.monthlyPayment + data.cashPrice * 0.2)}<br />
                  {data.termInMonths - 3} x {formatter.format(data.monthlyPayment)} */}
                </DetailsInfo>
              </DetailsItem>
              <DetailsItem>
                <DetailsText>
                  Fixed interest rate
                  <Tooltip
                    data-tip={
                      "This is a nominal interest rate. It is applied on a yearly pro-rated basis at the frequency of your payments (e.g.monthly or quarterly)"
                    }
                  />
                  <ReactTooltip
                    place="top"
                    effect="solid"
                    multiline={true}
                    className="tooltip"
                    delayHide={500}
                  />
                </DetailsText>
                <DetailsInfo>{numberTwoDecimalsFormatter.format(data.fixedInterestRatePerAnnum)}%</DetailsInfo>
              </DetailsItem>
              <DetailsSubHeader>Without commission (Net Yield):</DetailsSubHeader>
              <DetailsItem>
                <DetailsText>Monthly payment</DetailsText>
                {/* <DetailsInfo>
                  {data.monthlyPaymentNet !== null ? (formatter.format(data.monthlyPaymentNet)) : 'N/A'}
                </DetailsInfo> */}

                {data.monthlyPaymentNet && 
                <DetailsInfo>
                  {state.quote.assetDetails.vatDefer === 'yes' ? <>
                    2 x {formatter.format(data.monthlyPaymentNet)}<br />
                    1 x {formatter.format(data.monthlyPaymentNet + data.vatTotalCostNet)}<br />
                    <small>(incl. deferred VAT)</small><br />
                    {data.termInMonths - 3} x {formatter.format(data.monthlyPaymentNet)}
                  </> : <>
                    {formatter.format(data.monthlyPaymentNet)}
                  </>}
                  {/* 2 x {formatter.format(data.monthlyPaymentNet)}<br />
                  1 x {formatter.format(data.monthlyPaymentNet + data.cashPrice * 0.2)}<br />
                  {data.termInMonths - 3} x {formatter.format(data.monthlyPaymentNet)} */}
                </DetailsInfo>}

                {!data.monthlyPaymentNet && <DetailsInfo>
                  N/A
                </DetailsInfo>}

              </DetailsItem>
              <DetailsItem>
                <DetailsText>
                  Fixed interest rate
                  <Tooltip
                    data-tip={
                      "This is a nominal interest rate. It is applied on a yearly pro-rated basis at the frequency of your payments (e.g.monthly or quarterly)"
                    }
                  />
                  <ReactTooltip
                    place="top"
                    effect="solid"
                    multiline={true}
                    className="tooltip"
                    delayHide={500}
                  />
                </DetailsText>
                <DetailsInfo>{data.fixedInterestRatePerAnnumNet !== null ? numberTwoDecimalsFormatter.format(data.fixedInterestRatePerAnnumNet) + '%' : 'N/A'}</DetailsInfo>
              </DetailsItem>
              <DetailsItem>
                <DetailsText>Term length</DetailsText>
                <DetailsInfo>{data.termInMonths} months</DetailsInfo>
              </DetailsItem>
              <DetailsItem>
                <DetailsText>Arrangement fee</DetailsText>
                <DetailsInfo>
                  {formatter.format(data.arrangementFee)}
                </DetailsInfo>
              </DetailsItem>
              <DetailsItem>
                <DetailsText>Option to purchase fee</DetailsText>
                <DetailsInfo>
                  {formatter.format(data.optionToPurchaseFee)}
                </DetailsInfo>
              </DetailsItem>
            </DetailsColumn>
            <DetailsColumn>
              <DetailsItem>
                <DetailsText>
                    Total{" "}
                      {isCarsAssetSelected(assetTypes,  state.quote.assetDetails.assetTypeId)
                        ? "gross"
                        : "net"}{" "}
                      cost (
                      {isCarsAssetSelected(assetTypes,  state.quote.assetDetails.assetTypeId)
                        ? "incl."
                        : "ex."}{" "}
                      VAT)
                </DetailsText>
                <DetailsInfo>{formatter.format(data.cashPrice)}</DetailsInfo>
              </DetailsItem>
              <DetailsItem>
                <DetailsText>
                  {isCarsAssetSelected(assetTypes,  state.quote.assetDetails.assetTypeId)
                    ? `Deposit`
                    : state.quote.assetDetails.vatDefer === 'no'
                      ? `Deposit (incl. VAT)`
                      : `Deposit (ex. VAT)`
                  }
                  {/* Deposit{" "}
                  {isCarsAssetSelected(assetTypes, state.quote.assetDetails.assetTypeId)
                    ? ""
                    : "(incl.VAT)"} */}
                </DetailsText>
                <DetailsInfo>
                  {formatter.format(data.deposit)}
                  {/* {formatter.format(
                    (isDepositIncludingVat(assetTypes, state.quote.assetDetails.assetTypeId, state.quote.assetDetails.vatDefer) 
                      ? data.deposit - state.quote.assetDetails.purchasePrice * VAT
                      : data.deposit
                    )
                  )} */}
                </DetailsInfo>
              </DetailsItem>
              <DetailsItem>
                <DetailsText>Fixed interest amount</DetailsText>
                <DetailsInfo>
                  {formatter.format(data.fixedInterestAmount)}
                </DetailsInfo>
              </DetailsItem>

              {showFinalLumpSum && (
                <>
                  <DetailsItem>
                  <DetailsText>Final lump sum</DetailsText>
                  <DetailsInfo>
                    {formatter.format(data.finalLumpSum)}
                  </DetailsInfo>
                  </DetailsItem>
                  <DetailsSubHeader>(due 1 month after the specified term length)</DetailsSubHeader>
                </>
              )}

              <DetailsItem>
                <DetailsText>Total borrowing</DetailsText>
                <DetailsInfo>
                  {formatter.format(data.totalBorrowing)}
                </DetailsInfo>
              </DetailsItem>

              <DetailsItem>
                <DetailsText>Total amount payable</DetailsText>
                <DetailsInfo>
                  {formatter.format(data.totalAmountPayable)}
                </DetailsInfo>
              </DetailsItem>
            </DetailsColumn>
          </Details>
        </>
      )}
      {isLoading && <Loader />}
    </Frame>
  );
};

export default QuoteInformationTable;
