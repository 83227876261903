import React, { useCallback } from "react";
import { Form, Field, FormSpy } from "react-final-form";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  ErrorMessage,
  RadioWrapper,
  FieldInputWrapper,
  H3,
} from "../../../theming/common";
import {
  requiredValidator,
  shortDateFieldValidator,
} from "../../../utils/validators";
import { isFieldValidAndTouched } from "../../../utils/form";
import RadioButton from "../../../components/RadioButton";
import PrevNextButtons from "../../../components/PrevNextButtons";
import { useLombardContext } from "../../../context/lombardContext";
import {
  businessEntities,
  annualTurnoverRates,
  quoteStages,
  BUSINESS_ENTITY_KEYS,
  netAssetsNumber,
} from "../../../utils/constants";
import DateInput from "../../../components/DateInput";
import PartnershipEntityFields from "./PartnershipEntityFields";
import LimitedLiabilityPartnerShipFields from "./LimitedLiabilityPartnerShipFields";
import LimitedCompanyFields from "./LimitedCompanyFields";
import LombardRoutes from "../../../utils/routes";
import {
  trackLombardApplicationStepName, trackRadioButtonSelection, trackValidationError
} from "../../../../resources/analyticsHelper";
import { colours } from "../../../../resources/global-styles.js";

const PAGE_KEY = "businessDetails";

const ErrorLink = styled(Link)`
  color: ${colours.warning_link};
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

const BusinessDetails = () => {
  const { state, actions } = useLombardContext();
  const amountFinancedIsTooLow =
    state.quote.assetDetails.amountFinanced < 25000;
    
  const onSubmit = useCallback(
    (values) => {
      if (
        values.annualIncome > 25000000 ||
        (amountFinancedIsTooLow &&
          (values.businessEntity === BUSINESS_ENTITY_KEYS.PARTNERSHIP ||
            values.businessEntity === BUSINESS_ENTITY_KEYS.SOLE_TRADER))
      ) {
        return;
      } else {
        actions.setQuoteInformation(PAGE_KEY, values);
        actions.setStage(state.quote.activeStageId + 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [amountFinancedIsTooLow, state.quote.activeStageId]
  );

  const onValuesChange = (values) => {
    // console.log('>>> onValuesChange', values);

    actions.setQuoteInformation(PAGE_KEY, values);
  };

  const onDateInputChange = (value, meta) => {
    // console.log('>>> onDateInputChange', value, '---', meta.valid, '---', meta.error);
  }

  const handleBackClick = useCallback(() => {
    actions.setStage(state.quote.activeStageId - 1);

    trackLombardApplicationStepName("Asset Details");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.quote.activeStageId]);

  const getNextStageName = useCallback(() => {
    const nextStageId = state.quote.activeStageId + 1;
    const nextStage = quoteStages.filter((x) => x.id === nextStageId)[0];
    return nextStage.label;
  }, [state.quote.activeStageId]);

  const onBlurFieldHandler = (e, fieldLabel, meta) => {
    // console.log(`>>> onBlurFieldHandler`, e.name, e.value, fieldLabel, e, meta);

    if (meta && meta.error) {
      const errMsg = meta.error;
      trackValidationError(errMsg, fieldLabel, 'Business details')
    }    
  };
  
  const onRadioFieldSelection = (e, fieldLabel, option, values) => {
    // console.log(`>>> onRadioFieldSelection`, e, fieldLabel);
    
    trackRadioButtonSelection(fieldLabel, option.label);

    if (!values) {
      return;
    }

    if (fieldLabel.indexOf('legal business entity') > -1) {
      if (
        e.value
        && amountFinancedIsTooLow 
        && (
          e.value === BUSINESS_ENTITY_KEYS.PARTNERSHIP 
          || e.value === BUSINESS_ENTITY_KEYS.SOLE_TRADER
        )
      ) {
        trackValidationError('We are unable to provide you with a quote as the parameters entered indicate the amount financed is less than £25,000', fieldLabel, 'Business details')
      }
    }

    if (fieldLabel.indexOf('annual turnover') > -1) {
      if (e.value > 25000000) {
        trackValidationError('Unfortunately we’re unable to quote online for businesses with turnover over £25 million. You can still contact us to discuss your requirements', fieldLabel, 'Business details')
      }
    }
    
  };

  return (
    <>
      <h1>Business details</h1>
      <h3>What is the client's legal business entity?</h3>
      <Form
        onSubmit={onSubmit}
        initialValues={state.quote[PAGE_KEY]}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <input
              autoComplete="off"
              name="hidden"
              type="text"
              style={{ display: "none" }}
            />
            <FormSpy
              subscription={{ values: true, pristine: true }}
              onChange={(state) => {
                if (!state.pristine) {
                  const { values } = state;
                  onValuesChange(values);
                }
              }}
            />
            <RadioWrapper>
              {businessEntities.map((x) => {
                return (
                  <Field
                    name="businessEntity"
                    type="radio"
                    value={x.value}
                    key={`businessEntityType_${x.value}`}
                    validate={requiredValidator}
                  >
                    {({ input }) => {
                      return (
                        <RadioButton
                          key={`radio_businessEntityType_${x.value}`}
                          option={x}
                          bSelected={input.checked}
                          handleChange={(e) => {
                            input.onChange(e);
                            onRadioFieldSelection(input, `What is the client's legal business entity?`, x, values);
                          }}
                        />
                      );
                    }}
                  </Field>
                );
              })}
            </RadioWrapper>
            {form.getState().touched.businessEntity &&
              !values.businessEntity && (
                <ErrorMessage>Please select business entity type</ErrorMessage>
              )}
            {values.businessEntity &&
              amountFinancedIsTooLow &&
              (values.businessEntity === BUSINESS_ENTITY_KEYS.PARTNERSHIP ||
                values.businessEntity === BUSINESS_ENTITY_KEYS.SOLE_TRADER) && (
                <ErrorMessage>
                    We are unable to provide you with a quote as the parameters entered indicate the amount financed is less than £25,000
                </ErrorMessage>
              )}

            <PartnershipEntityFields values={values} form={form} />

            {values.businessEntity && (
              <>
                <h3>What is the annual turnover?</h3>
                <RadioWrapper>
                  {annualTurnoverRates.map((x) => {
                    return (
                      <Field
                        name="annualIncome"
                        type="radio"
                        value={x.value}
                        key={`annualTurnover_${x.value}`}
                        validate={requiredValidator}
                      >
                        {({ input }) => {
                          return (
                            <RadioButton
                              key={`radio_annualTurnover_${x.value}`}
                              option={x}
                              bSelected={input.checked}
                              handleChange={(e) => {
                                input.onChange(e);
                                onRadioFieldSelection(input, `What is the annual turnover?`, x, values);
                              }}
                            />
                          );
                        }}
                      </Field>
                    );
                  })}
                </RadioWrapper>
                {form.getState().touched.annualIncome &&
                  !values.annualIncome && (
                    <ErrorMessage>Please select annual turnover</ErrorMessage>
                  )}
                {values.annualIncome > 25000000 && (
                  <ErrorMessage>
                    Unfortunately we’re unable to quote online for businesses
                    with turnover over £25 million. You can still{" "}
                    <ErrorLink to={LombardRoutes.unableToProceedRoute()}>
                      contact us
                    </ErrorLink>{" "}
                    to discuss your requirements
                  </ErrorMessage>
                )}
              </>
            )}

            {values.businessEntity && (
              <>
                <h3>What is the net assets?</h3>
                <RadioWrapper>
                  {netAssetsNumber.map((x) => {
                    return (
                      <Field
                        name="netAssets"
                        type="radio"
                        value={x.value}
                        key={`netAssets_${x.value}`}
                        validate={requiredValidator}
                      >
                        {({ input }) => {
                          return (
                            <RadioButton
                              key={`radio_netAssets_${x.value}`}
                              option={x}
                              bSelected={input.checked}
                              handleChange={(e) => {
                                input.onChange(e);
                                onRadioFieldSelection(input, `What is the net assets?`, x);
                              }}
                            />
                          );
                        }}
                      </Field>
                    );
                  })}
                </RadioWrapper>
                {form.getState().touched.netAssets && (values.netAssets == undefined || values.netAssets == null) && (
                  <ErrorMessage>Please select net assets</ErrorMessage>
                )}
              </>
            )}

            {values.businessEntity &&
              (values.businessEntity === BUSINESS_ENTITY_KEYS.PARTNERSHIP ||
                values.businessEntity === BUSINESS_ENTITY_KEYS.SOLE_TRADER) && (
                <>
                  <H3>When did they start trading?</H3>
                  <small>MM/YYYY</small>
                  <Field
                    name="startedTradingDate"
                    validate={shortDateFieldValidator}
                  >
                    {({ input, meta }) => (
                      <>
                        <FieldInputWrapper>
                          <DateInput
                            {...input}
                            valid={isFieldValidAndTouched(meta)}
                            onBlur={(e) => { input.onBlur(); onBlurFieldHandler(input, `When did they start trading?`, meta); }}
                          />
                        </FieldInputWrapper>
                        {meta.error && meta.touched && (
                          <ErrorMessage>{meta.error}</ErrorMessage>
                        )}
                      </>
                    )}
                  </Field>
                </>
              )}

            <LimitedLiabilityPartnerShipFields values={values} form={form} />
            <LimitedCompanyFields values={values} form={form} pageTitle="Business Details" />

            <PrevNextButtons
              handleBackClick={handleBackClick}
              nextBtnText={`Next step: ${getNextStageName()}`}
            />
          </form>
        )}
      />
    </>
  );
};

export default BusinessDetails;
