import React from 'react';
import styled from 'styled-components';

import { colours } from 'resources/global-styles';

const Div = styled.div`
position: relative;
color: ${colours.table_content};
background-color: ${props => props.showInline ? 'none' : colours.warning_bg};
border: ${props => props.showInline ? 'none' : '1px solid ' + colours.warning_border};
border-radius: 8px;
padding: ${props => props.showInline ? '2px 24px 24px 36px;' : '24px 24px 24px 76px'};
background-image: ${props => props.showInline ? 'url(\'/images/information-icon-grey.svg\')' : 'url(\'/images/information-icon_purple.svg\')'};
background-size: 24px;
background-position: ${props => props.showInline ? 'initial' : `top ${props.showBare ? '50%' : '24px'} left 30px`};
background-repeat: no-repeat;
margin-bottom: 0;
font-size: 14px;
line-height: 20px;

& a {
  color: ${colours.warning_link};

  &:hover {
    text-decoration: underline;
  }
}
& p {
  margin-top: 0;
}
.inner-left-border {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 8px;
  height: -webkit-fill-available;
  background-color: ${colours.warning_border};
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}
`

const Warning = ({ children, withHTML = false, showBare = false, showInline = false }) => {
  if (withHTML) 
    return <><Div withHTML={withHTML} showInline={showInline} dangerouslySetInnerHTML={{ __html: children }}></Div>{showInline ? null : <div class="inner-left-border"></div>}</>
  else
    return <><Div showBare={showBare} showInline={showInline}>{children}{showInline ? null : <div class="inner-left-border"></div>}</Div></>
}


export default Warning;
