import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { pathVal } from 'resources/constants';
import { constsNatWest } from 'resources/constants';
import { Appetite, media } from 'resources/global-styles';
import { debugConsole } from 'resources/utils';

import { QuoteContext } from '_Broker/pages/Quote';
import Loading from '_Broker/structure/Loading';
import { StyledAppetite } from '../../../../quote/Summary/Appetite/LoanToValue/LoanToValue.jsx';

import { bucket } from '../../../../pages/Quote'

const amberPath = (path) => {
  return (
    <StyledAppetite path={path}>
      <strong>The debt service cover looks like it meets our appetite, but will require your BDM to check</strong>
    </StyledAppetite>
  );
};


const greenPath = (path) => {
  return (
    <StyledAppetite path={path}>
      <strong>Debt service coverage {path === pathVal.green ? 'within' : 'outside'} appetite.</strong>
      <p>Financial information supplied indicates debt service coverage is {path === pathVal.green ? 'within' : 'outside'} our normal lending appetite.</p>
    </StyledAppetite>
  );
};

const DebtServiceCoverage = ({ monthlyCost, interestRateSensitised, handlePath }) => {
  const context = useContext(QuoteContext);

  if (!monthlyCost) return <StyledAppetite><Loading /></StyledAppetite>

  const getDcrThreshold = function () {
    const sectorId = context['business.sector'].value;
    const subSectorId = context['business.sector.consolidated'].value;
    let dcrThreshold = null;
    
    context['sectors'].forEach(sector => {
      if (sector.id === sectorId) {
        sector.subSectors.forEach(subSector => {
          if (subSector.id === subSectorId) {
            dcrThreshold = subSector.dcrThreshold;
          }
        });
      }
    });

    return dcrThreshold;
  }

  const getDcrThresholds = function () {
    const sectorId = context['business.sector'].value;
    const subSectorId = context['business.sector.consolidated'].value;
    let dcrThresholds = [];
    
    context['sectors'].forEach(sector => {
      if (sector.id == sectorId) {
        sector.subSectors.forEach(subSector => {
          if (subSector.id == subSectorId) {
            dcrThresholds = [subSector.dcrThreshold, subSector.dcrThresholdHigher, subSector.dcrThresholdLower];
          }
        });
      }
    });

    return dcrThresholds;
  }

  const pmt = function (yearlyInterestRate, totalNumberOfMonths, loanAmount) {
    const yearlyRateAsPercentage = yearlyInterestRate / 100;
    const monthlyRateAsPercentage = yearlyRateAsPercentage / 12;

    const denominator = Math.pow((1 + monthlyRateAsPercentage), totalNumberOfMonths) - 1;
    const monthlyCost = (monthlyRateAsPercentage + (monthlyRateAsPercentage / denominator)) * loanAmount;

    return monthlyCost;
  }
  
  // ediba exist (either using the calculator or not)
  if (context['business.financial'].value === 'yes' && context['business.financial.ebitda'] && context['business.financial.existing_debt']) {
    const dcrThreshold = getDcrThreshold();
    const dcrThresholds = getDcrThresholds();
    const ebitda = Number(context['business.financial.ebitda'].value);
    const directorRemuneration = context['business.financial.director_remuneration'] ? Number(context['business.financial.director_remuneration'].value || 0) : 0;
    const existingDebt = Number(context['business.financial.existing_debt'].value);
    const serviceableValue = monthlyCost * 12;
    const coverageRate = ((ebitda - directorRemuneration) / (serviceableValue + existingDebt)).toFixed(2);
    
    // NOTE: When dcrThresholdsHigher and dcrThresholdsLower exist, use them to decide the path
    //  - excluding real-estate, health and agriculture
    let path;
    if (dcrThresholds[1] && dcrThresholds[2]) {
      path =
        coverageRate > dcrThresholds[1]
          ? pathVal.green
          : coverageRate < dcrThresholds[2]
            ? pathVal.red
            : pathVal.amber;
    } else {
      path = coverageRate >= dcrThresholds[0] ? pathVal.green : pathVal.red;
    }


    // console.log('olaf-483: dcrThresholds        :', dcrThresholds);
    // console.log('olaf-358: ebitda               :', ebitda);
    // console.log('olaf-358: directorRemuneration :', directorRemuneration);
    // console.log('olaf-358: DCR threshold is     :', dcrThreshold);
    // console.log('olaf-358: Monthly cost         :', monthlyCost);
    // console.log('olaf-358: DSC                  :', coverageRate);
    // console.log('olaf-358: threshold            :', dcrThreshold);
    // console.log('olaf-358: path                 :', path);

    debugConsole('Monthly cost', monthlyCost);
    debugConsole('DSC', coverageRate);
    debugConsole('threshold', dcrThreshold);
    debugConsole('path', path);

    if (path === pathVal.amber) {
      //change to be reverted
      handlePath(pathVal.amber, null, 'No financial information provided');
      // handlePath(pathVal.amber, coverageRate, 'BDM to check');
      return amberPath(path);
    }

    handlePath(path, coverageRate, path > pathVal.green ? 'Under threshold' : 'Green');
    return greenPath(path);
  }

  if (context['business.financial'].value === 'yes' && context['business.financial.gross_rent']) {
    // calculate interest cover ratio
    const capitalYearOne = Number(context['loan.amount'].value);
    const loanTerm = Number(context['loan.term'].value);
    
    // NOTE: check for Investiment (Residential)
    const policyInterestCover = context['business.sector.consolidated'].value === '104' ? constsNatWest.policyInterestCover.residential : constsNatWest.policyInterestCover.commercial;
    const GRI = context['business.financial.gross_rent'].value;
    const isResidential = context['business.sector.consolidated'].value === '104' || context['business.sector.consolidated'].value === '102'
    const NRI = (isResidential ? constsNatWest.nriPercent.residential : constsNatWest.nriPercent.commercial) * GRI;
    const dayOneInterest = (interestRateSensitised / 100) * capitalYearOne;
    const interestCoverRatio = NRI / dayOneInterest;

    // console.log("olaf-358: interestRateSensitised: ", interestRateSensitised);
    // console.log("olaf-358: capitalYearOne : ", capitalYearOne);
    // console.log("olaf-358: loanTerm : ", loanTerm);
    // console.log("olaf-358: policyInterestCover : ", policyInterestCover);
    // console.log("olaf-358: GRI : ", GRI);
    // console.log("olaf-358: NRI : ", NRI);
    // console.log("olaf-358: dayOneInterest : ", dayOneInterest);
    // console.log("olaf-358: interestCoverRatio : ", interestCoverRatio);

    // calculate nriDscRatio
    const totalNoOfMonths = loanTerm * 12;
    const loanAmount = Number(context['loan.amount'].value);
    const monthlyCost = pmt(interestRateSensitised, totalNoOfMonths, loanAmount);
    const yearlyCost = monthlyCost * 12;
    const nriDscRatio = NRI / yearlyCost;

    // console.log("olaf-358: totalNoOfMonths : ", totalNoOfMonths);
    // console.log("olaf-358: loanAmount : ", loanAmount);
    // console.log("olaf-358: monthlyCost : ", monthlyCost);
    // console.log("olaf-358: yearlyCost : ", yearlyCost);
    // console.log("olaf-358: nriDscRatio : ", nriDscRatio);

    bucket.interestCoverRatio = interestCoverRatio;
    bucket.nriDscRatio = nriDscRatio;

    const path = interestCoverRatio >= policyInterestCover && nriDscRatio >= 1 ? pathVal.green : pathVal.red;
    
    handlePath(path, interestCoverRatio, path > pathVal.green ? 'Under threshold' : 'Green');
    return (
      <StyledAppetite path={path}>
        <div class="inner-left-border"></div>
        <strong>Debt service coverage {path === pathVal.green ? 'within' : 'outside'} appetite.</strong>
        <p>Financial information supplied indicates debt service coverage is {path === pathVal.green ? 'within' : 'outside'} our normal lending appetite.</p>
      </StyledAppetite>
    );
  }

  handlePath(pathVal.amber, null, 'No financial information provided');
  return (
    <StyledAppetite path={pathVal.amber}>
      <div class="inner-left-border"></div>
      <strong>Client's financial information not provided.</strong>
      <div class="paragraph"><p>We've assumed that the loan would be affordable for your client. We'll need to verify this when you refer the quote to us.</p></div>
    </StyledAppetite>
  );
}

export default DebtServiceCoverage;
