import React from 'react';

import { useFetch } from 'resources/hooks';

const ResetPassword = ({ email }) => {
  useFetch('Passwords/forgotten', true, {
    email: email,
  });

  return <p>Thank you, a password reset email has been sent</p>
}

export default ResetPassword;
