import React from "react";
import styled from "styled-components";
import Footer from "./Footer";
import Header from "./Header";
import { media, colors } from "../theming/variables";
import { colours } from "../../resources/global-styles.js";

const PageContents = styled.div`
  box-sizing: border-box;
  max-width: ${media.maxWidth};
  margin: 0 auto;
  padding: 0 5%;
  width: 100%;
  -webkit-flex-grow: 1;
	flex-grow: 1;
  background-color: ${colours.page_bg};


  @media ${media.max} {
    padding: 0 0;
  }
`;

const PageWrapper = styled.div`

`;

const Page = (props) => {
  return (
    <PageWrapper className="lombard-container">
      <Header />
      {props.afterHeaderComponent}
      <PageContents>{props.children}</PageContents>
      <Footer />
    </PageWrapper>
  );
};

export default Page;
