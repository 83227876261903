import React, { Fragment } from 'react';
import styled from 'styled-components';

const Div = styled.div`
  display: flex;
  flex-wrap: wrap;

  & span {
    line-height: 60px;
    width: 50%;
    border-bottom: 1px solid #d9d7d7;

    &:nth-child(5),
    &:nth-child(6) {
      margin-top: 50px;
    }
  }
`
const Label = styled.span`
`
const Value = styled.span`
  font-family:"RNHouseSansBold";
  text-align: right;
`

const Details = ({ tableData }) => (
  <Div>
    {tableData.map((obj, i) => (
      <Fragment key={`row_${i}`}>
        <Label>{obj.label}</Label>
        <Value>{obj.value}</Value>
      </Fragment>
    ))}
  </Div>
)

export default Details;
