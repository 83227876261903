import React from "react";
import styled from "styled-components";
import assetsData from "resources/assetTypesData";
import { variables } from "../theming/variables";
import { colours } from "../../resources/global-styles.js";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
  border-bottom: 2px solid ${colours.table_bottom_border}; 
  padding-bottom: 0.5rem;
`;

const TableBody = styled.tbody`
  border-bottom: 2px solid ${colours.table_bottom_border}; 
`;

const TableCell = styled.td`
  padding: 20px 20px 20px 0;
  color: ${colours.table_content};

  &:first-child {
    width: 30%;
  }
`;

const TableRow = styled.tr`
  border-bottom: 1px solid ${colours.table_bottom_border};
`;

const HeaderTableCell = styled(TableCell)`
  font-weight: bold;
  color: ${colours.table_heading};
`;

const Wrapper = styled.div`
  margin-bottom: 4rem;
`;

const MaximumTermAndAgeTable = ({ assetKey }) => {
  const tableData = assetsData[assetKey];
  const headers =
    tableData && tableData.headers
      ? tableData.headers
      : assetsData.standardHeaders;
  const rows =
    tableData && tableData.rows ? tableData.rows : [["Test", "Test", "Test"]];
  return (
    <Wrapper>
      <h3>Maximum term and age information</h3>
      <Table>
        <TableHeader>
          <tr>
            {headers.map((x) => {
              return <HeaderTableCell>{x}</HeaderTableCell>;
            })}
          </tr>
        </TableHeader>
        <TableBody>
          {rows.map((row) => {
            return (
              <TableRow>
                {row.map((cell) => {
                  return <TableCell>{cell}</TableCell>;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Wrapper>
  );
};

export default MaximumTermAndAgeTable;
