import React from 'react';
import { Link } from 'react-router-dom';
import { isValid } from 'postcode';
import styled from 'styled-components';

import { currencyNoDecimalFormatter } from 'resources/constants';
import { isPurchasePriceIncludingVat, isDepositIncludingVat } from 'resources/utils';
import { VAT } from './constants';
import { isCarsAssetSelected } from './form';
import LombardRoutes from './routes';
import { colours } from "resources/global-styles";

const ErrorLink = styled(Link)`
  color:  ${colours.warning_link};
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

const requiredValidator = (value, evt, input) => {
  return value !== null && value !== undefined && value.toString().trim().length
    ? undefined
    : `Please enter ${getTextFromFieldName(input.name)}`;
};

const customRequiredValidator = (message) => (value, evt, input) => {
  if (message) {
    return value ? undefined : message;
  }
  return value ? undefined : `Please enter ${getTextFromFieldName(input.name)}`;
};

const emailRequiredValidator = (value) => {
  return value ? undefined : `Please enter a valid email address`;
};

const phoneValidator = (value) => {
  if (!value) {
    return undefined;
  }

  const phoneDigitsValue = value && value.replace(/[\s()-]+/g, "");
  const re = /^\d{9,11}$/;
  const result = re.test(phoneDigitsValue);

  return result ? undefined : "Please enter a valid UK phone number";
};

const emailValidator = (value) => {
  if (!value) {
    return;
  }

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return value && re.test(value.trim())
    ? undefined
    : "Please enter a valid email address";
};

const emailConfirmValidator = (emailConfirmId) => (value, evt, input) => {
  const email = evt.email;

  if (value === email) {
    return undefined;
  }

  return 'Email addresses should match';
};

const vehicleRegistrationNumberValidator = (value) => {
  if (!value) {
    return;
  }

  const re = /^[a-zA-Z0-9]+$/;
  return value && re.test(value)
    ? undefined
    : "Please enter a valid vehicle registration number without whitespaces";
};

const getTextFromFieldName = (name) => {
  var newName = name.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
  return newName.toLowerCase();
};

const numberValidator = (min, max) => (value) => {
  return value >= min && value <= max
    ? undefined
    : `Please enter a number between ${min} and ${max}`;
};

const currencyValidator = (min, max) => (value) => {
  const minValue = min ?? 0;

  if (!max) {
    return value > minValue
      ? undefined
      : `Please enter an amount bigger than ${currencyNoDecimalFormatter.format(
          minValue
        )}`;
  }

  return value >= minValue && value <= max
    ? undefined
    : `Please enter an amount between ${currencyNoDecimalFormatter.format(
        minValue
      )} and ${currencyNoDecimalFormatter.format(max)}`;
};

const dynamicTermLengthValidator = (assetTypes, form, fieldName) => (value) => {
  let min = 1;
  let max = 120;
  if (assetTypes && form.getState().values.assetTypeId) {
    var asset = assetTypes.filter(
      (x) => x.id === form.getState().values.assetTypeId
    )[0];
    min = asset.minTermLength;
    max = asset.maxTermLength;
  }

  return value >= min && value <= max
    ? undefined
    : `Please enter a number between ${min} and ${max}`;
};

/**
 * DEPOSIT validator - used in Lombard journey
 */
const dynamicDepositValidator = (form, assetTypes, vatDefer) => (value) => {
  const values = form.getState().values;
  
  const isVATInPP = isPurchasePriceIncludingVat(assetTypes, values.assetTypeId);
  const isVATInDeposit = isDepositIncludingVat(assetTypes, values.assetTypeId, vatDefer);
  
  const purchasePrice = values.purchasePrice; // gross cost input by user (include/exclude VAT)
  const deposit = Number(values.deposit) || 0;
  const lumsum = values.lumpsumset === 'yes' ? (Number(values.finalLumpSum) || 0) : 0;
  // console.log(`[dynamicDepositValidator] ${value} --- ${purchasePrice}, ${deposit}, ${lumsum}`);

  // reject - if purchasePrice is not defined
  if (purchasePrice == null) {
    return `Please enter the total ${
      isVATInPP ? 'gross' : 'net'
    } cost first. We need this to calculate the minimum deposit`;
  }

  // reject - if no input
  if (value == null) {
    return 'Please enter deposit amount';
  }

  let min = 0;
  let superMax = 0;
  const vatInDeposit = isVATInDeposit ? (purchasePrice * VAT).toFixed(2) * 1 : 0;

  if (isVATInPP) {
    // asset: car : purchasePrice including VAT, deposit excluding VAT
    min = 0;
    superMax = purchasePrice - 5000;
  } else {
    // asset: not car
    min = vatInDeposit;
    superMax = purchasePrice - 5000;
  }

  if (deposit < min) {
    return `Minimum deposit is 0% of total net asset cost + VAT. Please enter a larger deposit`;
  }

  if (deposit - vatInDeposit > superMax) {
    return `Maximum deposit exceeded, please enter a smaller deposit`;
  }
  
  if (lumsum + deposit - vatInDeposit >= purchasePrice) {
    return `The final lump sum payment and deposit exceeds gross cost, please enter a lower amount`;
  }

  return undefined;
};

/**
 * LUMPSUM validator - used in Lombard journey
 */
const dynamicLumpSumValidator = (form, assetTypes, vatDefer) => (value) => {
  const values = form.getState().values;
  const isVATIncluded = isCarsAssetSelected(assetTypes, values.assetTypeId, vatDefer);
  
  const maxPercent = 75;
  const lumpAndDepositTotal = values.purchasePrice * (maxPercent / 100);
  
  if (isNaN(lumpAndDepositTotal)) {
    return "Please enter deposit and total cost first. We need this to calculate lump sum range";
  }

  if (value > values.purchasePrice * 0.5) {
    return `Maximum final lump sum is 50% of total ${isVATIncluded ? 'gross' : 'net'} cost. Please enter a lower final lump sum`;
  }
};

const shortDateFieldValidator = (value) => {
  if (value && value.split("/").length > 1) {
    const dateParts = value.split("/");
    const monthPartIsValid =
      !isNaN(Number.parseInt(dateParts[0])) &&
      Number.parseInt(dateParts[0]) <= 12 &&
      Number.parseInt(dateParts[0]) > 0;

    const yearPartIsValid =
      !isNaN(Number.parseInt(dateParts[1])) &&
      Number.parseInt(dateParts[1]) <= new Date().getFullYear() &&
      Number.parseInt(dateParts[1]) >= 1792;

    const dateIsFutureDate =
      Number.parseInt(dateParts[1]) === new Date().getFullYear() &&
      new Date().getMonth() + 1 < Number.parseInt(dateParts[0]);

    return monthPartIsValid && yearPartIsValid && !dateIsFutureDate
      ? undefined
      : "Please enter a valid date";
  }
  return "Please enter a valid date";
};

const ukPostcodeValidator = (value) => {
  if (value) {
    const succeeded = isValid(value);
    return succeeded ? undefined : `Please enter a valid postcode`;
  }
  return undefined;
};

const getTotalForCommissionCalculation = (values, assetTypes) => {
  return isCarsAssetSelected(assetTypes, values.assetTypeId)
    ? values.purchasePrice - values.deposit
    // : values.purchasePrice * (1 + VAT) - values.deposit;
    : values.vatDefer === 'no' 
      ? values.purchasePrice * (1 + VAT) - values.deposit
      : values.purchasePrice - values.deposit;
};

const comissionValidator = (assetTypes) => (value, values) => {
  const min = 0;
  const max = 3;
  const vatDefer = values.vatDefer;
  const total = getTotalForCommissionCalculation(values, assetTypes, vatDefer);

  if (!values.purchasePrice || values.deposit == null) {
    return "Please enter the total cost and deposit first. We need this to calculate the commission amount";
  }

  if (value === undefined || value === null || !value.toString().trim().length) {
    return "Please enter commission";
  }

  // keep percentage to 6 decimal places (i.e. it could be 3.000000000000001, or 3.000024064492841)
  // actually as long as it is less than 14 decimal places, it will be fine
  const percentage = ((value * 100) / total).toFixed(6);

  return percentage >= min && percentage <= max
    ? undefined
    : `Please enter an amount between ${min}% and ${max}%`;
};

const dynamicTotalPriceValidatorMessage = (min, max) => (
  <span>
    Sum cost of assets should be between{" "}
    {currencyNoDecimalFormatter.format(min)} and{" "}
    {currencyNoDecimalFormatter.format(max)}. You can still{" "}
    <ErrorLink to={LombardRoutes.unableToProceedRoute()}>contact us</ErrorLink>{" "}
    to discuss your requirements
  </span>
);

const dynamicTotalPriceValidator = (form, assetTypes) => (value) => {
  const values = form.getState().values;
  const isCar = isCarsAssetSelected(assetTypes, values.assetTypeId);
  const min = isCar ? 5000 : 6000;
  const max = 250000;

  return value >= min && value <= max
    ? undefined
    : dynamicTotalPriceValidatorMessage(min, max);
};

const requiredPhoneValidator = (value) => {
  return value ? undefined : "Please enter a valid UK phone number";
};

const dynamicDirectorLastAppointedDate = (value, formValues) => {
  const tradingDate = formValues.startedTradingDate;
  if (
    value &&
    value.split("/").length > 1 &&
    tradingDate &&
    tradingDate.split("/").length > 1
  ) {
    const splittedTradingDate = tradingDate.split("/");
    const splittedValue = value.split("/");

    if (splittedValue[1] < splittedTradingDate[1]) {
      return "Please enter a date that is not before the Date of incorporation";
    }

    if (
      splittedValue[1] === splittedTradingDate[1] &&
      Number.parseInt(splittedTradingDate[0]) >
        Number.parseInt(splittedValue[0])
    ) {
      return "Please enter a date that is not before the Date of incorporation";
    }
  }

  return undefined;
};

export {
  requiredValidator,
  phoneValidator,
  emailValidator,
  emailConfirmValidator,
  emailRequiredValidator,
  numberValidator,
  currencyValidator,
  shortDateFieldValidator,
  dynamicTermLengthValidator,
  dynamicDepositValidator,
  dynamicLumpSumValidator,
  ukPostcodeValidator,
  comissionValidator,
  vehicleRegistrationNumberValidator,
  dynamicTotalPriceValidator,
  requiredPhoneValidator,
  customRequiredValidator,
  dynamicDirectorLastAppointedDate,
  getTotalForCommissionCalculation,
};