import React from "react";
import styled from "styled-components";
import { media } from "../theming/variables";
import { Input } from "../theming/common";

const FieldInputStyled = styled(Input)`
  width: 50%;

  @media ${media.mobile} {
    width: 100%;
  }
`;

const FieldInput = (props) => (
  <FieldInputStyled
    role="presentation"
    autoComplete="off"
    tabIndex={1}
    {...props}
  >
    {props.children}
  </FieldInputStyled>
);

export default FieldInput;
