import React from 'react';
import styled from 'styled-components';

import { colours } from 'resources/global-styles';


const Div = styled.div`
  position: relative;
  color: ${colours.table_content};
  background-color: ${props => props.showBare ? null : colours.warning_bg};
  border: 1px solid ${props => props.showBare ? null : colours.warning_border};
  border-radius: 8px;
  padding: 24px 24px 24px ${props => props.showBare ? '40px' : '84px'};
  background-image: url('/images/information-icon_purple.svg');
  background-position: top 24px left ${props => props.showBare ? '' : '30px'};
  background-repeat: no-repeat;
  margin-bottom: ${props => props.withHTML ? '50px' : '0'};
  font-size: ${props => props.showBare ? '18px' : '14px'};
  line-height: 20px;

  & a {
    color: ${colours.warning_link};

    &:hover {
      text-decoration: underline;
    }
  }
  & p {
    margin-top: 0;
  }
  .inner-left-border {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 8px;
    height: -webkit-fill-available;
    background-color: ${colours.warning_border};
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
  }
`

const Warning = ({ children, withHTML = false, showBare = false }) => {
  if (withHTML) return <Div withHTML={withHTML} dangerouslySetInnerHTML={{__html: children}}><div class="inner-left-border"></div></Div>
  return <Div showBare={showBare}>{children}<div class="inner-left-border"></div></Div>
}

export default Warning;
