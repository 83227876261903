import React from "react";
import styled from "styled-components";
import { media, colours } from "resources/global-styles";
import Radio from "./RadioButton";

const RadioWrapper = styled.div`
  @media ${media.tablet} {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
`;

const InvalidMessage = styled.div`
  display: block;
  width: ${(props) => (props.width ? props.width : "100%")};
  margin-bottom: ${(props) => (props.spacing ? props.spacing : "0")};
  background-color: ${colours.invalidRed};
  padding: 20px;
  color: white;
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-bottom: 10px solid ${colours.invalidRed};
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: -10px;
    left: 60px;
  }
`;

const RadioWrapperComponent = ({
  options,
  radioLayout,
  selectedOption,
  handleOptionChange,
  validationTriggered,
  validationMessage,
  useLombardStyles
}) => {

  return (
    <>
      <RadioWrapper>
        {options.map((x) => (
          <Radio
            layout={radioLayout}
            key={x.value}
            selectedValue={selectedOption}
            option={x}
            handleChange={handleOptionChange}
            useLombardStyles={useLombardStyles}
          />
        ))}
      </RadioWrapper>
      {validationTriggered && !selectedOption && (
        <InvalidMessage spacing="20px">{validationMessage}</InvalidMessage>
      )}
    </>
  );
};

export default RadioWrapperComponent;
