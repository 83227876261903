import React, { useState, useEffect } from "react";
import Header from "components/Header";
import Footer from "_Broker/structure/Footer";
import Page from "_Broker/structure/Page";
import styled from "styled-components";
import RadioWrapper from "../components/RadioWrapper";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useAppContext } from "../context/appContext";
import { products } from "../resources/constants";
import { useLombardContext } from "../_Lombard/context/lombardContext";
import LombardRoutes from "../_Lombard/utils/routes";
import PrevNextButtons from "../_Lombard/components/PrevNextButtons";
import { colours } from "resources/global-styles.js";
import {
  trackLombardApplicationStart,
  trackPageTransitionGetStarted,
  trackRadioButtonSelection,
  trackValidationError
} from "../resources/analyticsHelper";

const Main = styled.div`
  flex: 1 0 auto;
  background: ${colours.page_bg};
`;

const PageModified = styled(Page)`
  min-height: calc(100vh - 61px);
`;

const StyledH1 = styled.h1`
  font-family: "RNHouseSansBold",sans-serif;
  font-size: 36px;
  line-height: 1.2;
  color: ${colours.title_text};
  margin: 50px 0 10px 0;
`;

const StyledH3 = styled.h3`
  color: ${colours.title_text};
`;

const GetStartedLombardOnlyPage = () => {
  const { data, actions } = useAppContext();
  const lombardContext = useLombardContext();
  const [selectedProduct, setSelectedProduct] = useState(data.selectedProduct);
  const [productValidationTriggered, setProductValidationTriggered] =
    useState(false);
  const history = useHistory();

  const handleProductChange = (value) => {
    trackRadioButtonSelection('What product are you looking for?', value);
    setSelectedProduct(value);
  };

  useEffect(() => {
    trackPageTransitionGetStarted("Both");
  }, []);

  const match = useRouteMatch();
  const homepageLink = `/${match.params.brokerId}`;
  const handleNextClick = () => {

    if (selectedProduct) {
      actions.addOrUpdateChoice("selectedProduct", selectedProduct);

      lombardContext.actions.fetchAssetTypes();
      if (selectedProduct === "Fixed rate hire purchase") {
        if(lombardContext.state.initialQuoteCreationStarted === false){
          lombardContext.actions.markQuoteCreationStarted();
          trackLombardApplicationStart();
        }
        history.push(LombardRoutes.lombardQuoteRoute());
      } else {
        history.push(LombardRoutes.unableToProceedRoute());
      }
    } else {
      setProductValidationTriggered(true);
      
      trackValidationError(products.validationMessage, `What product are you looking for?`, `Let's get started`);
    }
  };

  return (
    <Main>
      <PageModified>
        <Header />
        <StyledH1>Let's get started</StyledH1>
        <StyledH3><h3>What product are you looking for?</h3></StyledH3>
        <RadioWrapper
          options={products.options}
          selectedOption={selectedProduct}
          handleOptionChange={handleProductChange}
          validationTriggered={productValidationTriggered}
          validationMessage={products.validationMessage}
          useLombardStyles={true}
        />
        <PrevNextButtons
          backLink={homepageLink}
          handleNextClick={handleNextClick}
          nextBtnText="Next"
        />
      </PageModified>
      <Footer useLombardColor={true} />
    </Main>
  );
};

export default GetStartedLombardOnlyPage;
