export const stageVal = {
  asset: 0,
  business: 1,
  summary: 2,
  additional: 3,
  review: 4,
};

export const quoteStages = [
  {
    id: stageVal.asset,
    label: "Asset details",
  },
  {
    id: stageVal.business,
    label: "Business details",
  },
  {
    id: stageVal.summary,
    label: "Quote summary",
  },
  {
    id: stageVal.additional,
    label: "Supporting details",
  },
];

export const lumpSumOptions = [
  {
    value: "yes",
    label: "Yes, add a final lump sum payment",
  },
  {
    value: "no",
    label: "No",
  },
];

export const BUSINESS_ENTITY_KEYS = {
  PRIVATE_LIMITED_COMPANY: "PrivateLimitedCompany",
  PUBLIC_LIMITED_COMPANY: "PublicLimitedCompany",
  LIMITED_LIABILITY_PARTNERSHIP: "LimitedLiabilityPartnership",
  PARTNERSHIP: "Partnership",
  SOLE_TRADER: "SoleTrader",
};

export const businessEntities = [
  {
    value: BUSINESS_ENTITY_KEYS.PRIVATE_LIMITED_COMPANY,
    label: "Private limited company (LTD)",
  },
  {
    value: BUSINESS_ENTITY_KEYS.PUBLIC_LIMITED_COMPANY,
    label: "Public limited company (PLC)",
  },
  {
    value: BUSINESS_ENTITY_KEYS.LIMITED_LIABILITY_PARTNERSHIP,
    label: "Limited liability partnership (LLP)",
  },
  {
    value: BUSINESS_ENTITY_KEYS.PARTNERSHIP,
    label: "Partnership",
  },
  {
    value: BUSINESS_ENTITY_KEYS.SOLE_TRADER,
    label: "Sole trader",
  },
];

export const annualTurnoverRates = [
  {
    value: 1000000,
    label: "Under £2m"
  },
  {
    value: 3000000,
    label: "Between £2m - £6.5m",
  },
  {
    value: 13000000,
    label: "Between £6.5m - £25m",
  },
  {
    value: 26000000,
    label: "Over £25m",
  },
];

export const netAssetsNumber = [
  {
    //changed the values from 2900000, 3300000 to 0, 1 (to solve bug: net assets radio button not getting selected after retrieving a saved quote)

    // value: 2900000,
    value: 0,
    label: "Less than £3.26m",
  },
  {
    // value: 3300000,
    value: 1,
    label: "More than £3.26m",
  },
]

export const partnersNumber = [
  {
    value: 1,
    label: "3 or less Partners",
  },
  {
    value: 2,
    label: "4 - 10 Partners",
  },
  {
    value: 3,
    label: "More than 10 Partners",
  },
];

export const limitedLiabilityPartnersNumber = [
  {
    value: 1,
    label: "1 Partner",
  },
  {
    value: 2,
    label: "2 to 10 Partners",
  },
  {
    value: 3,
    label: "More than 10 Partners",
  },
];

export const limitedCompanyDirectorsNumber = [
  {
    value: 1,
    label: "1 Director",
  },
  {
    value: 2,
    label: "2 to 10 Directors",
  },
  {
    value: 3,
    label: "More than 10 Directors",
  },
];

export const VAT = 0.2;
