import React from 'react';
import styled from 'styled-components';

import { media } from 'resources/global-styles';

const Main = styled.main`
  box-sizing: border-box;
  max-width: ${media.maxWidth};
  margin: 0 auto;
  padding: 0 5%;

  @media ${media.max} {
    padding: 0 0;    
  }
`

const Page = ({ className, children }) => (
  <Main className={className}>
    {children}
  </Main>
);

export default Page;
