import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { media, colours, RoundedDiv } from "resources/global-styles";
import { useKeyPress, useOutline } from "resources/hooks";

const getWidthFromLayout = (layout) => {
  if(layout === "fullwidth"){
    return "100%";
  }
  if(layout === "triple"){
    return "32%";
  }
  if(layout === "1-column"){
    return "100%";
  }

  return "49%";
}

const RadioStyled = styled.div`
  outline: ${(props) => props.checked ? "2px solid " : "1px solid "}
  ${(props) => (props.checked ? colours.radio_border_checked : colours.field_border)};
  border-radius: 8px;
  padding: 25px;
  width: ${(props) => (getWidthFromLayout(props.layout))};
  margin-bottom: 20px;
  padding: 0;
  border-width: 1px;

  @media ${media.tablet} {
    width: ${(props) => (getWidthFromLayout(props.layout))};
  }

  @media ${media.mobile}{
    width: 100%;
  }

  &:hover:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.input_border};
  }

  &:hover {
    border: none;
    outline: 2px solid ${colours.input_border};
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.input_border};
  }
`;

const RadioButton = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 16px 16px 16px 56px;
  cursor: pointer;
  transition: border-color 1s ease-in-out;

  &::before {
    content: "";
    display: block;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    border: ${(props) => props.checked ? "2px solid " : "1px solid "}
      ${(props) => (props.checked ? colours.radio_border_checked : colours.field_border)};
    position: absolute;
    top: 17px;
    left: 17px;
  }

  &::after {
    content: "";
    display: block;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 23px;
    left: 23px;
    background-color: ${(props) =>
      props.checked ? colours.radio_border_checked : "transparent"};
  }
`;

const Radio = ({ option, selectedValue, handleChange, layout, useLombardStyles }) => {
  const bSelected = option.value === selectedValue;
  const [hasFocus, setHasFocus] = useState(false);
  const bOutline = useOutline();
  const spacePress = useKeyPress(" ");

  useEffect(() => {
    if (spacePress && !bSelected && hasFocus) handleChange(option.value);
  }, [bSelected, hasFocus, spacePress, handleChange, option.value]);

  return (
    <RadioStyled
      checked={bSelected}
      outline={bOutline}
      layout={layout}
      tabIndex={1}
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
      useLombardStyles={useLombardStyles}
    >
      <RadioButton
        useLombardStyles={useLombardStyles}
        checked={bSelected}
        onClick={() => handleChange(option.value)}
      >
        {
          option.label.split("(").length > 1 &&
          <>
          <strong>{option.label.split("(")[0]}</strong>{" "}
          ({option.label.split("(")[1]}
          </>
        }
        {
          option.label.split("(").length === 1 &&
          option.label
        }

      </RadioButton>
    </RadioStyled>
  );
};

export default Radio;
