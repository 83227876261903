

export const baseColors = {
  color_regal_purple: "#3c1053",
  color_champion_purple: "#5a287d",
  color_bright_purple: "#5e10b1",
  color_secondary_purple: "#8b3fb2",
  color_black: "#000000",
  color_heading_grey: "#333333",
  color_body_grey: "#646068",
  color_light_grey: "#cccfd0",
  color_ground_grey: "#f2f2f8",
  color_white: "#ffffff",
  color_tangerine: "#db3700",
  color_lilac: "#f2eaf9",
  color_light_yellow: "#fef8e5",
  color_dark_yellow: "#fcb900", 
  color_dark_red: "#af2317",
  color_light_red: "#fae8e8",
  color_dark_green: "#429448",
  color_light_green: "#ecf4eD",
}