import React, { createContext, useContext, useEffect } from "react";
import { baseConfigs } from "resources/constants";

import { fetchPlus } from 'resources/utils';

const AppContext = createContext();

function appReducer(state, action) {
  switch (action.type) {
    case "addorupdatechoice": {
      const { payload } = action;
      let newState = { ...state };
      newState[payload.key] = payload.value;

      return newState;
    }
    case "clearContextState": {
      return {};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AppContextProvider({ children }) {
  const [state, dispatch] = React.useReducer(appReducer, {});
  const actions = {
    addOrUpdateChoice: (key, value) => {
      if (key && value) {
        dispatch({
          type: "addorupdatechoice",
          payload: {
            key,
            value,
          },
        });
      }
    },
    clearContextState: () => {
      dispatch({
        type: "clearContextState",
      });
    },
  };
  const value = { data: state, actions };
  
  useEffect(() => {

    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/BaseConfigs`;
      const { json: configs } = await fetchPlus(url, false);
      configs.map(o => {
        if (!isNaN(o.value) && o.value !== null && o.value !== undefined && o.value !== "") {
          baseConfigs[o.key] = Number(o.value);
        }
        else {
          baseConfigs[o.key] = o.value;
        }
      });
    };

    fetchData();
  }, []);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

function useAppContext() {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppContext");
  }
  return context;
}

export { AppContextProvider, useAppContext };
