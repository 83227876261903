import { pathVal } from 'resources/constants';
import { brokerAuth, getKeyByValue } from 'resources/utils';
import { useFetch } from 'resources/hooks';

const FetchAppetite = ({ quoteId, ltvAppetite, dscAppetite, val, loadData }) => {
  const dscAppetiteCurrent = loadData();
  const url = `Quotes/${quoteId}/appetite`;
  useFetch(url, true, {
    brokerFirmIdentifier: brokerAuth(),
    loanToValue: Number(ltvAppetite.value),
    loanToValueAppetite: getKeyByValue(pathVal, ltvAppetite.path),
    loanToValueAppetiteReason: ltvAppetite.reason,
    debtServiceCoverage: Number(dscAppetiteCurrent.value),
    debtServiceCoverageAppetite: getKeyByValue(pathVal, dscAppetiteCurrent.path),
    debtServiceCoverageAppetiteReason: dscAppetiteCurrent.reason,
  }, null, [quoteId]);

  return null;
}

export default FetchAppetite;
