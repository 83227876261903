import React from "react";
import { Field } from "react-final-form";
import {
  ErrorMessage,
  RadioWrapper,
  FieldInnerContainer,
  FieldInputWrapper,
  H3,
} from "../../../theming/common";
import {
  requiredValidator,
  shortDateFieldValidator,
} from "../../../utils/validators";
import { isFieldValidAndTouched } from "../../../utils/form";
import RadioButton from "../../../components/RadioButton";
import {
  limitedLiabilityPartnersNumber,
  BUSINESS_ENTITY_KEYS,
} from "../../../utils/constants";
import DateInput from "../../../components/DateInput";
import { trackRadioButtonSelection, trackValidationError } from "resources/analyticsHelper";

const LimitedLiabilityPartnerShipFields = ({ values, form }) => {
  

  const onBlurFieldHandler = (e, fieldLabel, meta) => {
    // console.log(`>>> onBlurFieldHandler`, e.name, e.value, fieldLabel, e, meta);

    if (meta && meta.error) {
      const errMsg = meta.error;
      trackValidationError(errMsg, fieldLabel, 'Asset details')
    }    
  };

  const onRadioFieldSelection = (e, fieldLabel, option) => {
    // console.log(`>>> onRadioFieldSelection`, e, fieldLabel);
    
    trackRadioButtonSelection(fieldLabel, option.label);
  };

  return (
    <>
      {values.businessEntity &&
        values.businessEntity ===
          BUSINESS_ENTITY_KEYS.LIMITED_LIABILITY_PARTNERSHIP && (
          <>
            <H3>Date of incorporation</H3>
            <small>MM/YYYY</small>
            <Field name="startedTradingDate" validate={shortDateFieldValidator}>
              {({ input, meta }) => (
                <>
                  <FieldInputWrapper>
                    <DateInput
                      {...input}
                      valid={isFieldValidAndTouched(meta)}
                    />
                  </FieldInputWrapper>
                  {meta.error && meta.touched && (
                    <ErrorMessage>{meta.error}</ErrorMessage>
                  )}
                </>
              )}
            </Field>

            <h3>How many Partners are there?</h3>
            <RadioWrapper>
              {limitedLiabilityPartnersNumber.map((x) => {
                return (
                  <Field
                    name="numberOfPartners"
                    type="radio"
                    value={x.value}
                    key={`partnersNumber_${x.value}`}
                    validate={requiredValidator}
                  >
                    {({ input }) => {
                      return (
                        <RadioButton
                          key={`radio_partnersNumber_${x.value}`}
                          option={x}
                          bSelected={input.checked}
                          // handleChange={input.onChange}
                          handleChange={(e) => {
                            input.onChange(e);
                            onRadioFieldSelection(input, `How many Partners are there?`, x);
                          }}
                          layout="triple"
                        />
                      );
                    }}
                  </Field>
                );
              })}
            </RadioWrapper>
            {form.getState().touched.businessEntity &&
              !values.numberOfPartners && (
                <ErrorMessage>
                  Please select the number of partners
                </ErrorMessage>
              )}

            <H3>When was the last Partner appointed?</H3>
            <small>MM/YYYY</small>
            <Field
              name="lastPartnerAppointed"
              validate={shortDateFieldValidator}
            >
              {({ input, meta }) => (
                <>
                  <FieldInputWrapper>
                    <DateInput
                      {...input}
                      valid={isFieldValidAndTouched(meta)}
                      onBlur={(e) => { input.onBlur(); onBlurFieldHandler(input, `When did they start trading?`, meta); }}
                    />
                  </FieldInputWrapper>
                  {meta.error && meta.touched && (
                    <ErrorMessage>{meta.error}</ErrorMessage>
                  )}
                </>
              )}
            </Field>
          </>
        )}
    </>
  );
};

export default LimitedLiabilityPartnerShipFields;
