import { stageVal } from 'resources/constants';
import { useFetch } from 'resources/hooks';
import { brokerAuth } from 'resources/utils';

const FetchReferralId = ({ product, quote, handleStateChange, handleStageChange }) => {
  const referralFetch = useFetch('Referrals', true, {
    quoteId: quote,
    brokerFirmIdentifier: brokerAuth(),
    selectedProductId: product,
  });

  if (referralFetch.data) {
    handleStateChange({ choiceId: 'id.referral', choiceVal: referralFetch.data.referralId });
    handleStageChange(stageVal.refer);
  }

  return null;
}

export default FetchReferralId;