import React from "react";
import styled from "styled-components";
import { brokerAccessOnly, getAccessLevel } from "../../resources/utils";

import { HeaderFooter } from "resources/global-styles";

const StyledFooter = styled.footer`
  & div {
    margin-right: 25px;
    line-height: 30px;

    & a {
      color: white;
      font-size: 14px;
    }
    & a:hover {
      text-decoration: underline;
    }
  }
`;

const BrokerOnlyFooterLinks = () => (
  <StyledFooter>
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.natwest.com/global/cookieprivacy.ashx?channel=personal"
      >
        Privacy &amp; Cookies
      </a>
    </div>
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://personal.natwest.com/personal/website-terms-and-FSCS.html"
      >
        Website T&amp;Cs &amp; FSCS
      </a>
    </div>
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://personal.natwest.com/personal/fraud-and-security.html"
      >
        Security Centre
      </a>
    </div>
  </StyledFooter>
);

const LombardAndAllFooterLinks = () => (
  <StyledFooter>
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.natwest.com/global/cookieprivacy.ashx?channel=personal"
      >
        Privacy &amp; Cookies
      </a>
    </div>
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.natwest.com/website-terms-and-FSCS.html"
      >
        NatWest T&amp;C's &amp; FSCS
      </a>
    </div>
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.lombard.co.uk/toolbar/terms-conditions.html"
      >
        Lombard T&amp;C's
      </a>
    </div>
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://personal.natwest.com/personal/fraud-and-security.html"
      >
        Security Centre
      </a>
    </div>
  </StyledFooter>
);

const Footer = ({ useLombardColor }) => (
  <HeaderFooter useLombardColor={useLombardColor}>
    {brokerAccessOnly() && <BrokerOnlyFooterLinks />}
    {(!getAccessLevel() || !brokerAccessOnly()) && <LombardAndAllFooterLinks />}
  </HeaderFooter>
);

export default Footer;
