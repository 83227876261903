import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Page from '_Broker/structure/Page';
import { media, Button, Input, TextLink } from 'resources/global-styles';
import { adminAuth } from 'resources/utils';
import Footer from '_Broker/structure/Footer';

import { ReactComponent as LogoIcon } from 'resources/images/logo.svg';

import Login from '_Broker/admin/Login';
import ResetPassword from '_Broker/admin/ResetPassword';
import EditData from '_Broker/admin/EditData';

const AdminPage = styled.div`
  flex: 1 0 auto;

  .login-error{
    width: 600px;
    margin-bottom: 20px;
    font-size: 16px;
    color: red;
  }

  .administration{
    width: auto;
    display: flex;
    -webkit-box-pack: justify;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-between;
    align-items: baseline;
  }

  @media ${media.tablet} {
    background-image: url('/images/start-graphic-new.svg');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 48%;
  }
  @media ${media.desktop} {
    background-size: 480px;
  }
`
const Header = styled.header`
  margin-bottom: 20px;
  
  @media ${media.tablet} {
    width: 50%;
  }

  & img {
    margin: 40px 0;
  }
  
  & h1 {
    margin: 30px 0;
    font-size: 42px;
  }
`
const Logo = styled(LogoIcon)`
  width: 180px;
  height: 33px;
  margin-top: 50px;
`

const Admin = () => {
  const [login, setLogin] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [forgot, setForgot] = useState(0);
  const [adminEmail, setAdminEmail] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [auth, setAuth] = useState(null);

  const handleLoginError = (error) => {
    setLogin(false);
    setLoginError(error);
  }

  const logout = () => {
    localStorage.setItem('admin', '');
    setAuth(adminAuth());
    setLogin(false);
  }

  useEffect(() => {
    setAuth(adminAuth());
  });

  if (auth) return (
    <>
      <AdminPage>
        <Page>
          <Header className='administration'>
            <h1>Administration</h1>
            <TextLink onClick={() => logout()}>Logout</TextLink>
          </Header>
          <EditData type='Base Configs' auth={auth} />
          <EditData type='Funding Costs' auth={auth} />
          <EditData type='Rate Cards' auth={auth} />
          <EditData type='Lombard Funding Costs' auth={auth} />
        </Page>
      </AdminPage>
      <Footer />
    </>
  );

  if (login && adminEmail.length && adminPassword.length) return <Login email={adminEmail} password={adminPassword} handleError={handleLoginError} />

  if (forgot > 0) return (
    <>
      <AdminPage>
        <Page>
          <Header>
            <Logo alt="NatWest" />
            <h1>Forgot password</h1>
            {forgot === 1 && (
              <>
                <Input onChange={(evt) => setAdminEmail(evt.target.value)} value={adminEmail} fullwidth={true} maxLength='50' placeholder='Email address' />
                <Button onClick={() => setForgot(2)}>Reset</Button>
              </>
            )}
            {forgot === 2 && <ResetPassword email={adminEmail} />}
            <TextLink onClick={() => setForgot(0)}>Back to login</TextLink>
          </Header>
        </Page>
      </AdminPage>
      <Footer />
    </>
  )

  return (
    <>
      <AdminPage>
        <Page>
          <Header>
            <Logo alt="NatWest" />
            <h1>Administration login</h1>
            <Input onChange={(evt) => setAdminEmail(evt.target.value)} value={adminEmail} fullwidth={true} maxLength='50' placeholder='Email address' />
            <Input type='password' onChange={(evt) => setAdminPassword(evt.target.value)} value={adminPassword} fullwidth={true} maxLength='50' placeholder='Password' />
            <Button onClick={() => setLogin(true)}>Login</Button>
            {loginError && <div className='login-error'>{loginError}</div>}
            <TextLink onClick={() => setForgot(1)}>Forgotten password?</TextLink>
          </Header>
        </Page>
      </AdminPage>
      <Footer />
    </>
  );
}

export default Admin;
