import React from "react";
import { Field } from "react-final-form";
import {
  currencyValidator,
  requiredValidator,
  customRequiredValidator
} from "../../../utils/validators";
import { isFieldValidAndTouched, composeValidators } from "../../../utils/form";
import Numeric from "../../../components/NumericInput";
import FieldInput from "../../../components/FieldInput";
import {
  ErrorMessage,
  FieldInputWrapper,
} from "../../../theming/common";
import { BUSINESS_ENTITY_KEYS } from "../../../utils/constants";
import { useLombardContext } from "../../../context/lombardContext";
import CompanySelect from "../../../components/CompanySelect";
import PostcodeLookUp from "../../../components/AddressSelect";
import { trackValidationError } from "resources/analyticsHelper";

const ClientDetailsFields = () => {
  const { state } = useLombardContext();
  const businessEntity = state.quote.businessDetails.businessEntity;

  
  const onBlurFieldHandler = (e, fieldLabel, meta) => {
    // console.log(`>>> onBlurFieldHandler`, e.name, e.value, fieldLabel, e);

    if (meta && meta.error) {
      let errMsg = meta.error;
      trackValidationError(errMsg, fieldLabel, 'Supporting details')
    }
  };

  return (
    <>
      {(businessEntity === BUSINESS_ENTITY_KEYS.LIMITED_LIABILITY_PARTNERSHIP ||
        businessEntity === BUSINESS_ENTITY_KEYS.PRIVATE_LIMITED_COMPANY ||
        businessEntity === BUSINESS_ENTITY_KEYS.PUBLIC_LIMITED_COMPANY) && (
        <>
          <Field name="registeredBusinessName" validate={composeValidators(customRequiredValidator("Please enter registered business name or number"))}>
            {({ input, meta }) => (
              <div>
                <label>What is the registered business name?</label>
                <small>
                  Or enter the registered company number to look up the name
                </small>
                <div>
                  <CompanySelect
                    value={input.value}
                    valid={isFieldValidAndTouched(meta)}
                    onChange={input.onChange}
                    className={meta.error && meta.touched ? 'error-border' : ''}
                    // onBlur={input.onBlur}
                    onBlur={(e) => {
                      input.onBlur(e); 
                      onBlurFieldHandler(input, 'What is the registered business name?', meta);
                    }}
                  />
                </div>
                {meta.error && meta.touched && (
                  <ErrorMessage>{meta.error}</ErrorMessage>
                )}
              </div>
            )}
          </Field>
        </>
      )}

      {businessEntity === BUSINESS_ENTITY_KEYS.PARTNERSHIP && (
        <>
          <Field name="tradingName" validate={requiredValidator}>
            {({ input, meta }) => (
              <div>
                <label>What is the trading name?</label>
                <FieldInput
                  {...input}
                  valid={isFieldValidAndTouched(meta)}
                  type="text"
                  onBlur={(e) => {
                    input.onBlur(e); 
                    onBlurFieldHandler(input, 'What is the trading name?', meta);
                  }}
                />
                {meta.error && meta.touched && (
                  <ErrorMessage>{meta.error}</ErrorMessage>
                )}
              </div>
            )}
          </Field>
          <Field name="address">
            {({ input, meta }) => (
              <div>
                <label>Business address</label>
                <small>Type your client's business postcode</small>
                <div>
                  <PostcodeLookUp 
                    {...input} 
                    postcodeIsRequired={true} 
                    valid={isFieldValidAndTouched(meta)}
                    pageTitle="Supporting details"
                    fieldLabel="Business address"
                  />
                </div>
              </div>
            )}
          </Field>
        </>
      )}

      {businessEntity === BUSINESS_ENTITY_KEYS.SOLE_TRADER && (
        <>
          <Field name="clientFirstName" validate={requiredValidator}>
            {({ input, meta }) => (
              <div>
                <label>Client's first name</label>
                <FieldInput
                  {...input}
                  valid={isFieldValidAndTouched(meta)}
                  type="text"
                  onBlur={(e) => {
                    input.onBlur(e); 
                    onBlurFieldHandler(input, `Client's first name`, meta);
                  }}
                />
                {meta.error && meta.touched && (
                  <ErrorMessage>{meta.error}</ErrorMessage>
                )}
              </div>
            )}
          </Field>
          <Field name="clientLastName" validate={requiredValidator}>
            {({ input, meta }) => (
              <div>
                <label>Client's last name</label>
                <FieldInput
                  {...input}
                  valid={isFieldValidAndTouched(meta)}
                  type="text"
                  onBlur={(e) => {
                    input.onBlur(e); 
                    onBlurFieldHandler(input, `Client's last name`, meta);
                  }}
                />
                {meta.error && meta.touched && (
                  <ErrorMessage>{meta.error}</ErrorMessage>
                )}
              </div>
            )}
          </Field>
          <Field name="tradingName" validate={requiredValidator}>
            {({ input, meta }) => (
              <div>
                <label>What is the trading name?</label>
                <FieldInput
                  {...input}
                  valid={isFieldValidAndTouched(meta)}
                  type="text"
                  onBlur={(e) => {
                    input.onBlur(e); 
                    onBlurFieldHandler(input, `What is the trading name?`, meta);
                  }}
                />
                {meta.error && meta.touched && (
                  <ErrorMessage>{meta.error}</ErrorMessage>
                )}
              </div>
            )}
          </Field>
          <Field name="address">
            {({ input, meta }) => (
              <div>
                <label>Business address</label>
                <small>Type your client's business postcode</small>
                <div>
                  <PostcodeLookUp 
                    {...input} 
                    postcodeIsRequired={true} 
                    valid={isFieldValidAndTouched(meta)}
                    pageTitle="Supporting details"
                    fieldLabel="Business address" 
                  />
                </div>
              </div>
            )}
          </Field>
        </>
      )}

      <Field
        name="annualTurnover"
        validate={composeValidators(currencyValidator())}
      >
        {({ input, meta }) => (
          <>
            <FieldInputWrapper>
              <label>Annual turnover</label>
              <Numeric
                {...input}
                valid={isFieldValidAndTouched(meta)}
                isCurrency={true}
                onBlur={(e) => {
                  input.onBlur(e); 
                  onBlurFieldHandler(input, `Annual turnover`, meta);
                }}
              />
            </FieldInputWrapper>
            {meta.error && meta.touched && (
              <ErrorMessage>{meta.error}</ErrorMessage>
            )}
          </>
        )}
      </Field>
      <Field name="netAssets" validate={composeValidators(currencyValidator())}>
        {({ input, meta }) => (
          <>
            <FieldInputWrapper>
              <label>Net assets</label>
              <Numeric
                {...input}
                valid={isFieldValidAndTouched(meta)}
                isCurrency={true}
                onBlur={(e) => {
                  input.onBlur(e); 
                  onBlurFieldHandler(input, `Net assets`, meta);
                }}
              />
            </FieldInputWrapper>
            {meta.error && meta.touched && (
              <ErrorMessage>{meta.error}</ErrorMessage>
            )}
          </>
        )}
      </Field>
    </>
  );
};

export default ClientDetailsFields;
