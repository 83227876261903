import React, { useContext } from 'react';
import styled from 'styled-components';

import { pathVal } from 'resources/constants';
import { findSector, debugConsole } from 'resources/utils';
import { Appetite, media, colours } from 'resources/global-styles';

import { QuoteContext } from '_Broker/pages/Quote';

export const StyledAppetite = styled(Appetite)`

  position: relative;
  border: 1px solid ${(props) => (props.path === pathVal.green ? colours.appetite_green_border : props.path === pathVal.amber ? colours.appetite_yellow_border : colours.appetite_red_border)};
  background-color: ${(props) => (props.path === pathVal.green ? colours.appetite_green_bg : props.path === pathVal.amber ? colours.appetite_yellow_bg : colours.appetite_red_bg)};

  @media ${media.tablet} {
    margin-right: 10px;
  }

  .inner-left-border {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 4px;
    height: -webkit-fill-available;
    background-color: ${(props) => (props.path === pathVal.green ? colours.appetite_green_border : props.path === pathVal.amber ? colours.appetite_yellow_border : colours.appetite_red_border)};
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }

  .paragraph{
    color: ${colours.appetite_text_copy};
  }
`

const LoanToValue = ({ handlePath }) => {
  const context = useContext(QuoteContext);

  if (context['loan.secured'].value === 'property') {
    let propertyVal = context['loan.secured.property_owned'] && context['loan.secured.property_owned'].value === 'yes' && context['loan.secured.property_value'] && !isNaN(context['loan.secured.property_value'].value) ? Number(context['loan.secured.property_value'].value) : 0;
    if (!propertyVal) propertyVal = context['loan.secured.property_owned'] && context['loan.secured.property_owned'].value === 'no' && context['loan.secured.property_purchase'] && !isNaN(context['loan.secured.property_purchase'].value) ? Number(context['loan.secured.property_purchase'].value) : 0;
    if (propertyVal) {
      const ltvValue =  ((Number(context['loan.amount'].value) / propertyVal) * 100).toFixed(2);
      const ltvThreshold = findSector(context.sectors, context['business.sector.consolidated'].value).ltv;
      
      if (ltvThreshold) {
        const path = ltvValue <= ltvThreshold ? pathVal.green : ltvValue <= (ltvThreshold+3) ? pathVal.amber : pathVal.red;

        debugConsole('LTV', ltvValue);
        debugConsole('threshold', ltvThreshold);
        debugConsole('path', path);
        handlePath(path, ltvValue, path > pathVal.green ? 'Over threshold' : 'Green');

        return (
          <StyledAppetite path={path}>
            <div class="inner-left-border"></div>
            <strong>The loan-to-value is {ltvValue}%</strong>
            <div class="paragraph">
              <p>
                {path === pathVal.green && <>This is within our normal lending appetite for this sector.</>}
                {path === pathVal.amber && <>This is slightly outside our normal lending appetite for this sector, which is {ltvThreshold}%.</>}
                {path === pathVal.red && <>This is outside our normal lending appetite for this sector, which is {ltvThreshold}%.</>}
              </p>
            </div>
            {path !== pathVal.green && <p>To increase the chances of getting a loan, try reducing the loan amount.</p>}
          </StyledAppetite>
        );
      }
    }
  }

  handlePath(pathVal.amber, null, 'No security provided');
  return (
    <StyledAppetite path={pathVal.amber}>
      <div class="inner-left-border"></div>
      <strong>No security provided</strong>
      <div class="paragraph"><p>This is slightly outside our normal lending appetite for this sector.</p></div>
    </StyledAppetite>
  );
}

export default LoanToValue;
