import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as LogoIcon } from 'resources/images/logo.svg';
import { media, Button, Input } from 'resources/global-styles';
import Page from '_Broker/structure/Page';
import Footer from '_Broker/structure/Footer';
import NewPassword from '_Broker/admin/NewPassword';
import NotValidated from '_Broker/structure/NotValidated';

const AdminPage = styled.div`
  flex: 1 0 auto;

  @media ${media.tablet} {
    background-image: url('/images/start-graphic-new.svg');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 48%;
  }
  @media ${media.desktop} {
    background-size: 480px;
  }
`
const Header = styled.header`
  margin-bottom: 20px;
  
  @media ${media.tablet} {
    width: 50%;
  }

  & img {
    margin: 40px 0;
  }
  
  & h1 {
    margin: 30px 0;
    font-size: 42px;
  }
`
const Logo = styled(LogoIcon)`
  width: 180px;
  height: 33px;
  margin-top: 50px;
`

const ChangePassword = ({ match }) => {
  const [change, setChange] = useState(false);
  const [adminEmail, setAdminEmail] = useState('');
  const [adminPassword, setAdminPassword] = useState('');

  return (
    <>
      <AdminPage>
        <Page>
          <Header>
            <Logo alt="NatWest" />
            <h1>Change password</h1>
            <Input onChange={(evt) => {
              setAdminEmail(evt.target.value)
              setChange(false)
            }} value={adminEmail} fullwidth={true} maxLength='50' placeholder='Email address' />
            <Input type='password' onChange={(evt) => {
              setAdminPassword(evt.target.value)
              setChange(false)
            }} value={adminPassword} fullwidth={true} maxLength='50' placeholder='New password' />
            {change && adminEmail.length > 0 && adminPassword.length < 12 && <NotValidated spacing='20px'>Password requires at least 12 characters</NotValidated>}
            <Button onClick={() => setChange(true)}>Submit</Button>
            {change && adminEmail.length > 0 && adminPassword.length > 11 && match.params.token.length && <NewPassword email={adminEmail} password={adminPassword} token={match.params.token} />}
          </Header>
        </Page>
      </AdminPage>
      <Footer />
    </>
  );
}

export default ChangePassword;
