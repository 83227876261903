import React from 'react';
import styled from 'styled-components';

import { media, HeaderFooter } from 'resources/global-styles';

const StyledHeaderFooter = styled(HeaderFooter)`
.styled-a {
  display: inline-block;
  position: relative;
}
`

const Logo = styled.div`
  text-align: right;
  line-height: 10px;
  height: 33px;

  .natwest_logo {
    height: 50px;
    width: 143px;
    margin-top: -9px;
    margin-left: -23px;
  }
`
const Title = styled.div`
  margin: 0 10px 0 0;
  height: 30px;
  line-height: 30px;
  padding: 2px 20px;
  font-size: 24px;
  border-left: 1px solid white;
`
const Lozenge = styled.div`
  display: none;

  @media ${media.tablet} {
    display: initial;
    margin: 3px 0;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    background-color: white;
    color: black;
    padding: 1px 16px;
    text-align: center;
    font-size: 13px;
  }
`

const Header = () => (
  <StyledHeaderFooter>
    <header>
      <a href='/' className="styled-a"><Logo><img src="/images/natwest_logo_new.svg" alt="NatWest" className="natwest_logo"/></Logo></a>
      <Title>Broker Portal</Title>
    </header>
  </StyledHeaderFooter>
);

export default Header;
