import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { starMessages } from "resources/constants";
import { ReactComponent as StarEmptyIcon } from "resources/images/star-empty.svg";
import { ReactComponent as StarFilledIcon } from "resources/images/star-filled.svg";
import { Button, TextArea, Tickbox } from "../theming/common";
import { colors } from "../theming/variables";
import ApiFetchService from "../utils/dataFetching";
import { useLombardContext } from "../context/lombardContext";
import { useAppContext } from "../../context/appContext";
import { trackFeedback, trackButtonClick, trackCheckboxSelection } from 'resources/analyticsHelper';

const StarWrapper = styled.div`
  width: 150px;
  margin: 0 auto 30px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const StarEmpty = styled(StarEmptyIcon)`
  cursor: pointer;

  &:hover > g > g > g > path {
    fill: ${colors.mediumGrey};
  }
`;

const StarFilled = styled(StarFilledIcon)`
  cursor: pointer;
`;

const WrapperDiv = styled.div`
  border: 1px solid ${colors.mediumGreyBorder};
  width: 100%;
  text-align: center;
  margin: 1.5rem auto;
  padding: 2rem;

  & h2 {
    color: ${colors.darkGrey};
    font-weight: bold;
    margin: 0 0 20px 0;
  }
`;

const BaseContainer = styled.div`
  margin-bottom: 2rem;
`;

const SaveRating = ({ rating, comment, contact, broker, referral }) => {
  ApiFetchService.SubmitApplicationRating(
    contact,
    rating,
    comment,
    referral,
    broker
  );

  return <h2>Thank you for sending your feedback</h2>;
};

const Star = ({ selected, onClick }) => {
  if (selected) return <StarFilled onClick={onClick} />;
  return <StarEmpty onClick={onClick} />;
};

const StarRating = ({ referral, broker }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState();
  const [contact, setContact] = useState(false);
  const [save, setSave] = useState(false);
  const history = useHistory();
  const { actions } = useLombardContext();
  const appContext = useAppContext();
  const appActions = appContext.actions;

  const handleBackToHomepageClick = () => {
    actions.clearContextState();
    appActions.clearContextState();
    history.push("/");
  }

  if (save && rating > 0) {
    if (contact) {
      trackCheckboxSelection('Tick the box if we can use your details to contact you about your experience', 'contact', true);
    }

    trackFeedback(rating, !!contact);

    setTimeout(() => {
      trackButtonClick('Button', 'Submit Feedback');
    }, 1000);

    return (
      <BaseContainer>
        <SaveRating
          rating={rating}
          comment={comment}
          contact={contact}
          broker={broker}
          referral={referral}
        />
        <Button
          onClick={handleBackToHomepageClick}
        >
          Return to homepage
        </Button>
      </BaseContainer>
    );
  }

  if (save && rating === 0) setSave(false);

  return (
    <BaseContainer>
      <WrapperDiv>
        <h2>How would you rate your overall application experience?</h2>
        <StarWrapper>
          {[...Array(5)].map((n, i) => (
            <Star
              key={`star_${i}`}
              selected={i < rating}
              onClick={() => setRating(i + 1)}
            />
          ))}
        </StarWrapper>
        {!!rating && (
          <p>
            {starMessages[rating]}
            <br />
          </p>
        )}
        <TextArea
          onChange={(evt) => setComment(evt.target.value)}
          value={comment}
          fullwidth="true"
          rows="6"
          maxlength="10000"
        />
        <div onClick={() => setContact(!contact)}>
          <Tickbox checked={!!contact} />
          Tick the box if we can use your details to contact you about your
          experience
        </div>
        <Button onClick={() => setSave(true)} style={{ margin: "30px 0 0 0" }}>
          Submit feedback
        </Button>
      </WrapperDiv>
      <Button
        onClick={handleBackToHomepageClick}
      >
        Return to homepage
      </Button>
    </BaseContainer>
  );
};

export default StarRating;
