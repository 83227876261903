import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import { media, colours, RoundedDiv } from 'resources/global-styles';
import { subContext } from 'resources/utils';
import { useKeyPress, useOutline } from 'resources/hooks';

import { QuoteContext } from '_Broker/pages/Quote';
import Warning from '_Broker/structure/Warning';
import FreeText from '../FreeText';
import Choice from '../Choice';

const getWidthFromLayout = (layout) => {
  if(layout === "fullwidth"){
    return "90%";
  }
  if(layout === "triple"){
    return "32%";
  }
  if(layout === "1-column"){
    return "100%";
  }

  return "49%";
}

const RadioStyled = styled.div`
  outline: ${(props) => props.checked ? "2px solid " : "1px solid "}
  ${(props) => (props.checked ? colours.radio_border_checked : colours.field_border)};
  border-radius: 8px;
  padding: 25px;
  width: ${(props) => (getWidthFromLayout(props.layout))};
  margin-bottom: 20px;
  padding: 0;
  border-width: 1px;
  background: ${colours.radio_bg};

  @media ${media.tablet} {
    width: ${(props) => (getWidthFromLayout(props.layout))};
  }

  @media ${media.mobile}{
    width: 100%;
  }

  &:hover:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.input_border};
  }

  &:hover {
    border: none;
    outline: 2px solid ${colours.input_border};
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.input_border};
  }
`;

const RadioButton = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 16px 16px 16px 56px;
  cursor: pointer;
  transition: border-color 1s ease-in-out;

  &::before {
    content: "";
    display: block;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    border: ${(props) => props.checked ? "2px solid " : "1px solid "}
      ${(props) => (props.checked ? colours.radio_border_checked : colours.field_border)};
    position: absolute;
    top: 17px;
    left: 17px;
  }

  &::after {
    content: "";
    display: block;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 23px;
    left: 23px;
    background-color: ${(props) =>
      props.checked ? colours.radio_border_checked : "transparent"};
  }
`;

const RadioFreeTextStyled = styled.div`
  margin-left: 56px;

  & h2 {
    font-size: 16px;
  }
  & h3 {
    color: ${colours.natWestPurple}; 
  }
  & label {
    width: 90%;
    font-size: 14px;
  }
  & input {
    width: 90%;
  }
`;

const RadioWrapper = styled.div`
  @media ${media.tablet} {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
`;

const RadioWrapperInner = styled.div`
  @media ${media.tablet} {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 20px 0 50px;
  }

  h2 {
    margin-top: 0;
  }
`;

export const RadioFreeText = ({ choice, handleChange }) => {
  return (
    <RadioFreeTextStyled>
      <FreeText option={choice} handleChange={handleChange} />
    </RadioFreeTextStyled>
  )
}

const Radio = ({ choiceId, option, handleChange }) => {
  const context = useContext(QuoteContext);
  const bSelected = option.value===context.value;
  const [hasFocus, setHasFocus] = useState(false);
  const bOutline = useOutline();
  const spacePress = useKeyPress(' ');
  if (spacePress && !bSelected && hasFocus) handleChange({choiceId: choiceId, choiceVal: option.value});

  return (

    <RadioStyled checked={bSelected} outline={bOutline} layout={option.layout} tabIndex={1} onFocus={() => setHasFocus(true)} onBlur={() => setHasFocus(false)} >
      <RadioButton checked={bSelected} onClick={() => handleChange({choiceId: choiceId, choiceVal: option.value})} >{option.label}</RadioButton>
      {
        bSelected && 
        option.radioFreetext && 
        option.radioFreetext.map(( obj, i ) => {
          if (obj.options && obj.options.length) {
            return (
              <QuoteContext.Provider key={`subchoice_${i}`} value={{...subContext(obj.id, context)}}>
                <RadioWrapperInner>
                  {obj.title && <h2>{obj.title}</h2>}
                  {obj.description && <label>{obj.description}</label>}
                  {obj.options.map(( subObj, i ) => {
                    return <Radio key={`radio_${i}`} choiceId={obj.id} option={subObj} handleChange={handleChange} />
                  })}
                </RadioWrapperInner>
              </QuoteContext.Provider>
            );
          }

          if (obj.condition) { 
            if (!obj.condition.operator && context.chosen[obj.condition.key].value !== obj.condition.value) {
              return null;
            }
            if (obj.condition.operator === '!=' && context.chosen[obj.condition.key].value === obj.condition.value) {
              return null;
            }
          }

          return (<QuoteContext.Provider key={`freetext_${i}`} value={{...subContext(obj.id, context)}}>
            <RadioFreeText choice={obj} handleChange={handleChange} />
          </QuoteContext.Provider>);
        })
      }
      {
        bSelected && 
        option.warning && 
        <div style={{padding: '0 20px 20px 20px'}}><Warning>{option.warning.label}</Warning></div>
      }
    </RadioStyled>
  )
}

export default Radio;
