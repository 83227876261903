import React from 'react';

import { initValues } from 'resources/constants';
import Quote from './Quote';

const Retrieve = () => {
  let context = localStorage.getItem('retrieve');
  // console.log('[Retrieve] context', context);
  
  if (context) {
    context = JSON.parse(context);

    const getValue = (field) => {
      if (context[field] === null) return null;
      if (context[field] === true) return 'yes';
      if (context[field] === false) return 'no';
      if (!isNaN(context[field])) return String(context[field]);
      return context[field];
    }
    const sectorId = getValue('sectorId');
    let chosen = {
      'id.product': { value: 0, valid: true },
      'id.quote': { value: getValue('quoteId'), valid: true },
      'loan.amount': { value: getValue('amount'), valid: true },
      'loan.term': { value: getValue('term'), valid: true },
      'loan.purpose': { value: getValue('loanPurpose'), valid: true },
      'loan.secured': { value: getValue('loanToBeSecuredVia'), valid: true },
      'loan.secured.property_owned': { value: getValue('isPropertyOwnedByTheBusiness'), valid: true },
      'loan.secured.property_value': { value: getValue('valueOfPropertyToBeOfferedAsSecurity'), valid: true },
      'loan.secured.property_purchase': { value: getValue('purchasePriceOfPropertyToBeOfferedAsSecurity'), valid: true },
      'loan.secured.other_asset': { value: getValue('assetToBeOfferedAsSecurity'), valid: true },
      'business.sector': { value: Number(sectorId) > 10 ? '2' : sectorId, valid: true },
      'business.sector.leisure': { value: Number(sectorId) > 10 ? sectorId : null, valid: true },
      'business.sector.consolidated': { value: sectorId, valid: true },
      'business.trading': { value: getValue('lengthOfTrading'), valid: true },
      'business.entity': { value: getValue('clientLegalBusinessEntity'), valid: true },
      'business.financial': { value: getValue('isClientFinancialInformationKnown'), valid: true },
      'business.financial.ebitda': { value: getValue('ebitda'), valid: true },
      'business.financial.director_remuneration': { value: getValue('totalDirectorsRemuneration'), valid: true },
      'business.financial.existing_debt': { value: getValue('existingDebt'), valid: true },
      'business.financial.gross_rent': getValue('grossRent') ? { value: getValue('grossRent'), valid: true } : null,
    }
  
    if (getValue('isClientFinancialInformationKnown') === 'yes') {
      chosen['business.financial.useCalculator'] = { value: 'no', valid: true };

      if (getValue('clientLegalBusinessEntity') === 'ltd' && chosen['business.financial.useCalculator'].value !== 'no') {
        chosen['business.financial.director_remuneration'] = { value: 0, valid: true };
      }
    }
  
    localStorage.removeItem('retrieve');

    return <Quote isRetrieved={true} initChosen={Object.assign({}, initValues, chosen)} />
  }
  return null;
}

export default Retrieve;
