import React, { useState } from "react";
import styled from "styled-components";

import { companiesHouse, regExp } from "resources/constants";
import { fetchPlus, validFormat } from "resources/utils";

import { colors } from "../theming/variables";
import FieldInput from "../components/FieldInput";

const PopupList = styled.ul`
  padding: 0;
  background-color: ${colors.lightGrey};
  width: 90%;
  max-height: 310px;
  margin-top: 0px;
  overflow-y: auto;

  & li {
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    color: ${colors.text};
    cursor: pointer;
    list-style: none;

    &:hover {
      color: white;
      background-color: ${colors.mediumGreyBorder};
    }
  }
`;

const Company = ({ valid, onChange, value, onBlur, className }) => {
  const [list, setList] = useState([]);
  const [text, setText] = useState(value);

  const handleTextChange = async (newVal, bLookup) => {
    setList([]);
    setText(newVal);

    if (bLookup && validFormat(newVal, regExp.companyNumber)) {
      // only lookup if it's a valid company number
      const url = `${companiesHouse.url}?q=${newVal}&items_per_page=10`;
      const response = await fetchPlus(url, false, null, {
        headers: {
          Authorization: `Basic ${companiesHouse.authorization}`,
        },
      });

      if (response.json && response.json.items) {
        setList(response.json.items);
      }
    } else {
      onChange(newVal);
    }
  };

  const handleItemSelect = (newVal) => {
    setList([]);
    setText(newVal);
    onChange(newVal);
    onBlur();
  };

  return (
    <>
      <FieldInput
        value={text}
        onChange={(evt) => handleTextChange(evt.target.value, true)}
        valid={valid}
        maxLength="255"
        class={className}
        onBlur={onBlur}
      />
      {!!list.length && (
        <PopupList>
          {list.map((obj, i) => {
            const label = `${obj.title}, ${obj.address_snippet}`;
            return (
              <li
                key={`address_${i}`}
                onClick={() => handleItemSelect(label, false)}
              >
                {label}
              </li>
            );
          })}
        </PopupList>
      )}
    </>
  );
};

export default Company;
