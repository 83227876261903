import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import { getAddress } from 'resources/constants';
import { fetchPlus, subContext } from 'resources/utils';
import { colours, TextLink, Input } from 'resources/global-styles';
import { QuoteContext } from '_Broker/pages/Quote';
import NotValidated from '_Broker/structure/NotValidated';

import Choice from '_Broker/quote/Choice';

const PopupList = styled.ul`
  padding: 0;
  background-color: ${colours.lightGrey};
  width: 100%;
  max-height: 310px;
  margin-top: -20px;
  overflow-y: scroll;

  & li {
    width: 100%;
    padding: 20px 20px 0 20px;
    color: ${colours.natWestPurple};  
    cursor: pointer;
    list-style: none;
  }
`

const Address = ({ choiceId, handleChange }) => {
  const context = useContext(QuoteContext);
  const [list, setList] = useState([]);
  const [showFields, setShowFields] = useState(context['refer.client.postcode'] && context['refer.client.postcode'].value);

  const handleLookupChoice = async (newAddress) => {
    const arrAddress = newAddress.split(',');

    if (arrAddress.length === 7) {
      handleChange([
        {choiceId: 'refer.client.address1', choiceVal: arrAddress[0], isValid: true},
        {choiceId: 'refer.client.address2', choiceVal: arrAddress[1], isValid: true},
        {choiceId: 'refer.client.town', choiceVal: arrAddress[5], isValid: true},
        {choiceId: 'refer.client.postcode', choiceVal: context['refer.client.postcodeLookup'].value, isValid: true},
      ]);
      setShowFields(true);
    }
  }

  const handleLookupChange = async (newVal) => {
    setList([]);
    handleChange({choiceId: choiceId, choiceVal: newVal});

    if (newVal.length > 4) {
      const url = `${getAddress.url}${newVal}?api-key=${getAddress.key}`;
      const response = await fetchPlus(url, false);

      if (response.json && response.json.addresses) {
        setList(response.json.addresses);
      }
    }
  }

  const validWarning = () => {
    return null;
  }

  if (showFields) return (
    <>
      <QuoteContext.Provider value={{...subContext('refer.client.address1', context)}}>
        <Choice choice={{id: ['refer.client.address1']}} handleChange={handleChange} />
      </QuoteContext.Provider>
      <QuoteContext.Provider value={{...subContext('refer.client.address2', context)}}>
        <Choice choice={{id: ['refer.client.address2']}} handleChange={handleChange} />
      </QuoteContext.Provider>
      <QuoteContext.Provider value={{...subContext('refer.client.town', context)}}>
        <Choice choice={{id: ['refer.client.town']}} handleChange={handleChange} />
      </QuoteContext.Provider>
      <QuoteContext.Provider value={{...subContext('refer.client.postcode', context)}}>
        <Choice choice={{id: ['refer.client.postcode']}} handleChange={handleChange} />
      </QuoteContext.Provider>
      <TextLink onClick={() => setShowFields(false)}>Search for another postcode</TextLink>
    </>
  )

  return (
    <>
      <label>Type your client's business postcode</label>
      <Input
        value={context[choiceId].value ? context[choiceId].value.toUpperCase() : ''}
        onChange={(evt) => handleLookupChange(evt.target.value)}
        onBlur={() => handleChange(choiceId)}
        valid={context[choiceId] ? context[choiceId].valid : undefined}
        maxLength='10'
      />
      <PopupList>
        {!!list.length && list.map((address, i) => (
          <li key={`address_${i}`} onClick={() => handleLookupChoice(address)}>{address.replace(/,{2,}/g,',')}</li>
        ))}
      </PopupList>
      {/* {validWarning()} */}
      <TextLink onClick={() => setShowFields(true)}>Enter address manually</TextLink>
    </>
  )
}


export default Address;
