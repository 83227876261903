import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import { colours } from 'resources/global-styles';
import { QuoteContext } from '_Broker/pages/Quote';

const Div = styled.div`
  position: relative;
`
const Button = styled.button`
  width: 91%;
  border: none;
  padding: 15px 20px;
  font-size: 18px;
  text-align: left;
  background-color: ${colours.lightGrey};
  position: relative;

  &::after {
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -8px;
    display: inline-block;
    content: "";
    font-family: nw-icons;
    -webkit-transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    -webkit-transform: ${props => props.title==='open' ? 'rotate(-90deg)' : 'rotate(90deg)'};
    transform: ${props => props.title==='open' ? 'rotate(-90deg)' : 'rotate(90deg)'};
  }
`
const Ul = styled.ul`
  width: 91%;
  padding: 0;
  list-style-type: none;
  margin-bottom: 20px;
  background-color: ${colours.lightGrey};
  position: absolute;
  overflow-y: scroll;
  z-index: 1;
  max-height: 300px;
  margin-top: 1px;
`
const Li = styled.li`
  display: block;
  text-decoration: none;
  padding: 15px;
  color: ${colours.natWestPurple};
`

const DropDown = ({ choice, handleChange }) => {
  const [open, setOpen] = useState(false);
  const context = useContext(QuoteContext);

  const handleNewChoice = (id) => {
    handleChange({choiceId: choice.id, choiceVal: id});
    setOpen(false);
  }
  
  return (
    <Div>
      <Button onClick={() => setOpen(!open)} title={open ? 'open' : ''}>{context.value ? choice.options.find((obj) => obj.value === context.value).label : choice.title}</Button>
      {open && <Ul>
        {choice.options && choice.options.map(( obj, i ) => (
          <Li key={`dropdownchoice_${i}`} onClick={() => handleNewChoice(obj.value)}>{obj.label}</Li>
        ))}
      </Ul>}
    </Div>
  )
}

export default DropDown;
