import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';

import { ReactComponent as EditIcon } from 'resources/images/edit_purple.svg';
import { RoundedDiv, Button, colours, media, TextLink } from 'resources/global-styles';
import { formatter } from 'resources/constants';
import { validAmount, validTerm, debugConsole } from 'resources/utils';
import { trackButtonClick } from 'resources/analyticsHelper';

import { QuoteContext } from '_Broker/pages/Quote';
import FreeText from '_Broker/quote/Choice/FreeText';


const Div = styled(RoundedDiv)`
  margin: 36px 0 20px 0;
  padding: 0 25px;
  cursor: pointer;
  background: ${colours.product_loan_bg};

  @media ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media ${media.desktop} {
    flex-wrap: nowrap;
  }
`
const TextLinkStyled = styled(TextLink)`
  margin: 0 0 20px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;

  @media ${media.tablet} {
    margin: auto 0;
  }
`
const SaveButton = styled(Button)`
  width: 100%;
  margin-bottom: 0;

  @media ${media.tablet} {
    width: auto;
    margin: 46px 0 20px 0;
    padding: 0px 20px;
  }
`
const CancelButton = styled(Button)`
  width: 100%;
  background-color: ${colours.btn_prim_bg};
  color: ${colours.btn_prim_text};
  border: none;
  margin-bottom: 0;

  @media ${media.tablet} {
    width: auto;
    margin: 46px 0 20px 0;
    padding: 0px 20px;
  }
`

const Loan = ({ handleChange }) => {
  const context = useContext(QuoteContext);
  const [edit, setEdit] = useState(false);
  const [amount, setAmount] = useState({value: context['loan.amount'].value, valid: context['loan.amount'].valid });
  const [term, setTerm] = useState({value: context['loan.term'].value, valid: context['loan.term'].valid });

  const handleEdit = () => {
    trackButtonClick('Button', 'Edit');
    
    setEdit(true);
  }
  const handleAmountChange = (updateParam) => {
    switch (updateParam.constructor) {
      case String:
        break;
      case Object:
        setAmount({value: updateParam.choiceVal, valid: validAmount(updateParam.choiceVal)});
        break;
      default:
        debugConsole('Bad parameter on handleAmountChange', updateParam);
        throw new Error(`Bad parameter on handleAmountChange: ${JSON.stringify(updateParam)}`);
    }
  }
  const handleTermChange = (updateParam) => {
    switch (updateParam.constructor) {
      case String:
        break;
      case Object:
        setTerm({value: updateParam.choiceVal, valid: validTerm(updateParam.choiceVal, context.dynamic.max_term)});
        break;
      default:
        debugConsole('Bad parameter on handleTermChange', updateParam);
        throw new Error(`Bad parameter on handleTermChange: ${JSON.stringify(updateParam)}`);
    }
  }
  const handleSave = () => {
    if (amount.valid && term.valid) {
      handleChange([{
        choiceId: 'loan.amount', 
        choiceVal: amount.value, 
        isValid: true
      }, {
        choiceId: 'loan.term', 
        choiceVal: term.value, 
        isValid: true
      }]);
      setEdit(false);
    }
  }
  const handleCancel = () => {
    setAmount({value: context['loan.amount'].value, valid: context['loan.amount'].valid });
    setTerm({value: context['loan.term'].value, valid: context['loan.term'].valid });
    setEdit(false);
  }

  if (!edit) return (
    <Div onClick={handleEdit}>
      <p>You requested a loan amount of <b>{formatter.format(amount.value)}</b> over a term of <b>{term.value} years</b></p>
      <TextLinkStyled>
        <div className="editicon"><EditIcon /></div>
        <div>Edit</div>
      </TextLinkStyled>
    </Div>
  );

  return (
    <Div>
      <QuoteContext.Provider value={amount}>
        <FreeText option={{description: 'Loan amount', type: 'currency', id: 'loan.amount', layout: 'fullwidth'}} handleChange={handleAmountChange} />
      </QuoteContext.Provider>
      <QuoteContext.Provider value={term}>
        <FreeText option={{description: 'Loan term', type: 'time', id: 'loan.term', layout: 'fullwidth'}} handleChange={handleTermChange} />
      </QuoteContext.Provider>
      <SaveButton onClick={handleSave}>Save</SaveButton>
      <CancelButton onClick={handleCancel}>Cancel</CancelButton>
    </Div>
  );
}

export default Loan;
