import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Form, Field, FormSpy } from "react-final-form";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { colors } from "../../../theming/variables";
import { ReactComponent as EditIcon } from "resources/images/edit_purple.svg";
import Warning from "../../../components/Warning";
import { TextArea, ErrorMessage, Button } from "../../../theming/common";
import PrevNextButtons from "../../../components/PrevNextButtons";
import BrokerDetailsFields from "../../../components/BrokerDetailsFields";
import { useLombardContext } from "../../../context/lombardContext";
import Checkbox from "../../../components/Checkbox";
import AdditionalAssetDetails from "./AdditionalAssetDetails";
import ClientDetailsFields from "./ClientDetails";
import SupplierDetailsFields from "./SupplierDetails";
import ApiFetchService from "../../../utils/dataFetching";
import { useEffect } from "react";
import { trackFieldInput, trackRadioButtonSelection, trackValidationError } from "resources/analyticsHelper";
import { colours } from 'resources/global-styles';

const ButtonContainer = styled.div`
  width: 100%;
  text-align: right;
  margin: 1.5rem 0;
`;

const EditIconContainer = styled.div`
  color: ${colours.warning_link};
  flex-grow: 1;
  text-align: end;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;

  svg {
    width: 32px;
    height: 32px;
    margin-right: 0.25rem;
  }
`;

const SuccessImage = styled.img`
  margin-left: 1.5rem;
`;

const DownloadIcon = styled.div`
  background-image: url("/images/download_icon.png");
  width: 24px;
  height: 24px;
  background-size: contain;
  float: left;
  margin-right: 0.5rem;
`;

const PrivacyConfirmedOption = {
  value: true,
  label:
    "I confirm that I've provided my client with a copy of your privacy notice and have consent to share their information.",
};

let accordionKeys = [
  "broker",
  "client",
  "additionaldetails",
  "supplierdetails",
];

const isExpanded = (expandedItems, key) => {
  return expandedItems.indexOf(key) !== -1;
};

const formMutators = {
  setAddress: (args, state, utils) => {
    utils.changeValue(state, "address", () => {
      const selectedAddress = args[0];
      return {
        ...selectedAddress,
      };
    });
  },
};

let firstRenderValidationState = {};

const SupportingDetails = () => {
  const [privacyNoteConfirmed, setPrivacyNoteConfirmed] = useState(false);
  const { state, actions } = useLombardContext();
  const initialValues = state.supportingDetails;
  const assetsNumber = state.quote.assetDetails.numberOfAssets;
  const vatDefer = state.quote.assetDetails.vatDefer;
  const [expandedAccordionItems, setExpandedAccordionItems] = useState([
    "broker",
  ]);

  const [formsValidationStatus, setFormsValidationStatus] = useState({});
  const [showPrivacyNoteError, setShowPrivacyNoteError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = useCallback(
    (values, form) => {
      const newExpandedItemsArray = [...expandedAccordionItems];
      const formKey = values.formKey;
      const itemToExpand = accordionKeys[accordionKeys.indexOf(formKey) + 1];

      newExpandedItemsArray.splice(newExpandedItemsArray.indexOf(formKey), 1);
      if (newExpandedItemsArray.indexOf(itemToExpand) === -1) {
        newExpandedItemsArray.push(itemToExpand);
      }
      setExpandedAccordionItems([...newExpandedItemsArray]);

      if (form.getState().valid) {
        updateFormsValidationStatus(formKey, true, values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expandedAccordionItems]
  );

  const updateFormsValidationStatus = (formKey, valid, values) => {
    if (formsValidationStatus) {
      let newValidationState = Object.assign({}, formsValidationStatus);
      newValidationState[formKey] = valid;
      setFormsValidationStatus(newValidationState);
    } else {
      let newValidationState = {};
      newValidationState[formKey] = valid;
      setFormsValidationStatus(newValidationState);
    }
    
    actions.setSupportingDetails(formKey, values);
  };

  const handleBackClick = useCallback(() => {
    actions.setStage(state.quote.activeStageId - 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.quote.activeStageId]);

  const updateAccordionItemExpandedState = (key) => {
    const newExpandedItemsArray = [...expandedAccordionItems];
    if (newExpandedItemsArray.indexOf(key) === -1) {
      newExpandedItemsArray.push(key);
    } else if (formsValidationStatus && formsValidationStatus[key]) {
      newExpandedItemsArray.splice(newExpandedItemsArray.indexOf(key), 1);
    }
    setExpandedAccordionItems([...newExpandedItemsArray]);
  };

  const buildAssetDetailsStages = useCallback(() => {
    let detailStages = [];
    accordionKeys = ["broker", "client"];
    for (let i = 1; i <= assetsNumber; i++) {
      accordionKeys.push(`assetdetails_${i}`);

      detailStages.push(
        <AdditionalAssetDetails
          key={`assetdetails_${i}`}
          assetIndex={i}
          onSubmit={onSubmit}
          isExpanded={isExpanded(expandedAccordionItems, `assetdetails_${i}`)}
          updateAccordionItemExpandedState={() => {
            updateAccordionItemExpandedState(`assetdetails_${i}`);
          }}
          handleFormSpyOnChange={handleFormSpyOnChange}
          formsValidationStatus={formsValidationStatus}
        />
      );
    }

    accordionKeys.push("additionaldetails");
    accordionKeys.push("supplierdetails");

    return detailStages;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsNumber, onSubmit, formsValidationStatus]);

  const handleSubmitToLombardClick = useCallback(() => {
    if (isSubmitting) {
      return;
    }

    if (!privacyNoteConfirmed) {
      setShowPrivacyNoteError(true);
      return;
    }

    const newExpandedItemsArray = [...expandedAccordionItems];
    let allRequiredFormsAreValid = true;
    for (let key in formsValidationStatus) {
      if (key !== "additionaldetails" && key !== "supplierdetails") {
        allRequiredFormsAreValid =
          allRequiredFormsAreValid && formsValidationStatus[key];

        if (
          !formsValidationStatus[key] &&
          newExpandedItemsArray.indexOf(key) === -1
        ) {
          newExpandedItemsArray.push(key);
        }
      }
    }

    if (!allRequiredFormsAreValid) {
      const submitButtons = document.querySelectorAll("button[type=submit]");
      for (let i = 0; i < submitButtons.length; i++) {
        submitButtons[i].click();
      }
    }

    if (allRequiredFormsAreValid) {
      setIsSubmitting(true);

      ApiFetchService.SubmitFinanceApplication(
        state.quoteSummary.quoteId,
        state.supportingDetails,
        state.prevReferenceNumber
      )
        .then((data) => {
          actions.setStage(state.quote.activeStageId + 1);
          actions.addOrUpdatePageData("referenceNumber", data.referenceNumber);
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    }

    setExpandedAccordionItems([...newExpandedItemsArray]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    privacyNoteConfirmed,
    formsValidationStatus,
    state.supportingDetails,
    isSubmitting,
  ]);

  const handleFormSpyOnChange = (state) => {
    const { valid, values } = state;
    if (!state.pristine) {
      updateFormsValidationStatus(values.formKey, valid, values);
    } else {
      if (firstRenderValidationState) {
        let newValidationState = Object.assign({}, firstRenderValidationState);
        newValidationState[values.formKey] =
          valid && Object.keys(values).length > 1;
        firstRenderValidationState = newValidationState;
      } else {
        let newValidationState = {};
        newValidationState[values.formKey] =
          valid && Object.keys(values).length > 1;
        firstRenderValidationState = newValidationState;
      }
    }
  };

  useEffect(() => {
    let firstOpened = false;
    const newExpandedItemsArray = [...expandedAccordionItems];
    for (let key in firstRenderValidationState) {
      if (firstRenderValidationState[key] === false) {
        if (firstOpened === false) {
          firstOpened = true;
          if (expandedAccordionItems.indexOf(key) === -1) {
            newExpandedItemsArray.push(key);
          }
        }
      } else {
        newExpandedItemsArray.splice(newExpandedItemsArray.indexOf(key), 1);
      }
    }
    setFormsValidationStatus(firstRenderValidationState);
    setExpandedAccordionItems([...newExpandedItemsArray]);
  }, []);

  return (
    <>
      <h1>Supporting details</h1>
      <label>
        To send your quote to Lombard, we need some details about you, your
        client and assets
      </label>
      <Warning>
        Our Privacy Policy explains how we'll use the information you provide
        from yourself and your client. Please send a copy of our privacy notice
        to your client. It is your responsibility to only refer clients in line
        with the FCA permissions that you currently hold.
        <br />
        <br />
        <a href="https://www.lombard.co.uk/content/dam/lombard_co_uk/documents/application-privacy-notice.pdf">
          <DownloadIcon />
          Download privacy notice
        </a>
      </Warning>

      <Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
        <AccordionItem
          dangerouslySetExpanded={isExpanded(expandedAccordionItems, "broker")}
        >
          <AccordionItemHeading
            onClick={() => {
              updateAccordionItemExpandedState("broker");
            }}
          >
            <AccordionItemButton>
              <h3>Your details (Broker)</h3>
              {formsValidationStatus && formsValidationStatus["broker"] && (
                <>
                  <SuccessImage src="/images/input-valid-icon.svg" />
                  {!isExpanded(expandedAccordionItems, "broker") && (
                    <EditIconContainer>
                      <div className="editicon"><EditIcon /></div>
                      <div>Edit</div>
                    </EditIconContainer>
                  )}
                </>
              )}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Form
              key="broker"
              onSubmit={onSubmit}
              initialValues={{ formKey: "broker", ...initialValues["broker"] }}
              render={({ handleSubmit }) => (
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <input
                    autoComplete="off"
                    name="hiddenone"
                    type="text"
                    style={{ display: "none" }}
                  />
                  <FormSpy
                    subscription={{ values: true, valid: true, pristine: true }}
                    onChange={handleFormSpyOnChange}
                  />
                  <BrokerDetailsFields />
                  <ButtonContainer>
                    <Button type="submit">Next</Button>
                  </ButtonContainer>
                </form>
              )}
            />
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          dangerouslySetExpanded={isExpanded(expandedAccordionItems, "client")}
        >
          <AccordionItemHeading
            onClick={() => {
              updateAccordionItemExpandedState("client");
            }}
          >
            <AccordionItemButton>
              <h3>Client details</h3>
              {formsValidationStatus && formsValidationStatus["client"] && (
                <>
                  <SuccessImage src="/images/input-valid-icon.svg" />
                  {!isExpanded(expandedAccordionItems, "client") && (
                    <EditIconContainer>
                    <div className="editicon"><EditIcon /></div>
                    <div>Edit</div>
                  </EditIconContainer>
                  )}
                </>
              )}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Form
              key="client"
              onSubmit={onSubmit}
              mutators={formMutators}
              initialValues={{ formKey: "client", ...initialValues["client"] }}
              render={({ handleSubmit }) => (
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <input
                    autoComplete="off"
                    name="hidden"
                    type="text"
                    style={{ display: "none" }}
                  />
                  <FormSpy
                    subscription={{ values: true, valid: true, pristine: true }}
                    onChange={handleFormSpyOnChange}
                  />
                  <ClientDetailsFields />
                  <ButtonContainer>
                    <Button type="submit">Next</Button>
                  </ButtonContainer>
                </form>
              )}
            />
          </AccordionItemPanel>
        </AccordionItem>

        {buildAssetDetailsStages(assetsNumber, onSubmit)}

        <AccordionItem
          dangerouslySetExpanded={isExpanded(
            expandedAccordionItems,
            "additionaldetails"
          )}
        >
          <AccordionItemHeading
            onClick={() => {
              updateAccordionItemExpandedState("additionaldetails");
            }}
          >
            <AccordionItemButton>
              <h3>Additional details (optional)</h3>
              {formsValidationStatus &&
                formsValidationStatus["additionaldetails"] && (
                  <>
                    <SuccessImage src="/images/input-valid-icon.svg" />
                    {!isExpanded(
                      expandedAccordionItems,
                      "additionaldetails"
                    ) && (
                      <EditIconContainer>
                      <div className="editicon"><EditIcon /></div>
                      <div>Edit</div>
                    </EditIconContainer>
                    )}
                  </>
                )}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Form
              onSubmit={onSubmit}
              initialValues={{
                formKey: "additionaldetails",
                ...initialValues["additionaldetails"],
              }}
              render={({ handleSubmit }) => (
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <input
                    autoComplete="off"
                    name="hidden"
                    type="text"
                    style={{ display: "none" }}
                  />
                  <FormSpy
                    subscription={{ values: true, valid: true, pristine: true }}
                    onChange={handleFormSpyOnChange}
                  />
                  <Field name="additionalDetails">
                    {({ input, meta }) => (
                      <div>
                        {/* {(vatDefer === 'yes' && 
                        <label>
                          <strong>You have selected a 3month VAT deferral. Please enter the following details here.</strong><br />
                          1. Why the deferral is required (e.g. trading cycle, competitive position etc.)<br />
                          2. How the VAT will be repaid (e.g. normal trading revenues etc.)<br /><br />
                        </label>)} */}
                        <label>
                          Include any further details that you think could be
                          relevant to this referral. You can email files to us
                          later.
                        </label>
                        <TextArea {...input} type="text" tabIndex={1} />
                        {meta.error && meta.touched && (
                          <ErrorMessage>{meta.error}</ErrorMessage>
                        )}
                      </div>
                    )}
                  </Field>
                  <ButtonContainer>
                    <Button type="submit">Next</Button>
                  </ButtonContainer>
                </form>
              )}
            />
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          dangerouslySetExpanded={isExpanded(
            expandedAccordionItems,
            "supplierdetails"
          )}
        >
          <AccordionItemHeading
            onClick={() => {
              updateAccordionItemExpandedState("supplierdetails");
            }}
          >
            <AccordionItemButton>
              <h3>Supplier details (optional)</h3>
              {formsValidationStatus &&
                formsValidationStatus["supplierdetails"] && (
                  <>
                    <SuccessImage src="/images/input-valid-icon.svg" />
                    {!isExpanded(expandedAccordionItems, "supplierdetails") && (
                      <EditIconContainer>
                      <div className="editicon"><EditIcon /></div>
                      <div>Edit</div>
                    </EditIconContainer>
                    )}
                  </>
                )}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Form
              key="supplierdetails"
              onSubmit={onSubmit}
              mutators={formMutators}
              initialValues={{
                formKey: "supplierdetails",
                ...initialValues["supplierdetails"],
              }}
              render={({ handleSubmit }) => (
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <input
                    autoComplete="off"
                    name="hidden"
                    type="text"
                    style={{ display: "none" }}
                  />
                  <FormSpy
                    subscription={{ values: true, valid: true, pristine: true }}
                    onChange={handleFormSpyOnChange}
                  />
                  <SupplierDetailsFields />
                  <ButtonContainer>
                    <Button type="submit">Next</Button>
                  </ButtonContainer>
                </form>
              )}
            />
          </AccordionItemPanel>
        </AccordionItem>

        <div>
          <h3>Confirm and submit</h3>
          <Checkbox
            option={PrivacyConfirmedOption}
            value={privacyNoteConfirmed}
            handleChange={() => {
              if (!privacyNoteConfirmed) {
                setShowPrivacyNoteError(false);
              } else {
                trackValidationError(
                  'Please apply the confirmation to submit the form', 
                  `I confirm that I've provided my client with a copy of your privacy notice and have consent to share their information.`, 
                  'Supporting details'
                );
              }
              setPrivacyNoteConfirmed(!privacyNoteConfirmed);
            }}
          />
          {showPrivacyNoteError && (
            <ErrorMessage>
              Please apply the confirmation to submit the form
            </ErrorMessage>
          )}
        </div>
      </Accordion>
      <PrevNextButtons
        handleBackClick={handleBackClick}
        handleNextClick={handleSubmitToLombardClick}
        nextBtnText={
          <>
            {!isSubmitting && <>Submit to Lombard</>}
            {isSubmitting && <span className="loader-1"></span>}
          </>
        }
      />
    </>
  );
};

export default SupportingDetails;
