import React, { useRef, useContext, useState } from "react";
import { useTransition, useChain, animated, config } from "react-spring";
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'resources/images/close.svg';
import { zIndex } from 'resources/constants';
import { tableData } from "resources/utils";
import { media, colours, Modal } from 'resources/global-styles';
import { QuoteContext } from '_Broker/pages/Quote';

import PDF from './PDF';
import Details from './Details';

const AnimatedDiv = styled(animated.div)`
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background-color: ${colours.sidebar_bg};
  z-index: ${zIndex.sidebar};
  box-shadow: -1px 0 10px rgba(0, 0, 0, 0.25);
  padding: 30px;

  & h1 {
    color: ${colours.darkGrey};
    margin: 0;
  }

  @media ${media.tablet} {
    width: 600px;
  }
`
const Close = styled(CloseIcon)`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`

const Sidebar = ({ product, handleOpen, index }) => {
  const chosen = useContext(QuoteContext);
  const [ready, setReady] = useState(false);

  const sidebarRef = useRef();
  const transition = useTransition(true, null, {
    from: {
      transform: "translateX(100%)"
    },
    enter: {
      transform: "translateX(0)"
    },
    leave: {
      transform: "translateY(-100%)"
    },
    unique: true,
    config: config.slow,
    ref: sidebarRef,
    onRest: () => setReady(true),
  });

  useChain(
    [sidebarRef],
    [0]
  );

  return transition.map(({ item, key, props }) =>
    item ? (
      <Modal key={key} onClick={() => handleOpen(index)}>
        <AnimatedDiv style={props} onClick={(evt) => evt.stopPropagation()}>
          <Close onClick={() => handleOpen(index)} />
          <h1>{product.name}</h1>
          {!!product.fixedRateTermInYears && <p>
            Interest remains the same for the duration of the fixed term. Cashflows are fixed and certain during the fixed term, reducing if lump sum early payments are made.
          </p>}
          {!product.fixedRateTermInYears && <p>
            Interest rate will change in line with the bank's base rate. Your repayments will vary depending on the interest rate.
          </p>}
          <PDF product={product} chosen={chosen} start={ready} />
          <Details tableData={tableData(product, chosen)} />
        </AnimatedDiv>
      </Modal>
    ) : null
  );
};

export default Sidebar;
