/* IMPORTANT */
// Do not edit the src/index.js file directly...
// ...edit the home/index-live.js version instead and then copy that to src/index.js

import 'react-app-polyfill/ie11';
import 'core-js';
//import 'core-js/features/array';
//import 'core-js/features/object';
//import 'core-js/features/symbol';
//import 'core-js/features/iterator';
// Remove the above lines if analytics show no usage of IE11

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import App from 'App';
import * as serviceWorker from 'serviceWorker';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({dsn: "https://74366010c72d441bb9e13565ebdc9b68@sentry.io/1818743"});
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
