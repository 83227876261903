import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { media } from 'resources/global-styles';
import { pathVal } from 'resources/constants';

import LoanToValue from './LoanToValue';
import DebtServiceCoverage from './DebtServiceCoverage';
import FetchAppetite from './FetchAppetite';
import Warning from '_Broker/structure/Warning';

const Wrapper = styled.div`
  & div:first-child {
    margin-bottom: 20px;
  }

  @media ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    & div:first-child {
      margin-bottom: 0;
    }
  }
`;

const Appetite = ({ maxCost, quoteId, interestRateSensitised, handlePath }) => {
  const [ltvAppetite, setLTVAppetite] = useState({ path: pathVal.loading, value: 0 });
  const [dscAppetite, setDSCAppetite] = useState({ path: pathVal.loading, value: 0 });
  const newDscAppetite = useRef({});
  let myPath = pathVal.loading;
  if (ltvAppetite.path > pathVal.loading || dscAppetite.path > pathVal.loading) {
    handlePath(Math.max(ltvAppetite.path, dscAppetite.path));
  }

  const handleLTVPath = (path, value, reason) => {
    if (path !== ltvAppetite.path) {
      setLTVAppetite({ path: path, value: value, reason: reason  });
      if (dscAppetite.path > pathVal.loading) handlePath(Math.max(path, dscAppetite.path));
      else if (myPath > pathVal.loading) handlePath(Math.max(path, myPath));
      myPath = path;
    }
  }

  const handleDSCPath = (path, value, reason) => {
    if (path !== dscAppetite.path) {
      setDSCAppetite({ path: path, value: value, reason: reason });
      newDscAppetite.current = { path: path, value: value, reason: reason };

      if (ltvAppetite.path > pathVal.loading) handlePath(Math.max(path, ltvAppetite.path));
      else if (myPath > pathVal.loading) handlePath(Math.max(path, myPath));
      myPath = path;
    }
  }

  const loadLatestAppetite = () => {
    return newDscAppetite.current;
  }

  return (
    <>
      <Wrapper>
        <LoanToValue handlePath={handleLTVPath} />
        <DebtServiceCoverage monthlyCost={maxCost} interestRateSensitised={interestRateSensitised} handlePath={handleDSCPath} />
      </Wrapper>

      {ltvAppetite.path < pathVal.red &&
      dscAppetite.path < pathVal.red &&
        <Warning showInline>
          Broker Portal is for credit brokers and other intermediaries only. These quotes should not be directly distributed to the customer.
        </Warning>
      }

      {ltvAppetite.path > pathVal.loading && 
      dscAppetite.path > pathVal.loading &&
        <FetchAppetite 
          quoteId={quoteId} 
          ltvAppetite={ltvAppetite} 
          dscAppetite={newDscAppetite.current}
          loadData={loadLatestAppetite} 
        />
      }
    </>
  );
}

export default Appetite;
