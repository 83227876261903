import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { Button} from "../theming/common";
import { useAppContext } from "../../context/appContext";
import { useLombardContext } from "../context/lombardContext";
import StarRating from "../components/StarRating";
import { colours } from 'resources/global-styles';

const MainText = styled.label`
  font-size: 1rem;
`;

const StyledLabel = styled.label`
  a {
    color: ${colours.warning_link};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const attachmentEmail = "introducer@lombard.co.uk";

const QuoteReceived = () => {
  const { state } = useLombardContext();
  const email = state.supportingDetails.broker.email;
  const reference = state.referenceNumber;
  const history = useHistory();
  const { actions } = useLombardContext();
  const appContext = useAppContext();
  const appActions = appContext.actions;


  const handleBackToHomepageClick = () => {
    actions.clearContextState();
    appActions.clearContextState();
    history.push("/");
  }
  
  return (
    <>
      <h1>Thanks, we've received your {state.prevReferenceNumber ? 'edited' : ''} quote</h1>
      <MainText>
        A confirmation has been emailed to you at <strong>{email}</strong>
      </MainText>
      <br />
      <div>
        <MainText>The referral reference number is <strong>{reference}</strong></MainText>
        <MainText>Quote this number if contacting us directly</MainText>
      </div>
      <br />
      <StyledLabel>
        To send us any supporting files, email them to{" "}
        <a href={`mailto:${attachmentEmail}?subject=${reference}`}>
          {attachmentEmail}
        </a>{" "}
        with your quote referral reference number as the email subject.
      </StyledLabel>
      <br />
      <label>
        This is still an indicative quote, subject to satisfactory additional
        checks on the information you have provided, and as such may be subject
        to change once we confirm the full details of your request.
      </label>
      <h3>What happens next</h3>
      <label>
        {
          state.prevReferenceNumber 
            ? `We'll review the details of your edited quote and get in touch.`
            : `We'll review the details of your quote and assign a relationship manager to you.`
        }
      </label>
      <StarRating
        reference={reference}
        broker={state.supportingDetails.broker}
      />
    </>
  );
};

export default QuoteReceived;