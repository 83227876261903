import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Page from '_Broker/structure/Page';
import { media, Button, colours } from 'resources/global-styles';
import { constsNatWest } from 'resources/constants';
import { brokerAuth, optOutCookies } from 'resources/utils';
import { trackHomePage } from 'resources/analyticsHelper';

import Warning from '_Broker/structure/Warning';
import Footer from '_Broker/structure/Footer';
import { trackButtonClick } from 'resources/analyticsHelper';

import { ReactComponent as LogoIcon } from 'resources/images/natwest_purple_logo.svg';

const HomePage = styled.div`
  flex: 1 0 auto;

  @media ${media.tablet} {
    background-image: url('/images/start-graphic-new.svg');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 48%;
  }
  @media ${media.desktop} {
    background-size: 480px;
  }

  & h2 {
    margin: 0;
    color: ${colours.text_copy};
  }

  & ul {
    list-style-image: url('/images/tick-grey.svg');
    padding-left: 25px;
    margin-bottom: 36px;

    & li {
      padding: 8px 15px;
      color: ${colours.sub_text_copy};
    }
    & svg > g > g > g > use {
      fill: ${colours.darkGrey};
    }
  }
`
const Header = styled.header`
  margin-bottom: 20px;

  @media ${media.tablet} {
    width: 50%;
  }

  & h1 {
    margin-top: 30px;
    font-size: 46px;
    font-family: knile-bold;
    color: ${colours.title_copy};
    width: max-content;
  }

  p{
    color: ${colours.sub_text_copy};
  }
`
const LogoWrapper = styled.div`
  display: flex;
  padding-top: 38px;
  color: ${colours.natWestPurple};

  .separation{
    border-left: 1px solid ${colours.natWestPurple};
    height: 33px;
    align-self: center;
  }

  .broker{
    align-self: center;
    margin-left: 20px;
    color: ${colours.main_text_copy};
    font-size: 24px;
  }
`
const Logo = styled(LogoIcon)`
  width: 143px;
  height: 50px;
  margin-top: -5px;
  vertical-align: middle;
  margin-left: -23px;
`
const Lozenge = styled.div`
  display: inline-block;
  width: fit-content;
  height: 24px;
  line-height: 26px;
  border: 1px solid ${colours.natWestPurple};
  border-radius: 8px;
  padding: 0 16px;
  margin-left: 20px;
  text-align: center;
  font-size: 13px;
  vertical-align: text-bottom;
`

const StyledButton = styled(Button)`
min-width: 96px;
height: 50px;
border-radius: 25px;
margin-bottom: 24px;
`
const CookieMsg = styled.div`
  font-size: 18px;
  color: ${colours.sub_text_copy}
  margin: -20px 0 48px 0;
  padding-top: 40px;

  a {
    text-decoration: underline;
    color: ${colours.warning_link};
  }
`

const BrokerHome = () => {
  const authPath = `/${brokerAuth()}/quote`;

  if (optOutCookies()) return (
    <>
      <HomePage>
        <Page>
          <Header>
            <LogoWrapper>
              <div><Logo alt="NatWest" /></div>
              <div>Broker Portal</div> 
            </LogoWrapper>
            <h1>Broker lending check</h1>
            <h2>Cookies not accepted</h2>
            <p>Unfortunately, as this is a beta prototype you are unable to use the tool with cookies turned off. This is for us to give you the best experience and make continual improvements to the prototype.</p>
            <p><a target="_blank" rel="noopener noreferrer" href="https://www.natwest.com/global/cookieprivacy.ashx?channel=personal">To continue using the tool please set you preferences here</a></p>
          </Header>
        </Page>
      </HomePage>
      <Footer />
    </>
  );

  return (
    <>
      <HomePage>
        <Page>
          <Header>
            <LogoWrapper>
              <div><Logo alt="NatWest" /></div>
              <div className="separation"></div>
              <div className="broker">Broker Portal</div> 
            </LogoWrapper>
            <h1>Broker lending check</h1>
            <p>Welcome to our Broker Portal application. Use this tool to get a fast, indicative quote and to check our appetite for lending. Get a quote within 5 minutes for lending between £{constsNatWest.minAmount/1000}k and £{constsNatWest.maxAmount/1000}k.</p>
            <Warning showInline>Broker Portal is for credit brokers and other intermediaries only.</Warning>
          </Header>
          <h2><strong>Have the following information to hand to get started:</strong></h2>
          <ul>
            <li>Loan purpose, amount and term</li>
            <li>Details of any security to be offered</li>
            <li>Business sector and trading length</li>
            <li>EBITDA, director's remuneration and annual debt servicing cost</li>
          </ul>
          {authPath.length !== 12 && <Warning>Please use the unique access link sent to you via email</Warning>}
          {authPath.length === 12 &&
            <div>
              <Link to={authPath}>
                <StyledButton onClick={() => { trackButtonClick('Button', 'Get a quote'); }}>Get a quote</StyledButton>
              </Link>
              <CookieMsg>
                <Warning showBare>
                  You must ensure the options you select are in line with the FCA permissions that you currently hold.
                </Warning>
                <br/>
                <p>We use cookies and similar technologies on our websites and mobile applications to help provide you with the best possible online experience. By using our sites and apps, you agree that we may store and access cookies and similar technologies on your device.</p>
                <p><a target="_blank" rel="noopener noreferrer" href="https://www.natwest.com/global/cookieprivacy.ashx?channel=personal">You can find out more and set your own preferences here</a></p>
              </CookieMsg>
            </div>
          }
        </Page>
      </HomePage>
      <Footer />
    </>
  );
}

export default BrokerHome;
