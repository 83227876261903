import React from 'react';
import { Redirect } from 'react-router-dom';

import { useFetch } from 'resources/hooks';
import Warning from '_Broker/structure/Warning';

const NewPassword = ({ email, password, token }) => {
  const { loading, error, data } = useFetch('Passwords/reset', true, {
    email: email,
    newPassword: password,
    token: token,
  });

  if (loading) return <p>[loading]</p>
  if (error && error.title) return <Warning>{error.title}: {Object.values(error.errors).map(err => err)}</Warning>
  if (error) return <Warning>{error}</Warning>
  if (data) return <Redirect to='/admin' />
  return null;
}

export default NewPassword;
