import React from "react";
import { Field } from "react-final-form";
import {
  emailValidator,
  phoneValidator,
} from "../../../utils/validators";
import { isFieldValidAndTouchedAndNotEmpty } from "../../../utils/form";
import {
  ErrorMessage
} from "../../../theming/common";
import FieldInput from "../../../components/FieldInput";
import PostcodeLookUp from "../../../components/AddressSelect";
import { trackFieldInput, trackRadioButtonSelection, trackValidationError } from "resources/analyticsHelper";

const SupplierDetails = () => {

  
  const onBlurFieldHandler = (e, fieldLabel, meta) => {
    // console.log(`>>> onBlurFieldHandler`, e.name, e.value, fieldLabel, e);
    // trackFieldInput(fieldLabel, e.value + (postfix || ''));

    if (meta && meta.error) {
      const errMsg = meta.error;
      trackValidationError(errMsg, fieldLabel, 'Supporting details')
    }    
  };


  return (
    <>
      <Field name="companyName">
        {({ input, meta }) => (
          <div>
            <label>Supplier's company name</label>
            <FieldInput
              {...input}
              valid={isFieldValidAndTouchedAndNotEmpty(meta, input.value)}
              type="text"
              class={meta.error && meta.touched ? 'error-border' : ''}
            />
          </div>
        )}
      </Field>
      <Field name="contactName">
        {({ input, meta }) => (
          <div>
            <label>Supplier's contact name</label>
            <FieldInput
              {...input}
              valid={isFieldValidAndTouchedAndNotEmpty(meta, input.value)}
              type="text"
              class={meta.error && meta.touched ? 'error-border' : ''}
            />
          </div>
        )}
      </Field>
      <Field name="phoneNumber" validate={phoneValidator}>
        {({ input, meta }) => (
          <div>
            <label>Supplier's phone number</label>
            <FieldInput
              {...input}
              valid={isFieldValidAndTouchedAndNotEmpty(meta, input.value)}
              type="text"
              class={meta.error && meta.touched ? 'error-border' : ''}
              onBlur={() => { input.onBlur(); onBlurFieldHandler(input, `Supplier's phone number`, meta); }}
            />
            {meta.error && meta.touched && (
              <ErrorMessage>{meta.error}</ErrorMessage>
            )}
          </div>
        )}
      </Field>
      <Field name="email" validate={emailValidator}>
        {({ input, meta }) => (
          <div>
            <label>Supplier's email address</label>
            <FieldInput
              {...input}
              valid={isFieldValidAndTouchedAndNotEmpty(meta, input.value)}
              type="email"
              class={meta.error && meta.touched ? 'error-border' : ''}
              onBlur={() => { input.onBlur(); onBlurFieldHandler(input, `Supplier's email address`, meta); }}
            />
            {meta.error && meta.touched && (
              <ErrorMessage>{meta.error}</ErrorMessage>
            )}
          </div>
        )}
      </Field>
      <Field name="address">
            {({ input, meta }) => (
              <div>
                <label>Supplier's address</label>
                <small>Type the supplier's postcode</small>
                <div>
                  <PostcodeLookUp {...input} 
                  class={meta.error && meta.touched ? 'error-border' : ''} />
                </div>
              </div>
            )}
          </Field>
    </>
  );
};

export default SupplierDetails;
