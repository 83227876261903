import React, { useCallback, useState } from "react";
import { Form, Field, FormSpy } from "react-final-form";
import { useHistory } from "react-router";

import { useAppContext } from "../../context/appContext";
import { brokerAuth } from "../../resources/utils";
import { products } from "../../resources/constants";

import BrokerDetailsFields from "../components/BrokerDetailsFields";
import FieldInput from "../components/FieldInput";
import Page from "../components/Page";
import PrevNextButtons from "../components/PrevNextButtons";
import { useLombardContext } from "../context/lombardContext";
import { H3, TextArea } from "../theming/common";
import ApiFetchService from "../utils/dataFetching";
import { isFieldValidAndTouchedAndNotEmpty } from "../utils/form";

const UnableToProcessPage = () => {
  const getStartedLink = `/${brokerAuth()}/getstarted`;
  const { state, actions } = useLombardContext();
  const appContext = useAppContext();
  const appActions = appContext.actions;
  const data = appContext.data;
  const pageData = state.unableToProceedPage;
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = useCallback(
    (values) => {
      if (isSubmitting) {
        return;
      }

      setIsSubmitting(true);
      const brokerIdentifier = brokerAuth();

      ApiFetchService.PostContactUs({
        ...values,
        productLookingFor: data.selectedProduct ? data.selectedProduct : products.options[0].value,
      }, state.quote)
        .then(() => {
          actions.clearContextState();
          appActions.clearContextState();
          history.push(`/${brokerIdentifier}/lombard/thankyou`);
        })
        .catch((error) => {
          console.error(error.message);
          setIsSubmitting(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSubmitting]
  );

  return (
    <Page>
      <h1>Unfortunately we're unable to provide an online quote</h1>
      <div>
        Enter your details below and a broker business development manager will
        get in touch to discuss your client's borrowing needs.
      </div>

      <Form
        onSubmit={onSubmit}
        initialValues={pageData}
        render={({ handleSubmit }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <input
              autoComplete="off"
              name="hidden"
              type="text"
              style={{ display: "none" }}
            />
            <FormSpy
              subscription={{ values: true, pristine: true }}
              onChange={(state) => {
                if (!state.pristine) {
                  const { values } = state;
                  actions.addOrUpdatePageData("unableToProceedPage", values);
                }
              }}
            />
            <h3>Your details (Broker)</h3>
            <BrokerDetailsFields />
            <h3>Client details (optional)</h3>
            <Field name="clientFirstName">
              {({ input, meta }) => (
                <div>
                  <label>Client's first name</label>
                  <FieldInput
                    {...input}
                    valid={isFieldValidAndTouchedAndNotEmpty(meta, input.value)}
                    type="text"
                  />
                </div>
              )}
            </Field>
            <Field name="clientLastName">
              {({ input, meta }) => (
                <div>
                  <label>Client's last name</label>
                  <FieldInput
                    {...input}
                    valid={isFieldValidAndTouchedAndNotEmpty(meta, input.value)}
                    type="text"
                  />
                </div>
              )}
            </Field>
            <H3>Asset details (optional)</H3>

            <Field name="assetDetails">
              {({ input, meta }) => (
                <div>
                  <label>
                    Tell us about the asset, number of assets needed, make/model
                    if known
                  </label>
                  <TextArea {...input} type="text" tabIndex={1} />
                </div>
              )}
            </Field>
            <PrevNextButtons
              backLink={getStartedLink}
              nextBtnText={
                <>
                  {!isSubmitting && <>Submit</>}
                  {isSubmitting && <span className="loader-1"></span>}
                </>
              }
            />
          </form>
        )}
      />
    </Page>
  );
};

export default UnableToProcessPage;
