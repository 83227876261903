

// TODO: to be deprecated 
export const colors = {
    text: "black",
    primary: "#288090",
    header: "#001E69",
    lightGrey: "#f4f4f4",
    mediumGrey: "#dadada",
    mediumGreyBorder: "#c9c6c6",
    validGreen: "#429448",
    invalidRed: "#af2317",
}

export const media = {
    mobile: "(max-width: 576px)",
    tablet: "(min-width: 768px) and (max-width: 1024px)",
    desktop: "(min-width: 1024px)",
    maxWidth: "970px",
    max: "(min-width: 1067px)", // 970px, plus 5% margins
}

export const variables = {
    standardMargin: "1.5rem"
}