import React, { useState } from "react";

import { TextLink } from 'resources/global-styles';

import Form from "./Form";

const EditData = ({ type, auth }) => {
  const [open, setOpen] = useState(false);

  const labelText = type === 'Lombard Funding Costs' 
    ? type
    : type === 'Base Configs'
      ? 'Natwest Base Rate and UIR Rate' 
      : `Natwest ${type}`;

  if (open) return (
    <>
      <TextLink onClick={() => setOpen(false)} style={{marginBottom: '20px'}}>{labelText}</TextLink>
      <Form type={type} auth={auth} />
    </>
  )

  return (
    <TextLink onClick={() => setOpen(true)} style={{marginBottom: '20px'}}>Edit {labelText}</TextLink>
  )
}

export default EditData;