import React, { useState } from "react";
import { Field, useForm } from "react-final-form";
import styled from "styled-components";
import { getAddress } from "resources/constants";
import { colors } from "../theming/variables";
import { TextLink } from "../theming/common";
import { parse } from "postcode";
import { fetchPlus } from "resources/utils";
import { ErrorMessage } from "../theming/common";
import { ukPostcodeValidator, requiredValidator } from "../utils/validators";
import {
  isFieldValidAndTouched,
  isFieldValidAndTouchedAndNotEmpty,
} from "../utils/form";
import FieldInput from "../components/FieldInput";
import { trackLombardApplicationStepName, trackRadioButtonSelection, trackValidationError } from "../../resources/analyticsHelper";

const chooseValidationMarker = (postcodeIsRequired, meta, value) => {
  return postcodeIsRequired ? isFieldValidAndTouched(meta) : isFieldValidAndTouchedAndNotEmpty(meta, value);
};

export default function PostcodeLookUp({ postcodeIsRequired, value, pageTitle, fieldLabel }) {
  const { mutators } = useForm();
  const [addresses, setAddresses] = useState([]);
  const [postCodeLookupError, setPostCodeLookupError] = useState(false);
  const [showAddressFields, setShowAddressFields] = useState(!!value);
  const [postCodeValue, setPostCodeValue] = useState();

  const formatPostcode = (value) => {
    const result = parse(String(value));
    return result?.postcode || value;
  };

  const handleItemSelect = (value) => {
    const selectedAddress = {
      addressLine1: `${value.line_1} ${value.line_2}`,
      addressLine2: `${value.line_3} ${value.line_4}`,
      city: value.town_or_city,
      postcode: postCodeValue,
    };
    mutators.setAddress(selectedAddress);
    setShowAddressFields(true);
  };

  const handleTextChange = async (newVal, bLookup) => {
    setPostCodeValue(newVal);
    if (bLookup && newVal.length > 5) {
      const url = `${getAddress.url}${newVal}?api-key=${getAddress.key}&expand=true`;
      const response = await fetchPlus(url, false);

      if (response.json && response.json.addresses) {
        setPostCodeValue(response.json.postcode);
        setAddresses(response.json.addresses);
        setPostCodeLookupError(false);
      } else {
        setPostCodeLookupError(true);
      }
    }
  };
  
  const onBlurFieldHandler = (fLabel, meta) => {
    // console.log(`>>> onBlurFieldHandler`, e.name, e.value, fieldLabel, e, meta);

    if (!pageTitle || !fieldLabel) {
      return;
    }

    if (meta && meta.error) {
      const errMsg = meta.error;
      trackValidationError(errMsg, fLabel || fieldLabel, pageTitle);
    }
  };

  return (
    <Container>
      {!showAddressFields && (
        <div>
          <Field
            key="address_postcode_initial"
            name="address.postcode"
            format={formatPostcode}
            validate={postcodeIsRequired ? requiredValidator : undefined}
          >
            {({ input, meta }) => (
              <>
                <FieldInput
                  {...input}
                  onChange={(evt) => handleTextChange(evt.target.value, true)}
                  maxLength="255"
                  valid={chooseValidationMarker(postcodeIsRequired, meta, input.value)}
                  onBlur={() => { input.onBlur(); onBlurFieldHandler(null, { error: 'Please enter postcode' }); }}
                />
                {meta.error && meta.touched && (
                  <ErrorMessage>Please enter postcode</ErrorMessage>
                )}
              </>
            )}
          </Field>

          {addresses.length > 0 && (
            <div className="addresses">
              <PopupList>
                {addresses.map((obj, i) => {
                  return (
                    <li
                      key={`address_${i}`}
                      onClick={() => handleItemSelect(obj)}
                    >
                      {`${obj.formatted_address
                        .filter((i) => i !== "")
                        .join(", ")}, ${postCodeValue}`}
                    </li>
                  );
                })}
              </PopupList>
            </div>
          )}
          <TextLink
            onClick={() => {
              setShowAddressFields(!showAddressFields);
            }}
          >
            Or enter address manually
          </TextLink>
          {postCodeLookupError && (
            <ErrorMessage>{`Sorry, we were unable to find addresses by ${postCodeValue} postcode. Please correct the postcode and try again, or enter address manually.`}</ErrorMessage>
          )}
        </div>
      )}

      {showAddressFields && (
        <>
          <Field name="address.addressLine1" validate={postcodeIsRequired ? requiredValidator : undefined}>
            {({ input, meta }) => (
              <div>
                <label>Address line 1</label>
                <FieldInput 
                  {...input} 
                  valid={chooseValidationMarker(postcodeIsRequired, meta, input.value)} 
                  onBlur={() => { input.onBlur(); onBlurFieldHandler('Address line 1', meta); }}
                />
                {meta.error && meta.touched && (
                  <ErrorMessage>{meta.error}</ErrorMessage>
                )}
              </div>
            )}
          </Field>
          <Field name="address.addressLine2">
            {({ input, meta }) => (
              <div>
                <label>Address line 2</label>
                <FieldInput
                  label="Address line 2"
                  {...input}
                  valid={chooseValidationMarker(postcodeIsRequired, meta, input.value)}
                />
              </div>
            )}
          </Field>
          <Field name="address.city" validate={postcodeIsRequired ? requiredValidator : undefined}>
            {({ input, meta }) => (
              <div>
                <label>Town / City</label>
                <FieldInput
                  label="Town / City"
                  {...input}
                  valid={chooseValidationMarker(postcodeIsRequired, meta, input.value)}
                  onBlur={() => { input.onBlur(); onBlurFieldHandler('Town / City', meta); }}
                />
                {meta.error && meta.touched && (
                  <ErrorMessage>{meta.error}</ErrorMessage>
                )}
              </div>
            )}
          </Field>
          <Field
            key={"address_postcode_expanded"}
            name="address.postcode"
            validate={ukPostcodeValidator}
            format={formatPostcode}
          >
            {({ input, meta }) => (
              <div>
                <label>Postcode</label>
                <FieldInput
                  label="Postcode"
                  {...input}
                  valid={chooseValidationMarker(postcodeIsRequired, meta, input.value)}
                  onBlur={() => { input.onBlur(); onBlurFieldHandler('Postcode', meta); }}
                />
                {meta.error && meta.touched && (
                  <ErrorMessage>{meta.error}</ErrorMessage>
                )}
              </div>
            )}
          </Field>
          <TextLink
            onClick={() => {
              setShowAddressFields(!showAddressFields);
            }}
          >
            Use address look-up
          </TextLink>
        </>
      )}
    </Container>
  );
}

const PopupList = styled.ul`
  padding: 0;
  background-color: ${colors.lightGrey};
  width: 90%;
  max-height: 310px;
  margin-top: 0px;
  overflow-y: auto;

  & li {
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    color: ${colors.text};
    cursor: pointer;
    list-style: none;

    &:hover {
      color: white;
      background-color: ${colors.mediumGreyBorder};
    }
  }
`;

const Container = styled.div`
  margin-bottom: 1.5rem;
`;
