import { getAddress, companiesHouse, initValues } from "./constants";
import { debugConsole } from "./utils";

export const testSectors = {
  sectors: [
    {
      id: 1,
      name: "Manufacturing",
      margin: 0,
      loanToValueRatio: 75,
      subSectors: [],
    },
    {
      id: 2,
      name: "Leisure",
      margin: 0,
      loanToValueRatio: 75,
      subSectors: [
        {
          name: "Hotels or short stay accommodation",
          margin: 0,
          loanToValueRatio: 70,
          sectorParentId: 1,
          id: 11,
        },
        {
          name: "Pubs and bars",
          margin: 0,
          loanToValueRatio: 65,
          sectorParentId: 1,
          id: 12,
        },
        {
          name: "Restaurants (excluding takeaways)",
          margin: 0,
          loanToValueRatio: 65,
          sectorParentId: 1,
          id: 13,
        },
        {
          name: "Sporting activities",
          margin: 0,
          loanToValueRatio: 70,
          sectorParentId: 1,
          id: 14,
        },
      ],
    },
    {
      id: 3,
      name: "Retail and Wholesale",
      margin: 0,
      loanToValueRatio: 75,
      subSectors: [],
    },
    {
      id: 4,
      name: "Professional services",
      margin: 0,
      loanToValueRatio: 75,
      subSectors: [],
    },
    {
      id: 5,
      name: "Technology, Media and Telecommunications",
      margin: -0.25,
      loanToValueRatio: 75,
      subSectors: [],
    },
    {
      id: 6,
      name: "Building and construction",
      margin: 0,
      loanToValueRatio: 75,
      subSectors: [],
    },
    {
      id: 7,
      name: "Other",
      margin: 0,
      loanToValueRatio: 75,
      subSectors: [],
    },
    {
      id: 8,
      name: "Agriculture",
      margin: -0.25,
      loanToValueRatio: 75,
      subSectors: [],
    },
    {
      id: 9,
      name: "Healthcare",
      margin: 0,
      loanToValueRatio: 75,
      subSectors: [],
    },
    {
      id: 10,
      name: "Real estate",
      margin: 0,
      loanToValueRatio: 75,
      subSectors: [],
    },
  ],
};
export const testProducts = {
  quoteId: 123456,
  relevantProducts: [
    {
      id: 1,
      name: "Variable",
      rate: 4.42,
      fixedRateTermInYears: 0,
      monthlyCost: 905.13,
      arrangementFee: 0,
      earlyRepaymentFee: 0,
      baseRate: 0.75,
      followOnRate: 4.42,
    },
    {
      id: 2,
      name: "Fixed term 3 year",
      rate: 4.58,
      fixedRateTermInYears: 3,
      monthlyCost: 1,
      arrangementFee: 1,
      earlyRepaymentFee: 1,
      baseRate: 0.75,
      followOnRate: 4.42,
    },
    {
      id: 3,
      name: "Fixed term 5 year",
      rate: 0,
      fixedRateTermInYears: 5,
      monthlyCost: 1,
      arrangementFee: 1,
      earlyRepaymentFee: 1,
      baseRate: 0.75,
      followOnRate: 4.42,
    },
    {
      id: 4,
      name: "Fixed term 7 year",
      rate: 0,
      fixedRateTermInYears: 7,
      monthlyCost: 1,
      arrangementFee: 1,
      earlyRepaymentFee: 1,
      baseRate: 0.75,
      followOnRate: 4.42,
    },
    {
      id: 5,
      name: "Fixed term 10 year",
      rate: 0,
      fixedRateTermInYears: 10,
      monthlyCost: 1,
      arrangementFee: 1,
      earlyRepaymentFee: 1,
      baseRate: 0.75,
      followOnRate: 4.42,
    },
  ],
};

const testReferrals = {
  referralId: 99,
  isReferred: false,
  referenceNumber: "REFNUM",
  appetite: "Green",
};

const testRetrieve = {
  webSessionId: "ABCDEF",
  quoteId: 654321,
  amount: 88888,
  term: 8,
  loanPurpose: "asset",
  loanToBeSecuredVia: "none",
  isPropertyOwnedByTheBusiness: null,
  valueOfPropertyToBeOfferedAsSecurity: null,
  purchasePriceOfPropertyToBeOfferedAsSecurity: null,
  assetToBeOfferedAsSecurity: null,
  securityCoverage: 100,
  sectorId: 1,
  sectorName: null,
  lengthOfTrading: "over_3_years",
  clientLegalBusinessEntity: "ltd",
  isClientFinancialInformationKnown: false,
  ebitda: null,
  totalDirectorsRemuneration: null,
  existingDebt: null,
  relevantProducts: [
    {
      id: 1,
      name: "retrieved product",
      rate: 5,
      monthlyCost: 500,
      arrangementFee: 0,
      earlyRepaymentFee: 0,
      readOnly: true,
      baseRate: 1,
      followOnRate: 5,
      fixedRateTermInYears: 1,
      factSheetUrl: null,
      isAffectedByBaseRateChange: false,
    },
  ],
};

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const mockFetch = async (url) => {
  const apiURL = process.env.REACT_APP_API_URL;

  const reAPIToken = new RegExp(`${apiURL}/api/Authentication/token$`);
  const reAPIValidate = new RegExp(`${apiURL}/api/Authentication/validate/.*`);
  const reAPIFundingCosts = new RegExp(`${apiURL}/api/FundingCosts$`);
  const reAPISectors = new RegExp(`${apiURL}/api/Sectors$`);
  const reAPIProducts = new RegExp(`${apiURL}/api/Quotes$`);
  const reAPIAppetite = new RegExp(`${apiURL}/api/Quotes/.+/appetite$`);
  const reAPISave = new RegExp(`${apiURL}/api/Quotes/.+/saveforlater$`);
  const reAPIRetrieve = new RegExp(`${apiURL}/api/Quotes/.+$`);
  const reAPIStarRating = new RegExp(`${apiURL}/api/Ratings/referral$`);
  const reAPIReferrals1 = new RegExp(`${apiURL}/api/Referrals$`);
  const reAPIReferrals2 = new RegExp(`${apiURL}/api/Referrals/.+/complete`);
  const reAPISpeakToTeam = new RegExp(`${apiURL}/api/ContactUs/speaktotheteam`);
  const reGetAddress = new RegExp(`${getAddress.url}.*`);
  const reCompaniesHouse = new RegExp(`${companiesHouse.url}?.*`);

  debugConsole("mock", url);

  switch (true) {
    case reAPIValidate.test(url):
      return {}; // either status 200, or 401
    case reAPISectors.test(url):
      await timeout(100);
      return testSectors.sectors;
    case reAPIProducts.test(url):
      await timeout(2000);
      return testProducts;
    case reAPIReferrals1.test(url):
      await timeout(1000);
      return testReferrals;
    case reAPIReferrals2.test(url):
      await timeout(2000);
      return testReferrals;
    case reAPIRetrieve.test(url):
      await timeout(2000);
      return testRetrieve;
    case reAPIToken.test(url):
      return {
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjpbIlN1cGVyQWRtaW4iLCJSYXRlTWFuYWdlciJdLCJuYW1laWQiOiI5ZGNiMjc0NC03ZWRiLTRkMDgtODI4Ny1mZDZjZGJkOTk5ZDMiLCJ1bmlxdWVfbmFtZSI6InJpY2hhcmQuYmFsbGFyZEBoaW11bXNhaWRkYWQuY29tIiwiZW1haWwiOiJyaWNoYXJkLmJhbGxhcmRAaGltdW1zYWlkZGFkLmNvbSIsIm5iZiI6MTU3Nzk2Mzg4NSwiZXhwIjoxNTc3OTY3NDg1LCJpYXQiOjE1Nzc5NjM4ODUsImlzcyI6Imh0dHBzOi8vYmFja2VuZC1kb3QtcmJzLWJyb2tlci1wb3J0YWwtcy5hcHBzcG90LmNvbS8iLCJhdWQiOiJodHRwczovL2JhY2tlbmQtZG90LXJicy1icm9rZXItcG9ydGFsLXMuYXBwc3BvdC5jb20vIn0.juUyF9yaJ__P_gdrU6oZuUy4LrXmdLsXxooBkHCu_cc",
        expires: "2020-01-02T12:18:05.9798655Z",
      };
    case reAPIFundingCosts.test(url):
      return [
        {
          id: 1,
          productId: 2,
          productName: "3 year fixed rate loan",
          rate: 0.91,
        },
        {
          id: 2,
          productId: 3,
          productName: "5 year fixed rate loan",
          rate: 1,
        },
        {
          id: 3,
          productId: 4,
          productName: "7 year fixed rate loan",
          rate: 1.09,
        },
        {
          id: 4,
          productId: 5,
          productName: "10 year fixed rate loan",
          rate: 1.21,
        },
      ];
    case reGetAddress.test(url):
      return {
        addresses: [
          "15-25 Underwood Street, , , , , London, ",
          "25a Underwood Street, , , , , London, ",
          "25b Underwood Street, , , , , London, ",
          "27-37 Underwood Street, , , , , London, ",
          "31 Underwood Street, , , , , London, ",
          "39-43 Underwood Street, , , , , London, ",
          "Brilliant Events Ltd, 45 Underwood Street, , , , London, ",
          "Hi Mum Said Dad, Unit 4 39a, Underwood Street, , , London, ",
          "Loft 2, 15-25 Shepherdess Building, Underwood Street, , , London, ",
          "Loft 2, 39-43 Underwood Street, , , , London, ",
          "Penthouse Building 5, 27-37 Underwood Street, , , , London, ",
          "Taylor James Ltd, 47 Underwood Street, , , , London, ",
        ],
      };
    case reCompaniesHouse.test(url):
      return {
        items: [
          { title: "FirstCo", address_snippet: "1 First Street" },
          { title: "SecondCo", address_snippet: "2 Second Avenue" },
          { title: "ThirdCo", address_snippet: "3 Third Close" },
          { title: "FourthCo", address_snippet: "4 Fourth Boulevard" },
          { title: "FifthCo", address_snippet: "5 Fifth Road" },
        ],
      };
    case reAPISpeakToTeam.test(url):
    case reAPIAppetite.test(url):
    case reAPIStarRating.test(url):
    case reAPISave.test(url):
    default:
      await timeout(Math.random() * 1000);
      return {};
  }
};

export const testChosen = Object.assign({}, initValues, {
  //  "id.referral": {value: 30, valid: true},
  "loan.purpose": { value: "property", valid: true },
  //  "loan.secured": { value: "none", valid: true },
  "loan.secured": { value: "property", valid: true },
  "loan.secured.property_owned": { value: "yes", valid: true },
  "loan.secured.property_value": { value: "100000", valid: true },
  "loan.secured.property_purchase": { value: "", valid: true },
  "loan.amount": { value: "50000", valid: true },
  "loan.term": { value: "6", valid: true },

  "business.sector": { value: "1", valid: true },
  "business.sector.leisure": { value: "12", valid: true },
  "business.sector.consolidated": { value: "12", valid: true },
  "business.trading": { value: "over_3_years", valid: true },
  "business.entity": { value: "ltd", valid: true },
  "business.financial": { value: "yes", valid: true },
  "business.financial.ebitda": { value: "300000", valid: true },
  "business.financial.director_remuneration": { value: "0", valid: true },
  "business.financial.existing_debt": { value: "100000", valid: true },

  "refer.broker.forename": { value: "Billy", valid: true },
  "refer.broker.surname": { value: "Broker", valid: true },
  "refer.broker.company": { value: "Brokerage Ltd", valid: true },
  "refer.broker.email": { value: "billy@brokerage.com", valid: true },
  "refer.broker.phone": { value: "0208111222", valid: true },
  //  "refer.broker.contact": { value: "broker", valid: true },
  "refer.client.forename": { value: "Cilla", valid: true },
  "refer.client.surname": { value: "Client", valid: true },
  "refer.client.company": { value: "Client Ltd", valid: true },
  //  "refer.client.email": { value: "cilla@client.com", valid: true },
  //  "refer.client.phone": { value: "0208 111222", valid: true },

  "unhappy.forename": { value: "Billy", valid: true },
  "unhappy.surname": { value: "Broker", valid: true },
  "unhappy.email": { value: "billy@brokerage.com", valid: true },
  "unhappy.phone": { value: "0208 111222", valid: true },
});
