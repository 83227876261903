import React, { useContext } from 'react';
import styled from 'styled-components';

import { formatLabel } from 'resources/utils';
import { colours } from 'resources/global-styles';
import { QuoteContext } from '_Broker/pages/Quote';

const Div = styled.div`
  display: block;
  width: ${(props) => (props.width ? props.width : "100%")};
  margin-bottom: ${(props) => (props.spacing ? props.spacing : "0")};
  padding: 4px 20px 20px 40px;
  color: black;
  position: relative;
  background-image: url(/images/error.svg);
  background-repeat: no-repeat;
  background-position: left top;
`

const NotValidated = ({ width, spacing, children }) => {
  const dynamic = useContext(QuoteContext);

  return (
    <Div width={width} spacing={spacing}>{formatLabel(children, dynamic)}</Div>
  )
}

export default NotValidated;
