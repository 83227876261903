import React from "react";
import { Field } from "react-final-form";
import {
  phoneValidator,
  emailConfirmValidator,
  emailValidator,
  requiredPhoneValidator,
  customRequiredValidator
} from "../utils/validators";
import { isFieldValidAndTouched, composeValidators } from "../utils/form";
import { ErrorMessage } from "../theming/common";
import FieldInput from "../components/FieldInput";
import { trackValidationError } from "resources/analyticsHelper";


const BrokerDetailsFieldsSimplified = ({onBlurBrokerDetailsHandler}) => {
  
  const onBlurFieldHandler = (e, fieldLabel, meta) => {
    // console.log(`>>> onBlurFieldHandler`, e.name, e.value, fieldLabel, e);

    if (meta && meta.error) {
      let errMsg = meta.error;
      trackValidationError(errMsg, fieldLabel, 'Asset details')
    }
  };

  return (
    <>
      {/* <Field name="firstName" validate={composeValidators(customRequiredValidator("Please enter your first name"))}>
        {({ input, meta }) => (
          <div>
            <label>Your first name</label>
            <FieldInput
              {...input}
              valid={isFieldValidAndTouched(meta)}
              type="text"
              onBlur={(e) => {
                input.onBlur(e); 
                onBlurFieldHandler(input, 'Your first name', meta);
              }}
            />
            {meta.error && meta.touched && (
              <ErrorMessage>{meta.error}</ErrorMessage>
            )}
          </div>
        )}
      </Field>
      <Field name="lastName" validate={composeValidators(customRequiredValidator("Please enter your last name"))}>
        {({ input, meta }) => (
          <div>
            <label>Your last name</label>
            <FieldInput
              {...input}
              valid={isFieldValidAndTouched(meta)}
              type="text"
              onBlur={(e) => {
                input.onBlur(e); 
                onBlurFieldHandler(input, 'Your last name', meta);
              }}
            />
            {meta.error && meta.touched && (
              <ErrorMessage>{meta.error}</ErrorMessage>
            )}
          </div>
        )}
      </Field>
      <Field name="firmName" validate={composeValidators(customRequiredValidator("Please enter your company name"))}>
        {({ input, meta }) => (
          <div>
            <label>Your company name</label>
            <FieldInput
              {...input}
              valid={isFieldValidAndTouched(meta)}
              type="text"
              onBlur={(e) => {
                input.onBlur(e); 
                onBlurFieldHandler(input, 'Your company name', meta);
              }}
            />
            {meta.error && meta.touched && (
              <ErrorMessage>{meta.error}</ErrorMessage>
            )}
          </div>
        )}
      </Field> */}
      <Field
        name="email"
        validate={composeValidators(customRequiredValidator("Please enter your email address"), emailValidator)}
      >
        {({ input, meta }) => (
          <div>
            <label>Your email address</label>
            <FieldInput
              {...input}
              valid={isFieldValidAndTouched(meta)}
              type="email"
              onBlur={(e) => {
                input.onBlur(e);

                onBlurFieldHandler(input, 'Your email address', meta);
                
                onBlurBrokerDetailsHandler(input.value);
              }}
            />
            {meta.error && meta.touched && (
              <ErrorMessage>{meta.error}</ErrorMessage>
            )}
          </div>
        )}
      </Field>
      <Field
        name="emailConfirm"
        validate={composeValidators(
          customRequiredValidator("Please confirm your email address"), 
          emailConfirmValidator('emailConfirm'), 
          emailValidator
        )}
      >
        {({ input, meta }) => (
          <div>
            <label>Confirm email address</label>
            <FieldInput
              {...input}
              valid={isFieldValidAndTouched(meta)}
              type="email"
              onBlur={(e) => {
                input.onBlur(e);

                onBlurFieldHandler(input, 'Your email address', meta);
                
                onBlurBrokerDetailsHandler(input.value);
              }}
            />
            {meta.error && meta.touched && (
              <ErrorMessage>{meta.error}</ErrorMessage>
            )}
          </div>
        )}
      </Field>
      {/* <Field
        name="phoneNumber"
        validate={composeValidators(requiredPhoneValidator, phoneValidator)}
      >
        {({ input, meta }) => (
          <div>
            <label>Your phone number</label>
            <FieldInput
              {...input}
              valid={isFieldValidAndTouched(meta)}
              type="text"
              onBlur={(e) => {
                input.onBlur(e); 
                onBlurFieldHandler(input, 'Your phone number', meta);
              }}
            />
            {meta.error && meta.touched && (
              <ErrorMessage>{meta.error}</ErrorMessage>
            )}
          </div>
        )}
      </Field> */}
    </>
  );
};

export default BrokerDetailsFieldsSimplified;
