import { quoteStages, nonLtdFinancials, loanChoices, optionsForBusinessSector } from 'resources/page-structure';
import {getAccessLevel} from "./utils";

window.digitalData = {
  page: {},
  application: {},
  form: {},
};

/* eslint-disable no-underscore-dangle */
if (!window._satellite) {
  if (!window.__karma__) {
    console.warn(
      "Adobe Analytics was not initialised. Mocking _satellite.track()"
    );
  }
  console.info("satellite is already initialized");
  window._satellite = { track: () => {} };
}

const formName = "Broker Lending check";

function resetTrackingData() {
  window["digitalData"] = {};
}

export function trackApplicationStart() {
  resetTrackingData();
  window["digitalData"].page = {};
  window["digitalData"].page.name = formName;
  window["digitalData"].application = {};
  window["digitalData"].application.applicationType = formName;
  window["digitalData"].application.stepName = "Application Start";
  window["digitalData"].application.brandName = "NatWest";
  window["_satellite"].track("applicationStart");
}

export function trackApplicationComplete(applicationId) {
  resetTrackingData();
  window["digitalData"].page = {};
  window["digitalData"].page.name = formName;
  window["digitalData"].application = {};
  window["digitalData"].application.applicationType = formName;
  window["digitalData"].application.stepName = "Application Complete";
  window["digitalData"].application.brandName = "NatWest";
  window["digitalData"].application.applicationID = applicationId;
  window["_satellite"].track("applicationComplete");
}

export function trackLandingPage() {
  resetTrackingData();
  window["digitalData"].page = {};
  window["digitalData"].page.name = `${formName} Landing Page`;
  window["digitalData"].page.brandName = getAccessLevel();
  window["_satellite"].track("pageTransition");
}

export function trackApplicationStepName(stepName) {
  // console.log('>>> trackApplicationStepName', stepName);
  let trackStepName = stepName;

  // only when step name start with /
  //  `/h5t3d/quote/2-business` will be converted to `quote business`
  if (trackStepName.indexOf("/quote") > 0) {
    const list = trackStepName.split("/").slice(2);
    trackStepName = list
      .reduce((a, b) => `${a} ${b !== "quote" ? b.substring(2) : b}`, "")
      .substring(1);
  }

  if (trackStepName.charAt(0) === "/") {
    trackStepName = trackStepName.substring(1);
  }

  switch (trackStepName) {
    case "quote loan":
      trackStepName = "Loan and security";
      break;
    case "quote business":
      trackStepName = "Business details";
      break;
    case "quote summary":
      trackStepName = "Quote summary";
      break;
    case "quote refer broker":
      trackStepName = "Refer quote";
      break;
    default:
      break;
  }
  // console.log('>>> trackApplicationStepName', trackStepName);

  resetTrackingData();
  window["digitalData"].page = {};
  window["digitalData"].application = {};
  window["digitalData"].page.name = formName;
  window["digitalData"].application.applicationType = formName;
  window["digitalData"].application.stepName = trackStepName;
  window["digitalData"].application.brandName = "NatWest";
  window["_satellite"].track("applicationStepName");
}

export function trackApplicationRating(rating) {
  // console.log('>>> >>> >>> trackApplicationRating', rating);

  resetTrackingData();
  window["digitalData"].page = {};
  window["digitalData"].application = {};
  window["digitalData"].page.name = formName;
  window["digitalData"].application.applicationType = formName;
  window["digitalData"].application.brandName = "NatWest";
  window["digitalData"].application.rating = rating;
  window["_satellite"].track("Rating");
}


function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}


function findObject(data, key, val) {
  const property1 = 'title';
  const property2 = 'label';

  if (data && data[key] === val) {
    if (data[property1]) {
      return data[property1];
    } else if (data[property2]) {
      return data[property2];
    }
  }

  if (Array.isArray(data)) {
    for (const obj of data) {
      const f = findObject(obj, key, val);
      if (f) {
        return f;
      }
    }
  }
  else if (typeof data === 'object') {
    for (const p in data) {
      const f = findObject(data[p], key, val);
      if (f) {
        return f;
      }
    }
  }

  return null;
}

function fieldNameToLabel(fieldName) {
  //
  const pageStructureData = {quoteStages, nonLtdFinancials, loanChoices};
  const found = findObject(pageStructureData, 'id', fieldName);
  return found;
}


function fieldValueToLabel(fieldValue) {
  //
  const pageStructureData = {quoteStages, nonLtdFinancials, loanChoices};
  const found = findObject(pageStructureData, 'value', fieldValue);
  return found;
}

function fieldValueToLabelBy(fieldValue, poolData) {
  //
  const pageStructureData = {poolData};
  const found = findObject(pageStructureData, 'value', fieldValue);
  return found;
}

///////////////////////////////////////
// Natwest | LOMBARD INPUT TRACKING
///////////////////////////////////////
// input tracking functions
export function trackInput(choiceId, choiceVal, isValidate) {
  // console.log('>> trackInput', choiceId, choiceVal, isValidate);

  // skip PII inputs
  const mainSkipList = ['business.financial.ebitda', 'business.financial.existing_debt', 'business.financial.director_remuneration', 'id.', 'refer.confirm.confirmed'];
  const toFilter = mainSkipList.filter((str) => {
    return choiceId.indexOf(str) === 0;
  });

  if (toFilter && toFilter.length) {
    // do nothing
  } else {
    const lastIdStr = choiceId.split('.')[choiceId.split('.').length - 1];

    // do not track input if it is in the following list (when input value changes)
    const skipList = ['refer.broker.contact', 'forename', 'surname', 'company', 'email', 'phone', 'postcodeLookup', 'address1', 'address2', 'town', 'postcode', 'information','loan.secured.property_value', 'loan.secured.property_purchase', 'loan.secured.other_asset', 'loan.amount', 'loan.term', 'loan.secured.property_value', 'loan.secured.property_purchase'];

    // only track input if it is in this list (when input is being validated (blur))
    const validateList = ['loan.secured.property_value', 'loan.secured.property_purchase', 'loan.secured.other_asset', 'loan.amount', 'loan.term', 'loan.secured.property_value', 'loan.secured.property_purchase'];
    
    const listForInput = ['amount', 'term', 'other_asset'];
    const listForInputExclude = ['sector', 'leisure'];
    const listForCheckbox = ['confirmed'];

    // track nothing for PII
    if (
      (!isValidate && (skipList.includes(lastIdStr) || skipList.includes(choiceId))) || 
      (isValidate && (!validateList.includes(lastIdStr) && !validateList.includes(choiceId)))
    ) {
      return;
    }

    let choiceLabel = fieldNameToLabel(choiceId);
    // console.log('choiceid : choiceLabel ---', choiceId, ':', choiceLabel);

    if (
      !listForInputExclude.includes(lastIdStr) 
      && (isNumeric(choiceVal) || listForInput.includes(lastIdStr))
    ) {
      trackFieldInput(choiceLabel, choiceVal);
    } else if (listForCheckbox.includes(lastIdStr)) { 
      trackCheckboxSelection(choiceLabel, choiceVal, !!choiceVal);
    } else {
      if (choiceId === 'business.sector.leisure') {
        choiceLabel = 'What sector of leisure is the client in?';
        const choiceOptionLabel = fieldValueToLabelBy(choiceVal, optionsForBusinessSector);
        trackRadioButtonSelection(choiceLabel, choiceOptionLabel);
        return;
      } else if (choiceId === 'business.sector.healthcare') {
        choiceLabel = 'What sector of healthcare is the client in?';
        const choiceOptionLabel = fieldValueToLabelBy(choiceVal, optionsForBusinessSector);
        trackRadioButtonSelection(choiceLabel, choiceOptionLabel);
        return;
      } else if (choiceId === 'business.sector') {
        const choiceOptionLabel = fieldValueToLabelBy(choiceVal, optionsForBusinessSector);
        trackRadioButtonSelection(choiceLabel, choiceOptionLabel);
        return;
      } else if (choiceId === 'loan.secured') {
        // only look for label inside loanChoices[1]
        const choiceOptionLabel = fieldValueToLabelBy(choiceVal, loanChoices[1]);
        trackRadioButtonSelection(choiceLabel, choiceOptionLabel);
        return;
      }

      const choiceOptionLabel = fieldValueToLabel(choiceVal);
      trackRadioButtonSelection(choiceLabel, choiceOptionLabel);
    }
  }
}

export function trackFieldInput(fieldName, fieldVal) {
  // console.log('>>> >>> >>> trackFieldInput:', fieldName, '---', fieldVal);

  resetTrackingData();
  window["digitalData"].form = {};
  window["digitalData"].form.textFieldName = fieldName;
  window["digitalData"].form.textFieldValues  = fieldVal;
  triggerTracking('textFieldTracking');
}

export function trackRadioButtonSelection(fieldName, fieldVal) {
  // console.log('>>> >>> >>> trackRadioButtonSelection:', fieldName, '---', fieldVal);
  
  resetTrackingData();
  window["digitalData"].form = {};
  window["digitalData"].form.radioButtonTitle = fieldName;
  window["digitalData"].form.radioButtonOption  = fieldVal;
  triggerTracking('radioButtonTracking');
}

export function trackButtonClick(evtType, evtName) {
  // console.log('>>> >>> >>> trackButtonClick:', evtType, '---', evtName);
  if (evtType !== 'Button' && evtType !== 'Link' && evtType !== 'Accordian') {
    // console.log('>>> trackButtonClick, invalid event type.');
    return;
  }

  resetTrackingData();
  window["digitalData"].eventType = evtType;
  window["digitalData"].eventName  = evtName;
  triggerTracking('pageEvent');
}

export function trackCheckboxSelection(fieldName, fieldVal, fieldSelected) {
  // console.log('>>> >>> >>> trackCheckboxSelection:', fieldName, '---', fieldVal, '---', fieldSelected);
  
  resetTrackingData();
  window["digitalData"].form = {};
  window["digitalData"].form.checkBoxName = fieldName;
  window["digitalData"].form.checkBoxOption  = fieldVal;
  window["digitalData"].form.checkBoxSelection= fieldSelected;
  triggerTracking('checkBoxTracking');
}




///////////////////////////////////////
// Lombard page tracking
///////////////////////////////////////
// page tracking functions
export function trackPageTransitionGetStarted() {
  // console.log('>>> >>> >>> trackPageTransitionGetStarted');

  resetTrackingData();
  window["digitalData"].page = {};
  window["digitalData"].application = {};
  window["digitalData"].page.name = `${formName}:Get Started`;
  window["digitalData"].page.brandName = getAccessLevel();
  triggerTracking('pageTransition');
}

export function trackPageTransitionLombardUnableToProceed() {
  // console.log('>>> >>> >>> trackPageTransitionLombardUnableToProceed');

  resetTrackingData();
  window["digitalData"].page = {};
  window["digitalData"].application = {};
  window["digitalData"].page.name = `${formName}:Unable to provide online quote`;
  window["digitalData"].application.brandName = "Lombard";
  triggerTracking('pageTransition');
}

export function trackLombardApplicationStart() {
  // console.log('>>> >>> >>> trackLombardApplicationStart');

  resetTrackingData();
  window["digitalData"].page = {};
  window["digitalData"].application = {};
  window["digitalData"].page.name = formName;
  window["digitalData"].application.stepName = "Application Start";
  window["digitalData"].application.brandName = "Lombard";
  triggerTracking('applicationStart');
}

let currentStepName = null;
export function trackLombardApplicationStepName(stepName) {
  // console.log('>>> >>> >>> trackLombardApplicationStepName', stepName);
  if (stepName === currentStepName) {
    // console.log('>>> >>> >>> trackLombardApplicationStepName', stepName, '--- skipped tracking');
    return;
  }

  resetTrackingData();
  window["digitalData"].application = {};
  window["digitalData"].application.stepName = stepName;
  window["digitalData"].application.brandName = "Lombard";
  triggerTracking('applicationStepName');

  currentStepName = stepName;
}

export function trackLombardApplicationComplete(referenceId) {
  // console.log('>>> >>> >>> trackLombardApplicationComplete', referenceId);

  resetTrackingData();
  window["digitalData"].page = {};
  window["digitalData"].application = {};
  window["digitalData"].page.name = formName;
  window["digitalData"].application.stepName = "Application Complete";
  window["digitalData"].application.brandName = "Lombard";
  window["digitalData"].application.referenceID = referenceId;
  triggerTracking('applicationComplete');
}

export function trackLombardEmailQuoteSummaryRetrieval(quoteStatus) {
  // console.log('>>> >>> >>> trackLombardEmailQuoteSummaryRetrieval', quoteStatus);

  resetTrackingData();
  window["digitalData"].page = {};
  window["digitalData"].application = {};
  window["digitalData"].page.name = "Broker Lending Check:via Email";
  window["digitalData"].application.quoteStatus = quoteStatus;
  window["digitalData"].application.stepName = "Quote Summary";
  window["digitalData"].application.brandName = "Lombard";
  triggerTracking('emailQuoteSummary');
}

export function trackPageTransitionLombardThankYouPage() {
  // console.log('>>> >>> >>> trackPageTransitionLombardThankYouPage');
  
  resetTrackingData();
  window["digitalData"].page = {};
  window["digitalData"].page.name = "ThankYou";
  window["digitalData"].page.brandName = "Lombard";
  triggerTracking('pageTransition');
}

export function trackFeedback(numOfStars, contactAllowed) {
  // console.log('>>> >>> >>> trackFeedback', numOfStars, '---', contactAllowed);
  
  resetTrackingData();
  window["digitalData"].feedback = {};
  window["digitalData"].feedback.numberOfStars = numOfStars;
  window["digitalData"].feedback.contactAllowed = contactAllowed ? 'Yes': 'No'
  window["digitalData"].eventType = "Feedback";
  triggerTracking('feedback'); 
}

export function trackValidationError(fieldErrorMsg, fieldName, stepName) {
  // console.log('>>> >>> >>> trackValidationError', fieldErrorMsg, '---', fieldName, '---', currentStepName);
  // console.log('currentStepName', currentStepName);
  
  resetTrackingData();
  window['digitalData'].form = {};
  window['digitalData'].form.fieldError = fieldErrorMsg;
  window['digitalData'].form.fieldName = fieldName;
  window['digitalData'].form.applicationStep = stepName || currentStepName || 'Asset details';
  triggerTracking('formfieldError');
}


///////////////////////////////////////
// PRIVATE FUNCTIONS
///////////////////////////////////////
/**
 * private function to trigger the satellite tracking
 * @param {*} trackKeyword name of the type/event to track
 */
function triggerTracking(trackKeyword) {
  // console.log(JSON.stringify(window['digitalData']));
  window['_satellite'].track(trackKeyword);
}