import { animated } from "react-spring";
import styled, { createGlobalStyle } from "styled-components";

import { colors as lombardColors } from "../_Lombard/theming/variables";
import { pathVal, zIndex } from "resources/constants";
import { baseColors } from "./global-style-colors";

export const media = {
  tabletWidth: 768,
  mobile: "(max-width: 576px)",
  tablet: "(min-width: 768px)",
  desktop: "(min-width: 1024px)",
  maxWidth: "970px",
  max: "(min-width: 1067px)", // 970px, plus 5% margins
};

export const colours = {
  natWestPurple: "#42145f",
  darkGrey: "#333",
  lightGrey: "#f4f4f4",
  mediumGrey: "#dadada",
  mediumGreyBorder: "#c9c6c6",
  validGreen: "#429448",
  invalidRed: "#af2317",
  warningAmber: "#fbba20",
  warningAmberBackground: "#fffbf4",
  teal: "#288090",
  headerColour: "black",

  main_text_copy: baseColors.color_heading_grey,
  title_copy: baseColors.color_champion_purple,
  sub_text_copy: baseColors.color_body_grey,

  page_bg: baseColors.color_ground_grey,
  btn_copy: baseColors.color_bright_purple,
  header_footer: baseColors.color_champion_purple,

  progress_bar: baseColors.color_white,
  progress_border: baseColors.color_regal_purple,
  progress_bg: baseColors.color_lilac,
  progress_bg_dark: baseColors.color_regal_purple,
  

  radio_border: baseColors.color_light_grey,
  radio_border_checked: baseColors.color_regal_purple,
  radio_bg: baseColors.color_white,

  //title
  title_text: baseColors.color_regal_purple,

  //text
  text_copy: baseColors.color_heading_grey,

  //button-dark
  btn_dark: baseColors.color_champion_purple,

  //table
  table_heading: baseColors.color_heading_grey,
  table_content: baseColors.color_body_grey,
  table_bottom_border: baseColors.color_light_grey,


  field_border: baseColors.color_body_grey,
  radio_border_checked: baseColors.color_regal_purple,


  //button-primary:
  btn_prim_bg: baseColors.color_bright_purple,
  btn_prim_dark: baseColors.color_regal_purple,
  btn_prim_border: baseColors.color_bright_purple,
  btn_prim_text: baseColors.color_white,

  //input-fields
  pink: baseColors.pink,
  input_bg: baseColors.color_white,
  input_border: baseColors.color_bright_purple,

  //warning
  warning_bg: baseColors.color_light_yellow,
  warning_border: baseColors.color_dark_yellow,
  warning_link: baseColors.color_bright_purple,

  //appetite
  appetite_red_border:baseColors.color_dark_red,
  appetite_red_bg:baseColors.color_light_red,
  appetite_green_border:baseColors.color_dark_green,
  appetite_green_bg:baseColors.color_light_green,
  appetite_yellow_border:baseColors.color_dark_yellow,
  appetite_yellow_bg:baseColors.color_light_yellow,
  appetite_text_copy:baseColors.color_body_grey,

  //product
  product_loan_bg: baseColors.color_white,
  product_loan_border: baseColors.color_light_grey,

  //sidebar
  sidebar_bg: baseColors.color_white,

};

export const HeaderFooter = styled.div`
  position: relative;
  background-color: ${colours.header_footer};
  color: white;

  & header,
  & footer {
    position: relative;
    max-width: ${media.maxWidth};
    margin: 0 auto;
    padding: 15px 5%;
    display: flex;
    justify-content: left;

    @media ${media.max} {
      padding: 15px 0;
    }
  }
`;
export const Button = styled.button`
outline: none;
padding: 0px 32px 0px 32px;
text-align: center;
font-size: 16px;
text-decoration: none;
cursor: pointer;
min-width: 210px;
margin-bottom: 60px;
background-color: ${colours.btn_prim_bg};
border-radius: 100px;
color: ${colours.btn_prim_text};
border-style: none;
height: 44px;
margin-bottom: 60px;

&:hover {
  background-color: ${colours.btn_prim_dark};
}

&:focus {
  outline: none;
  box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.btn_prim_border};
}
`;
export const RoundedDiv = styled.div`
  border: 1px solid
    ${(props) =>
      props.checked ? colours.radio_border_checked  : colours.mediumGreyBorder};
  border-radius: 8px;
  padding: 25px;
  width: 100%;
`;
export const Appetite = styled.div`
  flex: 1;
  margin-top: 10px;
  padding: 30px 30px 8px 80px;
  font-size: 16px;
  background-image: ${(props) =>
    props.path === pathVal.red
      ? "url(/images/red-warning-icon.svg)"
      : props.path === pathVal.amber
      ? "url(/images/information-icon_purple.svg)"
      : "url(/images/input-valid-icon.svg)"};
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: 28px 28px;
  background-color: ${colours.input_bg};
  border-radius: 8px;
`;
export const TextLink = styled.div`
  font-size: 18px;
  color: ${colours.warning_link};
  cursor: pointer;
  text-decoration: underline;

  & svg {
    margin-right: 10px;
    height: 32px;
  }  
`;
export const Modal = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: ${zIndex.modal};
`;
export const Input = styled.input`
  width: ${(props) => (props.fullwidth ? "90%" : "100%")};
  height: 54px;
  font-size: 18px;
  color: ${colours.darkGrey};
  padding: 15px 45px 15px 15px;
  border: none;
  background-color: ${colours.input_bg};
  border: 1px solid ${colours.field_border};

  outline: 0;
  background-position: center right 15px;
  background-repeat: no-repeat;
  margin: 0px 0px 20px 4px;
  margin-bottom: 20px;
  padding-left: 20px;
  background-image: ${(props) =>
    props.valid ? "url(/images/input-valid-icon.svg)" : "none"};
  border-radius: 8px;

  @media ${media.tablet} {
    width: ${(props) => (props.fullwidth ? "90%" : "50%")};
  }
  /*  @media ${media.desktop} {
    width: ${(props) => (props.fullwidth ? "90%" : "33%")};;
  } */

  &:hover:focus {
    border: none;
    box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.input_border};
  }

  &:hover {
    border: 2px solid ${colours.input_border};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.input_border};
  }

`;
export const TextArea = styled.textarea`
  font-family: "RNHouseSansRegular";
  width: 99% !important;
  font-size: 16px;
  color: ${colours.darkGrey};
  padding: 15px 45px 15px 15px;
  border-radius: 8px;
  margin: 0px 4px 20px 4px;
  background-color: ${colours.input_bg};
  outline: 0;
  margin-bottom: 20px;

  @media ${media.tablet} {
    width: ${(props) => (props.fullwidth === "true" ? "100%" : "50%")};
  }
  /*  @media ${media.desktop} {
    width: 33%;
  } */

  &:hover:focus {
    border: none;
    box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.input_border};
  }

  &:hover {
    border: 2px solid ${colours.input_border};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.input_border};
  }

`;
export const Tickbox = styled.div`
  width: 25px;
  height: 25px;
  border: 2px solid
    ${(props) =>
      props.checked ? colours.natWestPurple : colours.mediumGreyBorder};
  background-color: ${(props) =>
    props.checked ? colours.natWestPurple : "inherit"};
  padding: 1px 0 1px 2px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: text-bottom;

  &:before {
    content: url("/images/tick.svg");
  }
`;

const GlobalStyles = createGlobalStyle`
  @font-face{
    font-family:"RNHouseSansBold";
    src: url(/fonts/RNHouseSansW01Bold.eot);
    src: url(/fonts/RNHouseSansW01Bold.eot?#iefix) format('eot'),
    url(/fonts/RNHouseSansW01Bold.woff) format('woff'),
    url(/fonts/RNHouseSansW01Bold.ttf) format('truetype'),
    url(/fonts/RNHouseSansW01Bold.svg) format('svg')
  }
  @font-face {
    font-family:"RNHouseSansRegular";
    src: url(/fonts/RNHouseSansW01Regular.eot);
    src: url(/fonts/RNHouseSansW01Regular.eot?#iefix) format('eot'),
    url(/fonts/RNHouseSansW01Regular.woff) format('woff'),
    url(/fonts/RNHouseSansW01Regular.ttf) format('truetype'),
    url(/fonts/RNHouseSansW01Regular.svg) format('svg')
  }
  @font-face {
    font-family:"RNHouseSansLight";
    src: url(/fonts/RNHouseSansW01-Light.eot);
    src: url(/fonts/RNHouseSansW01-Light.eot?#iefix) format('eot'),
    url(/fonts/RNHouseSansW01-Light.woff) format('woff'),
    url(/fonts/RNHouseSansW01-Light.ttf) format('truetype'),
    url(/fonts/RNHouseSansW01-Light.svg) format('svg')
  }
  @font-face{
    font-family: 'nw-icons';
    src: url('/fonts/nw-icons/nw-icons.eot');
    src: url('/fonts/nw-icons/nw-icons.eot?#iefix') format('embedded-opentype'),
    url('/fonts/nw-icons/nw-icons.woff') format('woff'),
    url('/fonts/nw-icons/nw-icons.ttf') format('truetype'),
    url('/fonts/nw-icons/nw-icons.svg') format('svg');
    font-weight: normal;
    font-style: normal
  }
  @font-face {
    font-family: 'knile-semibold';
    font-style: normal;
    font-weight: bold;
    src:
      local('RN House Sans Bold'),
      url('/fonts/knile-semibold-webfont.woff') format('woff'),
      url('/fonts/knile-semibold-webfont.ttf') format('truetype');
  }
  @font-face {
    font-family: 'knile-bold';
    font-style: normal;
    font-weight: bolder;
    src:
      local('RN House Sans'),
      url('/fonts/knile-bold-webfont.woff') format('woff'),
      url('/fonts/knile-bold-webfont.ttf') format('truetype'),
      url('/fonts/knile-bold-webfont.svg') format('svg');
  }  

  body, html, #root {
    font-family: "RNHouseSansRegular",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: scrollbar;
  	font-size: 18px;
    line-height: 26px;
    color: ${colours.darkGrey};
    letter-spacing: .2px;
    margin: 0;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    background: ${colours.page_bg};
  }
  #root {
    display:-ms-flexbox;
    display:flex;
    flex-direction: column;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  main {
    display: block;
  }
  
  h1 {
    font-family: knile-bold;
    font-size: 36px;
    line-height: 1.2;
    color: ${colours.title_copy};
    margin: 50px 0 10px 0;
  }
  h2 {
    font-size: 22px;
    font-weight: normal;
    line-height: 1.2;
    color: ${colours.title_text}
    margin: 50px 0 20px 0;
  }

  label {
    font-size: 16px;
    display: block;
  }
  
  a {
    text-decoration: none;
    color: ${colours.warning_link};

    &:hover {
      text-decoration: underline;
    }
  }

  .lombard-container {
    width:100%; /* 2 */
    min-height:100vh;
    display:-webkit-flex; /* 3 */
    display:flex;
    -webkit-flex-direction:column; /* 3 */
    flex-direction:column;
    background-color: ${colours.page_bg};

    h1 {
      font-family: "RNHouseSansBold",sans-serif;
      font-size: 36px;
      line-height: 1.2;
      color: ${colours.title_text};
      margin: 50px 0 10px 0;
    }

    h2 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      color: ${lombardColors.text}
      margin: 20px 0 20px 0;
    }

    h3 {
      color: ${colours.title_text}
    }

    .accordion {
      margin: 2rem 0;
    }

    .accordion__item {
      border-bottom: 1px solid ${lombardColors.mediumGreyBorder};
    }
    .accordion__item:nth-of-type(1){
      border-top: 1px solid ${lombardColors.mediumGreyBorder};
    }

    .accordion__button{
      padding: 1.5rem 0;
      cursor: pointer;
      display:flex;

      h3 {
        margin: 0;
      }
    }

    .loader-1 {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      color: #FFF;
      left: -140px;
      -webkit-animation: shadowRolling 2s linear infinite;
              animation: shadowRolling 2s linear infinite;
    }

    @-webkit-keyframes shadowRolling {
      0% {
        box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
      }
      12% {
        box-shadow: 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
      }
      25% {
        box-shadow: 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
      }
      36% {
        box-shadow: 120px 0 white, 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0);
      }
      50% {
        box-shadow: 130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white;
      }
      62% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white, 110px 0 white;
      }
      75% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white;
      }
      87% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white;
      }
      100% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
      }
    }
    @keyframes shadowRolling {
      0% {
        box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
      }
      12% {
        box-shadow: 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
      }
      25% {
        box-shadow: 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
      }
      36% {
        box-shadow: 120px 0 white, 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0);
      }
      50% {
        box-shadow: 130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white;
      }
      62% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white, 110px 0 white;
      }
      75% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white;
      }
      87% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white;
      }
      100% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
      }
    }
  }
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export default GlobalStyles;
