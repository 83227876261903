import React, { useRef, useState } from "react";
import { useTransition, useChain, animated, config } from "react-spring";
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'resources/images/close.svg';
import { zIndex, regExp } from 'resources/constants';
import { media, colours, Modal, Button, Input } from 'resources/global-styles';
import { validFormat } from 'resources/utils';
import NotValidated from '_Broker/structure/NotValidated';
import { trackFieldInput, trackRadioButtonSelection, trackValidationError } from "resources/analyticsHelper";

import SaveAndEmail from './SaveAndEmail';

const AnimatedDiv = styled(animated.div)`
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background-color: ${colours.page_bg};
  z-index: ${zIndex.sidebar};
  box-shadow: -1px 0 10px rgba(0, 0, 0, 0.25);
  padding: 30px;

  @media ${media.tablet} {
    width: 600px;
  }
`
const Close = styled(CloseIcon)`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`
const SaveButton = styled(Button)`
  width: 45%;
  min-width: 0;
  margin-bottom: 0;
`
const CancelButton = styled(Button)`
  width: 45%;
  min-width: 0;
  background-color: ${colours.btn_prim_bg};
  color: ${colours.btn_prim_text};
  border: none;
  margin-bottom: 0;
`

const SaveQuote = ({ quote, handleSave }) => {
  const [save, setSave] = useState(false);
  const [brokerEmail, setBrokerEmail] = useState('');
  const [valid, setValid] = useState();
  const sidebarRef = useRef();
  const transition = useTransition(true, null, {
    from: {
      transform: "translateX(100%)"
    },
    enter: {
      transform: "translateX(0)"
    },
    leave: {
      transform: "translateY(-100%)"
    },
    unique: true,
    config: config.slow,
    ref: sidebarRef,
  });

  useChain(
    [sidebarRef],
    [0]
  );

  const onBlurHandler = (fieldLabel, brokerEmail) => {
    const isValid = validFormat(brokerEmail, regExp.email);

    if (!isValid) {
      const errMsg = `Enter a valid email address`;
      
      trackValidationError(errMsg, fieldLabel, 'Save application')
    }
  };

  return transition.map(({ item, key, props }) =>
    item ? (
      <Modal key={key} onClick={handleSave}>
        <AnimatedDiv style={props} onClick={(evt) => evt.stopPropagation()}>
          <Close onClick={handleSave} />
          <h1>Save application</h1>
          {!save && <>
            <p>Enter your email address and we'll send you a link so you can return to your quotes.</p>
            <strong>Your email address</strong>
            <Input
              value={brokerEmail}
              onChange={(evt) => setBrokerEmail(evt.target.value)}
              onBlur={() => { setValid(validFormat(brokerEmail, regExp.email)); onBlurHandler('Your email address', brokerEmail); }}
              valid={valid}
              fullwidth={true}
              maxLength='50'
            />
            {valid === false && <NotValidated width='90%' spacing='20px'>Enter a valid email address</NotValidated>}
            <CancelButton onClick={handleSave}>Cancel</CancelButton>
            <SaveButton onClick={() => {if (valid) setSave(true)}}>Submit</SaveButton>
          </>}
          {save && <>
            <SaveAndEmail quote={quote} brokerEmail={brokerEmail} />
            <p>Thanks, we've emailed your link to <strong>{brokerEmail}</strong></p>
            <p>Use the link to return to your indicative quotes</p>
            <SaveButton onClick={handleSave}>Close</SaveButton>
          </>}
        </AnimatedDiv>
      </Modal>
    ) : null
  );
};

export default SaveQuote;
