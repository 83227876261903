import React, { Fragment } from "react";
import styled from "styled-components";
import { quoteStages } from "../utils/constants";
import { zIndex } from "resources/constants";
import { media, colors } from "../theming/variables";
import { colours } from "../../resources/global-styles.js";

const Outer = styled.div`
  width: 100%;
  padding: 0 5%;
  background-color: ${colours.progress_bar};
  border-bottom: 1px solid ${colours.progress_border};
  font-size: 14px;
`;
const Inner = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: ${media.maxWidth};
  padding: 0.75rem 0 0.5rem 0;
  display: flex;
  justify-content: space-between;
`;

const Div = styled.div`
  z-index: ${zIndex.progress};
`;
const Circle = styled.div`
  font-family: ${(props) => (props.done ? "nw-icons" : "")};
  border-radius: 100%;
  background-color: ${(props) =>
    props.done ? colours.progress_bg_dark : props.active ? colours.progress_bg : "#f9f9f9"};
  color: ${(props) =>
    props.done ? colours.progress_bar : props.active ? colours.header : colours.progress_border};
  border: 2px solid
    ${(props) => (props.active || props.done ? colours.progress_border : colours.field_border)};
  cursor: ${(props) => (props.done ? "pointer" : "inherit")};
  width: 40px;
  height: 40px;
  padding-top: 6px;
  margin: 0 auto;
  text-align: center;
`;
const Title = styled.span`
  @media ${media.mobile} {
    display: none;
  }
`;

const ProgressBar = ({ stageId, handleChange }) => (
  <Outer>
    <Inner>
      {quoteStages.map((obj, i) => (
        <Fragment key={`progress_${i}`}>
          {obj.label && (
            <Div>
              <Circle
                active={i === stageId}
                done={i < stageId}
                onClick={() => {
                  if (i < stageId) handleChange(i);
                }}
              >
                {i < stageId ? "\ue900" : i + 1}
              </Circle>
              <Title>{obj.label}</Title>
            </Div>
          )}
        </Fragment>
      ))}
    </Inner>
  </Outer>
);

export default ProgressBar;
