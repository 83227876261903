import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

import { Button } from "../theming/common";
import { colors } from "../theming/variables";
import { colours } from "../../resources/global-styles.js";
import { useLombardContext } from "../context/lombardContext";
import { useAppContext } from "../../context/appContext";
import { ReactComponent as BackIcon } from "../../resources/images/chevron_purple.svg";
import { trackButtonClick } from "resources/analyticsHelper";

const PrevNextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const PrevButton = styled.button`
  height: 60px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: ${colours.btn_copy};
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }

  & svg {
    height: 18px;
    transform: rotate(180deg);
    margin-right: 10px;
    margin-top: 0px;
  }
`;

const PrevNextButtons = ({
  nextBtnText,
  backBtnText,
  backLink,
  handleBackClick,
  handleNextClick,
  handleNextClickTracking,
  nextBtnHidden,
}) => {
  const backText = backBtnText ? backBtnText : "Back";
  const nextText = nextBtnText ? nextBtnText : "Submit";
  const link = backLink ? backLink : "/";
  const onClickNextHandler = (nextObj) => {
    trackButtonClick('Button', nextObj.props ? nextObj.props.children[0].props.children : nextObj );
  };

  return (
    <PrevNextContainer>
      {handleBackClick && (
        // <PrevButton onClick={handleBackClick} tabIndex={1}>
        <PrevButton onClick={ (e) => { trackButtonClick('Button', 'Back'); handleBackClick(e); } } tabIndex={1}>
          <div><BackIcon /></div>
          <div>{backText}</div>
        </PrevButton>
      )}
      {!handleBackClick && (
        <Link to={link}>
          <PrevButton tabIndex={1} onClick={ (e) => { trackButtonClick('Button', 'Back'); } }>
            <BackIcon />
            {backText}
          </PrevButton>
        </Link>
      )}

      {/* {handleNextClick && <Button tabIndex={1} onClick={handleNextClick}>{nextText}</Button>} */}
      {!nextBtnHidden && handleNextClick && (
        <Button 
          tabIndex={1} 
          onClick={ (e) => { 
            onClickNextHandler(nextText); 
            handleNextClick(e); 
            if (handleNextClickTracking) { 
              handleNextClickTracking(e); 
            } 
          } }>{nextText}</Button>)}
      {!nextBtnHidden && !handleNextClick && (
        <Button 
          tabIndex={1} 
          type="submit"
          onClick={ (e) => { 
            onClickNextHandler(nextText); 
            if (handleNextClickTracking) { 
              handleNextClickTracking(e); 
            } }}>{nextText}</Button>)}
    </PrevNextContainer>
  );
};

export default PrevNextButtons;
