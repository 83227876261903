import styled from "styled-components";
import { media, colors } from "./variables";
import { colours } from "../../resources/global-styles.js";

export const HeaderFooterWrapper = styled.div`
  position: relative;
  background-color: ${colours.header_footer};
  color: white;

  & header,
  & footer {
    position: relative;
    max-width: ${media.maxWidth};
    margin: 0 auto;
    padding: 15px 5%;
    display: flex;
    justify-content: left;

    @media ${media.max} {
      padding: 15px 0;
    }
  }
`;

export const Button = styled.button`
  outline: none;
  padding: 0px 32px 0px 32px;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  min-width: 210px;
  background-color: ${colours.btn_prim_bg};
  border-radius: 100px;
  color: ${colours.btn_prim_text};
  border-style: none;
  height: 44px;


  &:hover {
    background-color: ${colours.btn_prim_dark};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.btn_prim_border};
  }

`;

export const Input = styled.input`
  width: ${(props) => (props.fullwidth ? "90%" : "100%")};
  height: 54px;
  font-size: 18px;
  color: ${colors.darkGrey};
  padding: 15px 45px 15px 15px;
  border: none;
  background-color: ${colours.input_bg};
  border-radius: 8px;
  outline: 0;
  background-position: center right 15px;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  padding-left: 20px;
  background-image: ${(props) =>
    props.valid === "true" ? "url(/images/input-valid-icon.svg)" : "none"};
  border: 1px solid ${colours.field_border};

    &:hover:focus {
      border: none;
      box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.input_border};
    }

    &:hover {
      border: 2px solid ${colours.input_border};
    }
  
    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.input_border};
    }
`;

export const ErrorMessage = styled.div`
  display: block;
  width: ${(props) => (props.width ? props.width : "100%")};
  margin-bottom: ${(props) => (props.spacing ? props.spacing : "0")};
  padding: 4px 20px 20px 40px;
  color: black;
  position: relative;
  background-image: url(/images/error.svg);
  background-repeat: no-repeat;
  background-position: left top;

`;
const getRadioWrapperWidthFromLayout = (layout) => {
  switch (layout) {
    case '2-column':
      return '50%';
    default:
      return '100%';
  }
}

export const RadioWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: ${(props) => (getRadioWrapperWidthFromLayout(props.layout))};
  
  @media ${media.tablet} {
    width: ${(props) => (getRadioWrapperWidthFromLayout(props.layout))};
  }

  @media ${media.mobile}{
    width: 100%;
  }
`;

export const FieldInnerContainer = styled.div`
  margin-top: 1rem;
`;

export const FieldInputWrapper = styled.div`
  width: 50%;

  @media ${media.mobile} {
    width: 100%;
  }
`;

export const TextLink = styled.div`
  font-size: 18px;
  color: ${colours.warning_link};
  cursor: pointer;

  & svg {
    margin-right: 10px;
    height: 32px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const TextArea = styled.textarea`
  font-family: "RNHouseSansRegular";
  width: 100%;
  font-size: 16px;
  color: ${colors.darkGrey};
  padding: 15px 45px 15px 15px;
  border: none;
  background-color: ${colours.input_bg};
  outline: 0;
  margin-bottom: 20px;
  resize: vertical;
  resize: none;
  border-radius: 8px;
  border: 1px solid ${colours.field_border};

  @media ${media.tablet} {
    width: ${(props) => (props.fullwidth === "true" ? "100%" : "50%")};
  }

  &:hover:focus {
    border: none;
    box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.input_border};
  }

  &:hover {
    outline: 2px solid ${colours.input_border};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.input_border};
  }

`;

export const Tickbox = styled.div`
  width: 30px;
  height: 30px;
  border: 2px solid ${(props) => (props.checked ? colours.btn_prim_dark : colours.table_bottom_border)};
  background-color: ${(props) => (props.checked ? colours.btn_prim_dark : colours.page_bg)};
  padding: 3px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: text-bottom;

  &:before {
    content: url("/images/tick.svg");
  }
`;

export const ToggleVisibility = styled.div`
  display: ${({ show }) => (show ? `block` : `none`)};
`;

export const H3 = styled.h3`
  display: block;
  width: 100%;
  margin-block-end: 0;
`;

export const NoteWrapper = styled.div`
  width: 70%;

  @media ${media.mobile} {
    width: 100%;
  }
`;
