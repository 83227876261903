import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Page from "_Broker/structure/Page";
import { media, Button, colours } from "resources/global-styles";
import { constsNatWest } from "resources/constants";
import { brokerAuth, optOutCookies } from "resources/utils";

import Warning from "_Broker/structure/Warning";
import Footer from "_Broker/structure/Footer";
import Header from "../components/WelcomeHeader";
import { trackButtonClick } from 'resources/analyticsHelper';

const HomePage = styled.div`
  flex: 1 0 auto;
  margin-bottom: 1.5rem;

  @media ${media.tablet} {
    background-image: url("/images/start-graphic-new.svg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 48%;
  }
  @media ${media.desktop} {
    background-size: 480px;
  }

  & h2 {
    margin: 0 0 1rem 0;
    color: ${colours.headerColour};
  }

  & h3 {
    margin: 0;
  }

  & ul {
    list-style-image: url("/images/tick-purple.svg");
    padding-left: 25px;
    margin-bottom: 0;

    & li {
      padding: 8px 15px;
    }
    & svg > g > g > g > use {
      fill: ${colours.darkGrey};
    }
  }
`;

const CookieMsg = styled.div`
  font-size: 16px;
  margin: -20px 0 48px 0;
  border-top: 1px solid ${colours.mediumGrey};
  padding-top: 40px;
`;

const InformationContainer = styled.div`
  display: flex;
  margin-bottom: 3rem;
  @media ${media.mobile} {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
`;

const InformationDiv = styled.div`
  width: 50%;
  border: 1px solid lightgray;
  margin-right: 2rem;
  padding: 1rem;
  @media ${media.mobile} {
    margin: 0 0 2rem 0;
    width: 100%;
  }
`;

const Home = () => {
  const { brokerId } = useParams();
  useEffect(() => {
    if (brokerId) {
      localStorage.setItem("broker", brokerId);
    }
  }, [brokerId]);

  const authPath = `/${brokerAuth()}/getstarted`;
  return (
    <>
      <HomePage>
        <Page>
          <Header showNatwestLogo={true} />
          {optOutCookies() === false && (
            <>
              <h2>
                <strong>
                  Have the following information to hand to get started:
                </strong>
              </h2>
              <InformationContainer>
                <InformationDiv>
                  <h3>For loans</h3>
                  <div>
                    Between £{constsNatWest.minAmount / 1000}k and £
                    {constsNatWest.maxAmount / 1000}k.
                  </div>
                  <ul>
                    <li>Loan purpose, amount and term</li>
                    <li>Details of any security to be offered</li>
                    <li>Business sector and trading length</li>
                    <li>
                      EBITDA, director's remuneration and annual debt servicing
                      cost
                    </li>
                  </ul>
                </InformationDiv>
                <InformationDiv>
                  <h3>For asset finance</h3>
                  <div>
                    Between £{constsNatWest.minAssetFinanceAmount / 1000}k and £
                    {constsNatWest.maxAssetFinanceAmount / 1000}k.
                  </div>
                  <ul>
                    <li>Asset type and asset details</li>
                    <li>Asset purchase price</li>
                    <li>Business sector and trading length</li>
                    <li>Business turnover and director's details</li>
                  </ul>
                </InformationDiv>
              </InformationContainer>
              {authPath.length !== 17 && (
                <Warning>
                  Please use the unique access link sent to you via email
                </Warning>
              )}
              {authPath.length === 17 && (
                <div>
                  <Link to={authPath}>
                    <Button onClick={() => { trackButtonClick('Button', 'Get a quote'); }}>Get a quote</Button>
                  </Link>
                  <CookieMsg>
                    <Warning>
                      <div>
                        Broker Portal is for credit brokers and other
                        intermediaries only.
                      </div>
                      <div>
                        You must ensure the options you select are in line with
                        the FCA permissions that you currently hold.
                      </div>
                    </Warning>
                    <br />
                    <p>
                      We use cookies and similar technologies on our websites
                      and mobile applications to help provide you with the best
                      possible online experience. By using our sites and apps,
                      you agree that we may store and access cookies and similar
                      technologies on your device.
                    </p>
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.natwest.com/global/cookieprivacy.ashx?channel=personal"
                      >
                        You can find out more and set your own preferences here
                      </a>
                    </p>
                  </CookieMsg>
                </div>
              )}
            </>
          )}
        </Page>
      </HomePage>
      <Footer />
    </>
  );
};

export default Home;
