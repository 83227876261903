import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Page from "../components/Page";
import { Button } from "../theming/common";
import styled from "styled-components";
import { useLombardContext } from "../context/lombardContext";
import { useAppContext } from "../../context/appContext";
import { trackPageTransitionLombardThankYouPage } from "../../resources/analyticsHelper";

const ButtonWithMargin = styled(Button)`
  margin-top: 2rem;
`;

const ThankYou = () => {
  const history = useHistory();
  const { actions } = useLombardContext();
  const appContext = useAppContext();
  const appActions = appContext.actions;

  useEffect(() => {
    trackPageTransitionLombardThankYouPage();
  }, []);

  const handleClickReturn = () => {
    actions.clearContextState();
    appActions.clearContextState();
    history.push("/");
  };
  return (
    <Page>
      <h1>
        Thanks, we've got your details and a broker business development manager
        will be in touch.
      </h1>
      <label>Please return to the homepage</label>
      <ButtonWithMargin onClick={handleClickReturn}>
        Return to homepage
      </ButtonWithMargin>
    </Page>
  );
};

export default ThankYou;
