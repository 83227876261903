import React from 'react';
import { Redirect } from 'react-router-dom';

import { useFetch } from 'resources/hooks';

const Login = ({ email, password, handleError }) => {
  const {loading, error, data } = useFetch('Authentication/token', true, {
    username: email,
    password: password,
  });

  if (data && data.token) {
    localStorage.setItem('admin', data.token);
    return <Redirect to='/admin' />
  }

  if (!loading && error) handleError(error);
  
  return null;
}

export default Login;
