// export const apiURL = 'https://backend-dot-rbs-broker-portal-s.appspot.com';

export const getAddress = {
  // key: "h7hFdTGfDkGOXLCjI9wjTA22659",
  key: "cDQ92xSDZUipI_HkrfU0SA26532",
  url: "https://api.getAddress.io/find/",
};

export const companiesHouse = {
  authorization: "Q3NSMzZQeXFmNEMzZXVJQ1FKa3lqR0ZfT3lvaE5XV0xKREhwV2NlbA==",
  url: "https://api.companieshouse.gov.uk/search/companies",
};

export const googleAnalytics = "UA-152860493-1";

export const formatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  minimumFractionDigits: 2,
});

export const currencyNoDecimalFormatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  minimumFractionDigits: 0,
});

export const numberTwoDecimalsFormatter = new Intl.NumberFormat("en-GB", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const constsNatWest = {
  minAmount: 50000,
  maxAmount: 750000,
  securedMaxTerm: 25,
  unsecuredMaxTerm: 7,
  dcrThreshold: 1.75,
  minAssetFinanceAmount: 5000,
  maxAssetFinanceAmount: 250000,
  policyInterestCover: {
    commercial: 1.5,
    residential: 1.25
  },
  // nriPercent: 0.6
  nriPercent: {
    commercial: 0.6,
    residential: 0.7,
  }
};

// configs from BE API
export const baseConfigs = {
  realEstateUIR: 3.5,
};

export const initValues = {
  "id.product": { value: 0, valid: true },
  "id.quote": { value: 0, valid: true },
};

export const errorMessages = {
  404: {
    title: "Sorry, we can’t find the page you were looking for",
    description:
      "Please check the URL and try again, or you may find the links below useful:",
  },
  internal: {
    title: "Sorry, something has gone wrong",
    description:
      "NatWest have been informed of the issue. Try refreshing the page. If that doesn't work you may find the links below useful:",
  },
  maintenance: {
    title:
      "The NatWest Broker Portal is undergoing maintenance and is currently unavailable",
    description:
      "Apologies for any inconvenience caused. Please try again later, or you may find the links below useful:",
  },
};

export const starMessages = [
  "",
  "We're sorry the experience didn't meet your expectations. We value your feedback and it would help us improve the process if you could tell us why you gave us this rating.",
  "We're sorry the experience didn't meet your expectations. We value your feedback and it would help us improve the process if you could tell us why you gave us this rating.",
  "We value your feedback and it would help us improve the application process if you could tell us more about why the experience didn't quite meet your expectations.",
  "It's great to hear that you've had a good experience with this application process. Could you tell us one thing you would add or change to make it even better?",
  "It's great to hear that you've had a good experience with this application process. Could you tell us one thing you would add or change to make it even better?",
];

// TODO if this project is converted to TypeScript, use enums here instead

export const zIndex = {
  normal: 0,
  dropdown: 1,
  progress: 2,
  modal: 3,
  sidebar: 4,
};

export const stageVal = {
  loan: 0,
  business: 1,
  summary: 2,
  refer: 3,
  confirmRefer: 4,
  unhappy: 5,
  confirmUnhappy: 6,
};

export const pathVal = {
  loading: 0,
  green: 1,
  amber: 2,
  red: 3,
};

export const regExp = {
  email: 0,
  phone: 1,
  postcode: 2,
  name: 3,
  companyNumber: 4,
};

export const products = {
  options: [
    {
      value: "Fixed rate hire purchase",
      label: "Fixed rate hire purchase",
    },
    {
      value: "Variable rate hire purchase",
      label: "Variable rate hire purchase",
    },
    {
      value: "Operating lease",
      label: "Operating lease",
    },
    {
      value: "Finance lease",
      label: "Finance lease",
    },
    {
      value: "Sale & lease/HP back",
      label: "Sale & lease/HP back",
    },
    {
      value: "Chattel loan",
      label: "Chattel loan",
    },
  ],
  validationMessage: "Please select an option",
};

export const financeType = {
  options: [
    {
      value: "loan",
      label:
        "Loan (property purchase, refinance of existing loan, business growth, debt consolidation, cash flow)",
    },
    {
      value: "asset",
      label: "Asset finance (asset purchase)",
    },
  ],
  validationMessage: "Please select an option",
};
