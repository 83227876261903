import React, { useState, useEffect, createContext } from 'react';
import styled from 'styled-components';

import { validAmount, validTerm, transformSectors, getKeyByValue, brokerAuth, validFormat, debugConsole } from 'resources/utils';
import { trackApplicationStepName, trackInput } from 'resources/analyticsHelper';
import { useFetch } from 'resources/hooks';
import { stageVal, initValues, regExp, constsNatWest } from 'resources/constants';
import { quoteStages } from 'resources/page-structure';
import { testChosen } from 'resources/mock';

import Header from '_Broker/structure/Header';
import Footer from '_Broker/structure/Footer';
import Page from '_Broker/structure/Page';
import Progress from '_Broker/quote/Progress';
import Choice from '_Broker/quote/Choice';
import Sections from '_Broker/quote/Section';
import Summary from '_Broker/quote/Summary';
import ConfirmRefer from '_Broker/quote/ConfirmRefer';
import ConfirmUnhappy from '_Broker/quote/ConfirmUnhappy';
import PrevNext from '_Broker/quote/PrevNext';
import Warning from '../structure/Warning';

const PageWrap = styled.div`
  flex: 1 0 auto;
`

export const QuoteContext = createContext(null);

export const bucket = {};

const Quote = ({ isRetrieved = false, initChosen = initValues}) => {
  const sectorFetch = useFetch('Sectors');

  if (isRetrieved) {
    Object.keys(initChosen).forEach(key => {
      if (initChosen[key] && initChosen[key].value === null && initChosen[key].valid) {
        delete initChosen[key];
      }
    })
  }

  if (isRetrieved && sectorFetch.data) {
    const chosenSectorId = initChosen['business.sector.consolidated'].value;
    sectorFetch.data.forEach(sector => {
      if (sector.subSectors && sector.subSectors.length) {
        sector.subSectors.forEach(subsector => {
          if (subsector.id === Number(chosenSectorId)) {
            initChosen['business.sector'].value = `${subsector.sectorParentId}`;
          }
        })
      }
    });
  }

  const [stage, setStage] = useState(process.env.NODE_ENV === 'development' ? stageVal.summary : isRetrieved ? stageVal.summary : stageVal.loan);
  const [chosen, setChosen] = useState(process.env.NODE_ENV === 'development' ? testChosen : initChosen);
  const [dynamic, setDynamic] = useState({ max_term: constsNatWest.unsecuredMaxTerm });

  useEffect(() => {
    if (isRetrieved && initChosen['loan.secured'] && initChosen['loan.secured'].value) {
      processChoice('loan.secured', initChosen['loan.secured'].value);
    }
    window.history.pushState({name: "browserBack"}, "on browser back click", window.location.href);
    const handleBrowserBack = () => {
      if (stage > stageVal.loan) handleStageChange(stageVal.loan);
    }
    window.addEventListener('popstate', handleBrowserBack);
    return () => { window.removeEventListener('popstate', handleBrowserBack) }
  // eslint-disable-next-line
  }, [stage])

  const currPage = quoteStages.filter(obj => obj.stage === stage);
  if (!currPage.length) return null;

  if (currPage[0].sections) {
    const newChosen = currPage[0].sections.reduce((newChosen, section) => {
      return Object.assign({}, newChosen, section.choices.reduce((newChosen, choice) => {
        return Object.assign({}, newChosen, chosen[choice.id[0]] ? null : {[choice.id[0]]: { value: null }})
      }, {}))
    }, {});
    if (Object.keys(newChosen).length) setChosen(Object.assign({}, chosen, newChosen));
  } else if (currPage[0].choices) {
    const newChosen = currPage[0].choices.reduce((newChosen, obj) => Object.assign({}, newChosen, chosen[obj.id[0]] ? null : {[obj.id[0]]: { value: null }}), {});
    if (Object.keys(newChosen).length) setChosen(Object.assign({}, chosen, newChosen));
  }

  /**
   * 
   */
  const validateChoice = (choiceId, choiceVal) => {
    let unhappyChosen = {};
    let isValid = false;

    switch (choiceId) {
      case 'loan.secured':
        isValid = !!choiceVal && (!isNaN(choiceVal) || !!choiceVal.length);
        if (choiceVal === 'other') unhappyChosen = { 'unhappy.reason': { value: 'Loan secured by Other', valid: true } };
        break;
      case 'loan.amount':
        isValid = Number(choiceVal) > 0;
        if (!validAmount(choiceVal)) unhappyChosen = { 'unhappy.reason': { value: 'Loan amount outside range', valid: true } };
        break;
      case 'business.sector':
        isValid = !!choiceVal && (!isNaN(choiceVal) || !!choiceVal.length);
        // Agriculture, Healthcare & Real estate
        // Warning: if the ids change from the back-end this test will fail

        // Commented this condition (Pratik Kale) as we need to make all sectors supported except 2 subsectors from real estate - OLAF-358
        // if (choiceVal === '8' || choiceVal === '10') {
        //   unhappyChosen = { 'unhappy.reason': { value: 'Unsupported business sector', valid: true } };
        // }
        break;
      case 'business.sector.leisure':
          isValid = !!choiceVal && (!isNaN(choiceVal) || !!choiceVal.length);
          // Agriculture, Healthcare & Real estate
          // Warning: if the ids change from the back-end this test will fail
          
          // Commented and changed the unsupported subsector ids (Pratik Kale) -  OLAF-358
          // if (choiceVal === '15' || choiceVal === '16' || choiceVal === '17' || choiceVal === '18') {
          //   unhappyChosen = { 'unhappy.reason': { value: 'Unsupported business sector', valid: true } };
          // }
          if (choiceVal === '101' || choiceVal === '102') {
            unhappyChosen = { 'unhappy.reason': { value: 'Unsupported business sector', valid: true } };
          }
          break;
      case 'loan.term':
        isValid = validTerm(choiceVal, dynamic.max_term)
        break;
      case 'refer.broker.email':
      case 'unhappy.email':
        isValid = !!choiceVal && validFormat(choiceVal, regExp.email);
        break;
      case 'refer.client.email': // only validate if main contact is 'client'
        isValid = (chosen['refer.broker.contact'] && chosen['refer.broker.contact'].value === 'broker') || (!!choiceVal && validFormat(choiceVal, regExp.email));
        break;
      case 'refer.broker.phone':
      case 'unhappy.phone':
        isValid = !!choiceVal && validFormat(choiceVal, regExp.phone);
        break;
      case 'refer.client.phone': // only validate if main contact is 'client'
        isValid = (chosen['refer.broker.contact'] && chosen['refer.broker.contact'].value === 'broker') || (!!choiceVal && validFormat(choiceVal, regExp.phone));
        break;
      case 'refer.client.postcodeLookup': // only validate if postcode is missing
        isValid = (chosen['refer.client.postcode'] && chosen['refer.client.postcode'].valid === true) || (!!choiceVal && validFormat(choiceVal, regExp.postcode));
        break;
      case 'refer.client.postcode':
        isValid = !!choiceVal && validFormat(choiceVal, regExp.postcode);
        break;
      case 'refer.broker.forename':
      case 'refer.broker.surname':
      case 'refer.client.forename':
      case 'refer.client.surname':
      case 'unhappy.forename':
      case 'unhappy.surname':
        isValid = !!choiceVal && validFormat(choiceVal, regExp.name);
        break;
      case 'refer.client.address2':
      case 'refer.additional.information':
      case 'id.product.arrangementFee':
        isValid = true;
        break;
      
      case 'business.financial.operatingProfit':
      case 'business.financial.depreciation':
      case 'business.financial.amortisation':
      case 'business.financial.lossOnDisposalFixedAssets':
      case 'business.financial.profitOnDisposalFixedAssets':
      case 'business.financial.exceptionalAddBacks':
      case 'business.financial.exceptionalItem':
      case 'business.financial.dividends':
      case 'business.financial.netProfit':
      case 'business.financial.interest':
      case 'business.financial.dividends2':
      case 'business.financial.ebitdaDraft':
      case 'business.financial.ebitda':
        // console.log('choiceVal', choiceVal, !isNaN(choiceVal));
        if (!isNaN(choiceVal)) {
          isValid = true;
        }
        // if (choiceVal >= 0) {
        //   isValid = true;
        // }
        break;
      case 'business.financial.director_remuneration':
        if (chosen['business.financial.useCalculator']?.value === 'yes') {
          isValid = true;
        }
        else {
          if (chosen['business.entity']?.value !== 'ltd') {
            isValid = true;
          }
          else {
            if (!isNaN(choiceVal)) {
              isValid = true;
            }
            // if (choiceVal >= 0) {
            //   isValid = true;
            // }
          }
        }
        // console.log(choiceId, choiceVal);
        // console.log('[validateChoice]', chosen);
        // console.log('[validateChoice]', chosen['business.financial']?.value, chosen['business.financial.useCalculator']?.value);
        break;      
      case 'refer.broker.company':
      default:
        isValid = !!choiceVal && (!isNaN(choiceVal) || !!choiceVal.length);
    }

    debugConsole('validate', `${choiceId} - ${choiceVal} - ${isValid}`);
    // console.log('[validateChoice]', `${isValid} --- ${choiceId} - ${choiceVal}`);

    return Object.assign({
      [choiceId]: {
        value: choiceVal,
        valid: isValid,
      }
    }, unhappyChosen);
  }

  /**
   * 
   */
  const processChoice = (choiceId, choiceVal, currChosen) => {
    // console.log('>> processChoice', choiceId, choiceVal);
    trackInput(choiceId, choiceVal);

    let newChosen = { [choiceId]: { value: choiceVal }};

    const secotrsWithSubsectors = ['2', '3', '4', '6', '7', '8', '9', '10', '1100', '1200']

    switch (choiceId) {
      case 'loan.secured':
        const newMaxTerm = !choiceVal || choiceVal === 'none' ? constsNatWest.unsecuredMaxTerm : constsNatWest.securedMaxTerm;
        setDynamic({ ...dynamic, max_term: newMaxTerm });

        if (chosen['loan.term'] && chosen['loan.term'].value) {
          newChosen['loan.term'] = {};
          newChosen['loan.term'].value = chosen['loan.term'].value;
          newChosen['loan.term'].valid = Number(chosen['loan.term'].value) >= 1 && Number(chosen['loan.term'].value) <= newMaxTerm;
        }

        if (choiceVal === 'property' && !chosen['loan.secured.property_owned']) {
          newChosen['loan.secured.property_owned'] = {value: null};
          newChosen['loan.secured.property_value'] = null;
          newChosen['loan.secured.property_purchase'] = null;
          newChosen['loan.secured.other_asset'] = null;
        }
        if (choiceVal === 'other' && !chosen['loan.secured.other_asset']) {
          newChosen['loan.secured.other_asset'] = {value: null};
          newChosen['loan.secured.property_owned'] = null;
          newChosen['loan.secured.property_value'] = null;
          newChosen['loan.secured.property_purchase'] = null;
        }
        if (choiceVal === 'none') {
          newChosen['loan.secured.property_owned'] = null;
          newChosen['loan.secured.property_value'] = null;
          newChosen['loan.secured.property_purchase'] = null;
          newChosen['loan.secured.other_asset'] = null;
        }
        break;
      case 'loan.secured.property_owned':
        if (choiceVal === 'yes' && !chosen['loan.secured.property_value']) {
          newChosen['loan.secured.property_value'] = {value: null};
          newChosen['loan.secured.property_purchase'] = null;
        } else if (choiceVal === 'no' && !chosen['loan.secured.property_purchase']) {
          newChosen['loan.secured.property_purchase'] = {value: null};
          newChosen['loan.secured.property_value'] = null;
        }
        break;
      case 'business.sector':
        // Warning: if the ids change from the back-end this test will fail
        if (secotrsWithSubsectors.includes(choiceVal)) {
          // if (chosen['business.sector.leisure']) {
          //   newChosen['business.sector.consolidated'] = {value: chosen['business.sector.leisure'].value, valid: true};
          // } else {
          //   newChosen['business.sector.leisure'] = {value: null};
          //   newChosen['business.sector.consolidated'] = {value: null};
          // }
          newChosen['business.sector.leisure'] = {value: null};
          newChosen['business.sector.consolidated'] = {value: null};
        } else {
          newChosen['business.sector.consolidated'] = {value: choiceVal, valid: true};
          if (chosen['business.sector.leisure'] && (!chosen['business.sector.leisure'].value || chosen['business.sector.leisure'].valid === false)) {
            chosen['business.sector.leisure'] = null;
          }
        }
        break;
      case 'business.sector.leisure':
        newChosen['business.sector.consolidated'] = {value: choiceVal, valid: true};
        break;
      case 'business.entity':
        if (!chosen['business.financial']) {
          newChosen['business.financial'] = {value: null};
        }
        newChosen = resetBusinessFinancialContextValues(choiceId, choiceVal, newChosen);
        break;
      case 'business.financial':
        if (choiceVal === 'yes') {
          if (chosen['business.sector'] && chosen['business.sector'].value === '10') {
            // sector - real estate 
            if (!chosen['business.financial.gross_rent']) {
              newChosen['business.financial.gross_rent'] = { value: null };
            }
          } else {
            // sector - others 
            if (!chosen['business.financial.ebitda']) {
              newChosen['business.financial.ebitda'] = { value: null };
            }
            if (!chosen['business.financial.existing_debt']) {
              newChosen['business.financial.existing_debt'] = { value: null };
            }
            if (!chosen['business.financial.director_remuneration'] && chosen['business.entity'].value === 'ltd') {
              newChosen['business.financial.director_remuneration'] = { value: null };
            }
          }
        } else {
          newChosen['business.financial.ebitda'] = null;
          newChosen['business.financial.existing_debt'] = null;
          newChosen['business.financial.director_remuneration'] = null;
          newChosen['business.financial.gross_rent'] = null;
        }

        // console.log(`[processChoice] ${choiceId}, ${choiceVal}`);
        // console.log(`[processChoice] ${chosen['business.entity']?.value}, ${chosen['business.financial.useCalculator']?.value}`);
        break;
      case 'business.financial.useCalculator':
        newChosen = resetBusinessFinancialContextValues(choiceId, choiceVal, newChosen);
        break;
      case 'business.financial.ebitda':
        newChosen['business.financial.gross_rent'] = null;
        break;
      case 'business.financial.gross_rent':
        newChosen['business.financial.ebitda'] = null;
        newChosen['business.financial.existing_debt'] = null;
        newChosen['business.financial.director_remuneration'] = null;
        break;
      case 'refer.broker.contact':
        if (choiceVal === 'broker') {
          newChosen['refer.client.email'] = {value: null, valid: true};
          newChosen['refer.client.phone'] = {value: null, valid: true};
        }
        break;
      case 'refer.client.postcodeLookup':
        newChosen[choiceId].value = choiceVal ? choiceVal.toUpperCase() : null;
        break;
      case 'refer.client.postcode':
        newChosen[choiceId].value = choiceVal ? choiceVal.toUpperCase() : null;
        newChosen['refer.client.postcodeLookup'] = {value: choiceVal};
        break;
      default:
    }

    // console.log('newChosen', newChosen);
    debugConsole('process', newChosen);
    return newChosen;
  }

  /**
   * reset business financial fields if changes business.entity or business.financial.useCalculator
   */
  const resetBusinessFinancialContextValues = (choiceId, choiceVal, newChosen) => {
    // console.log('>> resetBusinessFinancialContextValues', choiceId, choiceVal, newChosen);
    newChosen['business.financial.operatingProfit'] = { value: 0, valid: true };
    newChosen['business.financial.netProfit'] = { value: 0, valid: true };
    newChosen['business.financial.interest'] = { value: 0, valid: true };
    newChosen['business.financial.depreciation'] = { value: 0, valid: true };
    newChosen['business.financial.amortisation'] = { value: 0, valid: true };
    newChosen['business.financial.lossOnDisposalFixedAssets'] = { value: 0, valid: true };
    newChosen['business.financial.profitOnDisposalFixedAssets'] = { value: 0, valid: true };
    newChosen['business.financial.exceptionalAddBacks'] = { value: 0, valid: true };
    newChosen['business.financial.exceptionalItem'] = { value: 0, valid: true };
    newChosen['business.financial.dividends'] = { value: 0, valid: true };
    newChosen['business.financial.dividends2'] = { value: 0, valid: true };
    newChosen['business.financial.ebitdaDraft'] = { value: 0, valid: true };
    newChosen['business.financial.ebitda'] = { value: undefined, valid: true };
    newChosen['business.financial.director_remuneration'] = { value: undefined, valid: true };
    newChosen['business.financial.existing_debt'] = { value: undefined, valid: true };

    if (
      newChosen['business.financial.useCalculator']?.value === 'yes' || 
      (choiceId === 'business.entity' && chosen['business.financial.useCalculator']?.value === 'yes')
    ) {
      newChosen['business.financial.ebitda'] = { value: 0, valid: true };  
    }
    return newChosen;
  };

  /**
   * 
   */
  const buildTree = (obj) => {
    if (obj.choices) {
      return obj.choices.reduce((objFlat, obj2) => Object.assign(objFlat, buildTree(obj2)), {});
    }
    return Object.assign({}, chosen[obj.id[0]], obj.id.reduce((objFlat, strId) => Object.assign(objFlat, { [strId]: chosen[strId] ? chosen[strId] : { value: null } }), {}));
  }

  /**
   * 
   */
  const checkUnhappy = (newStage, newChosen) => {
    if (newChosen['unhappy.reason']) {
      const broker = brokerAuth();
      const pseudoURL = `/${broker}/${isRetrieved ? 'retrieve-' : ''}quote/6-unhappy`;
      debugConsole('Track unhappy path', pseudoURL);
      trackApplicationStepName(pseudoURL);


      debugConsole('Track unhappy reason', newChosen['unhappy.reason'].value);
      return stageVal.unhappy;
    }

    return newStage;
  }

  /**
   * 
   */
  const updateStage = (s) => {
    // console.log('[updateStage]', s);

    const broker = brokerAuth();
    if (stage === stageVal.unhappy && s === stageVal.loan) { // user coming back from Unhappy page
      const newStage = chosen['business.sector'] ? stageVal.business : stageVal.loan;
      const pseudoURL = `/${broker}/${isRetrieved ? 'retrieve-' : ''}quote/${newStage + 1}-${getKeyByValue(stageVal, newStage)}`;
      debugConsole('Track quote stage', pseudoURL);
      trackApplicationStepName(pseudoURL);

      setStage(newStage);
    } else {
      const newStage = Math.max(Math.min(s, quoteStages.length - 1), 0);
      const pseudoURL = `/${broker}/${isRetrieved ? 'retrieve-' : ''}quote/${newStage + 1}-${getKeyByValue(stageVal, newStage)}${newStage===stageVal.refer ? '/1-broker' : ''}`;
      debugConsole('Track quote stage', pseudoURL);

      // Do not track the last page, which is "/quote/5-confirmRefer", for we already "trackApplicationComplete"
      if (pseudoURL.indexOf('/quote/5-confirmRefer') === -1) {
        trackApplicationStepName(pseudoURL);
      }

      setStage(newStage);
    }

    // reset browser to top of page
    window.scrollTo(0, 0);
  }

  /**
   * 
   */
  const handleStateChange = (updateParam) => {
    debugConsole('[handleStateChange]', updateParam);
    // console.log('[handleStateChange]', updateParam);

    switch (updateParam.constructor) {
      case String: 
        // validate this field when losing focus
        trackInput(updateParam, (chosen[updateParam] ? chosen[updateParam].value : null), true);

        const newChosenByCalculator = calculateEbitda(updateParam);
        if (newChosenByCalculator) {
          setChosen(Object.assign({}, chosen, newChosenByCalculator));
        } else {
          setChosen(Object.assign({}, chosen, validateChoice(updateParam, chosen[updateParam] ? chosen[updateParam].value : null)));
        }

        break;
      case Object:
        const { choiceId, choiceVal, isValid } = updateParam;
        if (typeof(isValid) === 'undefined') {
          const newChoice = processChoice(choiceId, choiceVal, chosen);
          setChosen(Object.assign({}, chosen, newChoice));
        } else {
          setChosen(Object.assign({}, chosen, {[choiceId]: {value: choiceVal, valid: isValid}}));
        }
        break;
      case Array:
        const newChosen = updateParam.reduce((newChosen, obj) => {
          if (typeof(obj.isValid) === 'undefined') {
            return Object.assign({}, newChosen, processChoice(obj.choiceId, obj.choiceVal))
          } else {
            return Object.assign({}, newChosen, {[obj.choiceId]: {value: obj.choiceVal, valid: obj.isValid}})
          }
        }, {});
        setChosen(Object.assign({}, chosen, newChosen));
        break;
      default:
        debugConsole('Bad parameter on handleStateChange', updateParam);
        throw new Error(`Bad parameter on handleStateChange: ${JSON.stringify(updateParam)}`);
    }
  }

  /**
   *  auto update calculated-EBITDA and calculated-draft-EBITDA
   */
  const calculateEbitda = (id) => {
    const ids = [
      'business.financial.operatingProfit', 
      'business.financial.netProfit', 
      'business.financial.interest', 
      'business.financial.depreciation', 
      'business.financial.amortisation', 
      'business.financial.lossOnDisposalFixedAssets', 
      'business.financial.profitOnDisposalFixedAssets', 
      'business.financial.exceptionalAddBacks', 
      'business.financial.exceptionalItem', 
      'business.financial.dividends',
      'business.financial.dividends2'
    ];

    if (ids.includes(id)) {
      // console.log(
      //   chosen['business.financial.operatingProfit']?.value,
      //   chosen['business.financial.depreciation']?.value,
      //   chosen['business.financial.amortisation']?.value,
      //   chosen['business.financial.lossOnDisposalFixedAssets']?.value,
      //   chosen['business.financial.exceptionalAddBacks']?.value,
      //   chosen['business.financial.exceptionalItem']?.value,
      //   chosen['business.financial.dividends']?.value,
      // );
      // console.log('--- --- --- ', chosen['business.entity']?.value);

      let draft = 0;
      let final = 0;
      if (chosen['business.entity']?.value === 'ltd') {
        draft = calculateSum([
          "business.financial.operatingProfit",
          "business.financial.depreciation",
          "business.financial.amortisation",
          "business.financial.lossOnDisposalFixedAssets",
          // "business.financial.profitOnDisposalFixedAssets",
          "business.financial.exceptionalAddBacks",
          // "business.financial.exceptionalItem",
        ]);
        draft -= (Number(chosen['business.financial.profitOnDisposalFixedAssets']?.value) || 0);
        draft -= (Number(chosen['business.financial.exceptionalItem']?.value) || 0);
        final = draft - (Number(chosen['business.financial.dividends']?.value) || 0);
      } else {
        draft = calculateSum([
          "business.financial.netProfit", // non-ltd
          "business.financial.interest", // non-ltd
          "business.financial.depreciation",
          "business.financial.amortisation",
          "business.financial.lossOnDisposalFixedAssets",
          // "business.financial.profitOnDisposalFixedAssets",
          "business.financial.exceptionalAddBacks",
          // "business.financial.exceptionalItem",
        ]);
        draft -= (Number(chosen['business.financial.profitOnDisposalFixedAssets']?.value) || 0);
        draft -= (Number(chosen['business.financial.exceptionalItem']?.value) || 0);
        final = draft - (Number(chosen['business.financial.dividends2']?.value) || 0);
      }
      // console.log('draft, final:', draft, final);
      
      const newChosen = {};
      newChosen['business.financial.ebitdaDraft'] = {
        value: draft,
        valid: true
      };
      newChosen['business.financial.ebitda'] = {
        value: final,
        valid: true
      };
      return newChosen;
    }
  };

  /**
   * 
   */
  const calculateSum = (ids) => {
    let sum = 0;
    ids.forEach((id) => {
      sum += Number(chosen[id]?.value) || 0;
    });
    return sum;
  };

  /**
   * 
   */
  // eslint-disable-next-line
  const handleStageChange = (newStage) => {
    debugConsole('[handleStageChange]', newStage);
    // console.log('[handleStageChange]', newStage);

    if (newStage < stage || (!currPage[0].choices && !currPage[0].sections)) {
      updateStage(newStage);
    } else if (currPage[0].choices) {
      let newChosen = {};
      chosen['unhappy.reason'] = newStage === stageVal.confirmUnhappy ? chosen['unhappy.reason'] : null;
      currPage[0].choices.forEach((obj) => {
        obj.id.forEach((id) => newChosen = Object.assign({}, newChosen, chosen[id] ? validateChoice(id, chosen[id].value) : null));
      });
      newChosen = Object.assign({}, chosen, newChosen);
      debugConsole('validate - newChosen', newChosen);
      setChosen(newChosen);

      // console.log('[handleStageChange]', newChosen);
      // Check all current choices are valid
      if (
        currPage[0].choices.reduce((isValid, obj) => 
          isValid && 
          obj.id.reduce((isValid, id) => 
            isValid && 
            (
              !newChosen[id] || 
              newChosen[id].valid
            )
          , true)
        , true)
      ) {
        updateStage(newStage === stageVal.confirmUnhappy ? newStage : checkUnhappy(newStage, newChosen));
      } else {
        // console.log(newChosen);
      }
    } else if (currPage[0].sections) {
      // console.log('[handleStageChange] 222');
      
      let newChosen = {};
      currPage[0].sections.forEach((section) => {
        section.choices.forEach((obj) => {
          obj.id.forEach((id) => newChosen = Object.assign({}, newChosen, validateChoice(id, chosen[id] ? chosen[id].value : null)));
        });
      });
      newChosen = Object.assign({}, newChosen, validateChoice('id.product',chosen['id.product'].value));
      newChosen = Object.assign({}, newChosen, validateChoice('id.product.name',chosen['id.product.name'].value));
      newChosen = Object.assign({}, newChosen, validateChoice('id.product.rate',chosen['id.product.rate'].value));
      newChosen = Object.assign({}, newChosen, validateChoice('id.product.monthlyCost',chosen['id.product.monthlyCost'].value));
      newChosen = Object.assign({}, newChosen, validateChoice('id.product.arrangementFee',chosen['id.product.arrangementFee'].value));
      newChosen = Object.assign({}, newChosen, validateChoice('id.quote',chosen['id.quote'].value));
      newChosen = Object.assign({}, newChosen, validateChoice('id.referral',chosen['id.referral'].value));
      newChosen = Object.assign({}, chosen, newChosen);
      debugConsole('validate - newChosen', newChosen);
      setChosen(newChosen);

      if (Object.values(newChosen).reduce((isValid, obj) => isValid && (!obj || obj.valid), true)) {
        updateStage(newStage);
      }
    }

    // TODO - scroll to top error
    window.scrollTo(0, 0);
  }

  if (sectorFetch.data) return (
    <>
      <PageWrap>
        <Header />
        {stage < stageVal.confirmRefer && <Progress stage={stage} handleChange={handleStageChange} />}
        <Page>
          {currPage[0].title && <h1>{currPage[0].title}</h1>}
          {currPage[0].description && <p>{currPage[0].description}</p>}
          {currPage[0].warning && <Warning withHTML={true} showInline={true}>{currPage[0].warning}</Warning>}
          {currPage[0].choices && currPage[0].choices.map((obj, i) => (
            <QuoteContext.Provider key={`choice_${i}`} value={Object.assign({ sectors: transformSectors(sectorFetch.data, true), chosen: chosen }, dynamic, buildTree(obj))}>
              <Choice choice={obj} handleChange={handleStateChange} />
            </QuoteContext.Provider>
          ))}
          {currPage[0].sections &&
            <Sections sections={currPage[0].sections} handleChange={handleStateChange} buildTree={buildTree} isRetrieved={isRetrieved} />
          }
          {stage === stageVal.summary &&
            <QuoteContext.Provider value={Object.assign({ sectors: transformSectors(sectorFetch.data) }, { dynamic: dynamic }, chosen)}>
              <Summary handleStateChange={handleStateChange} handleStageChange={handleStageChange} />
            </QuoteContext.Provider>
          }
          {stage === stageVal.confirmRefer &&
            <QuoteContext.Provider value={chosen}>
              <ConfirmRefer />
            </QuoteContext.Provider>
          }
          {stage === stageVal.confirmUnhappy &&
            <QuoteContext.Provider value={chosen}>
              <ConfirmUnhappy />
            </QuoteContext.Provider>
          }
          <PrevNext stage={stage} nextLabel={quoteStages[stage + 1] ? quoteStages[stage + 1].progress : ''} handleChange={handleStageChange} />
        </Page>
      </PageWrap>
      <Footer />
    </>
  );
  return null;
}

export default Quote;