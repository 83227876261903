import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Page from "../components/Page";
import { Button } from "../theming/common";
import LombardRoutes from "../utils/routes";
import { trackButtonClick, trackLombardEmailQuoteSummaryRetrieval } from "../../resources/analyticsHelper";

const ButtonWithTopMargin = styled(Button)`
  margin-top: 1.5rem;
`;

const QuoteExpired = ({ quoteExpired }) => {
  useEffect(() => {
    trackLombardEmailQuoteSummaryRetrieval(quoteExpired ? "Quote Expired" : "Quote Already Submitted");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <Page>
      {quoteExpired ? (
        <h1>Unfortunately this quote has expired</h1>
      ) : (
        <h1>This quote has already been submitted to Lombard</h1>
      )}
      <label>Please start a new quote</label>
      <Link onClick={() => trackButtonClick('Button', 'Start a new quote')} to={LombardRoutes.getStarted()}>
        <ButtonWithTopMargin>Start a new quote</ButtonWithTopMargin>
      </Link>
    </Page>
  );
};

export default QuoteExpired;
